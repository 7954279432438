import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import BlackLogo from '../assets/images/logo/logo-black.png';
import HomerunLogo from "../assets/images/logo/logo_homerun.png"

import InternityLogo from "../assets/icons/internity-logo-final-20@3x.png";

import {
  selectAuthUser,
  selectAuthMessage,
  selectAuthPending,
  selectAuthAlertMessage
} from '../store/auth/auth.selector';
import PaylixIcon from '../components/commonElements/PaylixIcon';
import LoginLock from '../assets/images/loginlock.png';
import { hideMessage, showAuthLoader, SignInMerchant } from '../store/auth/auth.actions';

class SignIn extends React.Component {
  state = {
    // email: 'gigitest@test.com',
    // password: 'R@me2010Rame'
    // email: 'demo@example.com',
    // password: 'paylix1'
    email: '',
    password: ''
  };

  placeholderStyle = {
    // placeholder styles for UI material, used in TextField component' attribute 'InputLabelProps
    fontSize: '13px',
    color: '#c4c4c4'
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      // delete error about invalid email or password after 3 seconds
      setTimeout(() => {
        this.props.hideMessage();
      }, 3000);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    const { email, password } = this.state;
    const { showMessage, loader, SignInMerchant } = this.props;

    return (
      <>
        <div className="app-login-container bg-1 d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-login-content ">
              <div className="app-login-form">
                {window.location.hostname == 'homerun.webiz.ge' ? <img src={HomerunLogo} style={{ width: '100px' }} /> : <img src={InternityLogo} style={{ width: '100px' }} />}
                <img src={LoginLock} alt="Lock" className="app-login-form__lock" />

                {showMessage ? ( // showing error about invalid email and password
                  <p className="login-error__message">
                    <IntlMessages id="appModule.wrongemail" />.
                  </p>
                ) : (
                    <h1>
                      <IntlMessages id="appModule.memberslogin" />
                    </h1>
                  )}

                <form>
                  <TextField // email input
                    className="outlined-dense"
                    label={<IntlMessages id="appModule.emailaddress" />}
                    InputLabelProps={{
                      style: this.placeholderStyle
                    }}
                    inputProps={{
                      onKeyUp: e => {
                        e.preventDefault();
                        if (e.keyCode === 13) {
                          this.props.showAuthLoader();
                          SignInMerchant({ email, password });
                          localStorage.setItem('email', email);
                        }
                      }
                    }}
                    fullWidth
                    onChange={event => this.setState({ email: event.target.value })}
                    margin="dense"
                    variant="outlined"
                  />

                  <TextField // password input
                    className="password-input outlined-dense"
                    label={<IntlMessages id="appModule.password" />}
                    InputLabelProps={{
                      style: this.placeholderStyle
                    }}
                    inputProps={{
                      onKeyUp: e => {
                        e.preventDefault();
                        if (e.keyCode === 13) {
                          this.props.showAuthLoader();
                          SignInMerchant({ email, password });
                          localStorage.setItem('email', email);
                        }
                      }
                    }}
                    variant="outlined"
                    type="password"
                    fullWidth
                    onChange={event => this.setState({ password: event.target.value })}
                    margin="dense"
                  />

                  <div className="forgot-pass-login-button d-flex align-items-center justify-content-between">
                    <div className="forgot-pass">
                      <Link className="forgot-pass__link" to="/passwordreset">
                        <IntlMessages id="appModule.forgotPassword" />
                      </Link>
                    </div>

                    <Button
                      className="login-button"
                      onClick={() => {
                        this.props.showAuthLoader();
                        SignInMerchant({ email, password });
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <IntlMessages id="appModule.signIn" />
                    </Button>
                  </div>
                </form>
              </div>

              <div className="app-login-footer">
                <IntlMessages id="appModule.dontHaveAccount" />
                &nbsp;
                <Link to="/signup" className="sign-up__link">
                  <IntlMessages id="appModule.signup" />
                </Link>
              </div>
            </div>
          </div>
          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: selectAuthUser(state),
    loader: selectAuthPending(state),
    showMessage: selectAuthMessage(state),
    alertMessage: selectAuthAlertMessage(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMessage: () => dispatch(hideMessage()),
    showAuthLoader: () => dispatch(showAuthLoader()),
    SignInMerchant: merchant => dispatch(SignInMerchant(merchant))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);