import React from 'react';
import { Route, Redirect } from 'react-router-dom';


export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route {...rest} render={props => {


        // check if route is restricted by role
        if (roles === '2') {
            // role not authorised so redirect to home page
            return <Redirect to={{ pathname: '/app/club/catalog'}} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />
)