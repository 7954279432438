import React from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import './style.css';

const TopMenu = props => {
  const { topMenuStatus, topMenuContent } = props;
  return (
    <CSSTransition
      in={topMenuStatus}
      timeout={{
        appear: 200,
        enter: 300,
        exit: 100
      }}
      classNames="top-menu"
      unmountOnExit
      // onEnter={() => setShowButton(false)}
      // onExited={() => setShowButton(true)}
    >
      <div className={`top-menu-wrapper ${topMenuStatus ? 'active' : ''}`}> {topMenuContent} </div>
    </CSSTransition>
  );
};

const mapStateToProps = ({ settings }) => {
  const { topMenuStatus, topMenuContent } = settings;
  return { topMenuStatus, topMenuContent };
};

const mapDispatchToProps = dispatch => {
  return {
    // setMenuType: menu_type => dispatch(setMenuType(menu_type)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TopMenu);
