import React, { Component } from 'react';
import Catalog from './Catalog';
import ProductContainer from './ProductContainer';
import axios from '../../../../appUtility/Api';
import Loader from '../../../../appUtility/Loader';
class index extends Component {
  state = {
    category: '',
    merchant: '',
    sort: '',
    searchText: '',
    page: 1,
    isOwnProducts: true,
    showFilter: false
  };

  onFilterClick = () => {
    this.setState({ showFilter: !this.state.showFilter });
    console.log(this.state.showFilter);
  };

  componentDidMount() {
    this.props.FetchCategories();
    this.props.FetchMerchants();
    this.props.FetchFilteredProducts();
  }

  filterProducts = async e => {
    // this.setState({ isOwnProducts: !(e.target.value && e.target.value !== '') });
    await this.setState({
      [e.target.name]: e.target.value
    });
    this.props.FetchFilteredProducts({ filters: this.state });
  };

  searchBarValue = e => {
    this.setState({
      searchText: e
    });
  };

  submitSearchBox = async () => {
    await this.setState({
      page: 0
    });
    this.props.FetchFilteredProducts({ filters: this.state });
  };

  changePage = async next => {
    if (next) {
      await this.setState({ page: this.state.page + 1 });
    } else {
      if (this.state.page > 1) {
        await this.setState({ page: this.state.page - 1 });
      }
    }

    this.props.FetchFilteredProducts({ filters: this.state });
  };

  render() {
    const {
      Categories,
      Merchants,
      Pending,
      Products,
      UserRole,
      ProductsCount,
      PerPage,
      ToggleImporterModal,
      FetchSelectedProducts
    } = this.props;
    const EndPage = Math.ceil(ProductsCount / PerPage);
    return (
      <div className="club-wrapper">
        <div className="catalog-container khatia" style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
          <Catalog
            categories={Categories}
            merchants={Merchants}
            filterProducts={this.filterProducts}
            UserRole={UserRole}
            ToggleImporterModal={ToggleImporterModal}
            FetchSelectedProducts={FetchSelectedProducts}
            showFilter={this.state.showFilter}
          />
          <ProductContainer
            UserRole={UserRole}
            page={this.state.page}
            pending={Pending}
            products={Products}
            EndPage={EndPage}
            filterProducts={this.searchBarValue}
            submitSearchBox={this.submitSearchBox}
            changePage={this.changePage}
            isOwnProducts={this.state.isOwnProducts}
            showFilter={this.onFilterClick}
          />
        </div>
      </div>
    );
  }
}

export default index;
