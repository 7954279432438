// Fetching Products
export const selectProducts = state => {
  return state.ProductReducer.Products;
};
// Pending
export const selectProductsPending = state => {
  return state.ProductReducer.pending;
};
// Error
export const selectProductsError = state => {
  return state.ProductReducer.error;
};

// Data Count
export const selectProductsCount = state => {
  return state.ProductReducer.productsCount;
};

// Data PerPage
export const selectProductsPerPage = state => {
  return state.ProductReducer.PerPage;
};

// Importer Modal
export const selectImporterModal = state => {
  return state.ProductReducer.ImporterModal;
};

// Import
export const selectImport = state => {
  return state.ProductReducer.import;
};

// Countries
export const selectCountries = state => {
  return state.ProductReducer.Countries;
};
