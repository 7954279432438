export const FETCH_FILTERED_PRODUCTS_PENDING = 'FETCH_FILTERED_PRODUCTS_PENDING';
export const FETCH_FILTERED_PRODUCTS_SUCCESS = 'FETCH_FILTERED_PRODUCTS_SUCCESS';
export const FETCH_FILTERED_PRODUCTS_ERROR = 'FETCH_FILTERED_PRODUCTS_ERROR';

export const FETCH_SELECTED_PRODUCTS_PENDING = 'FETCH_SELECTED_PRODUCTS_PENDING';
export const FETCH_SELECTED_PRODUCTS_SUCCESS = 'FETCH_SELECTED_PRODUCTS_SUCCESS';
export const FETCH_SELECTED_PRODUCTS_ERROR = 'FETCH_SELECTED_PRODUCTS_ERROR';

export const IMPORT_PRODUCTS_PENDING = 'IMPORT_PRODUCTS_PENDING';
export const IMPORT_PRODUCTS_SUCCESS = 'IMPORT_PRODUCTS_SUCCESS';
export const IMPORT_PRODUCTS_ERROR = 'IMPORT_PRODUCTS_ERROR';

export const IMPORT_RETRIEVE_COUNTRIES_PENDING = 'IMPORT_RETRIEVE_COUNTRIES_PENDING';
export const IMPORT_RETRIEVE_COUNTRIES_SUCCESS = 'IMPORT_RETRIEVE_COUNTRIES_SUCCESS';
export const IMPORT_RETRIEVE_COUNTRIES_ERROR = 'IMPORT_RETRIEVE_COUNTRIES_ERROR';

export const TOGGLE_IMPORTER_MODAL = 'TOGGLE_IMPORTER_MODAL';
