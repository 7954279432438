import React, { Component } from 'react';
import { ArrowIncoSvg } from '../../../../assets/svg/SvgIcons';

export default class SelectedItem extends Component {
  state = {
    index: 0
  };

  // Slider
  slider = dir => {
    const { selectedItem, index } = this.state;
    if (dir) {
      if (selectedItem && selectedItem.images[index + 1]) {
        this.setState({ index: index + 1 });
      } else {
        this.setState({ index: 0 });
      }
    } else {
      if (selectedItem && selectedItem.images[index - 1]) {
        this.setState({ index: index - 1 });
      } else {
        this.setState({ index: selectedItem.images.length - 1 });
      }
    }
  };

  render() {
    const { selectedItem, removeSelected, handleCheck, checkedOrNot } = this.props;
    const { index } = this.state;
    return (
      <div className="selected-item">
        <div className="selected-body">
          <div className="close-selected" onClick={() => removeSelected()}>
            X
          </div>
          <h4 className="selected-up-title">Andrew Charles By Andy Hilfiger</h4>
          <h3 className="selected-title">{selectedItem.name}</h3>
          <div className="selected-item-slider">
            <img src={selectedItem.images[index]} />
            <div className="selected-slider-overlay">
              <div className="dots" onClick={() => this.slider(false)}>
                {' '}
                <ArrowIncoSvg />{' '}
              </div>
              <div className="dots right" onClick={() => this.slider(true)}>
                {' '}
                <ArrowIncoSvg />{' '}
              </div>
            </div>
          </div>
          <p className="selected-title-description">{selectedItem.description}</p>

          {selectedItem.options[0].values.length && selectedItem.options[0].values[0] !== ' ' && (
            <div className="selected-options">
              <div className="options-left">
                <h4>Avaliable Colors</h4>
              </div>
              <div className="options-right">
                <p>
                  {selectedItem.options[0].values &&
                    selectedItem.options[0].values.length &&
                    selectedItem.options[0].values.map((v, i) => {
                      return `${v} ${i !== selectedItem.options[0].values.length - 1 ? ', ' : ''}`;
                    })}
                </p>
              </div>
            </div>
          )}

          {selectedItem.options[1].values.length && selectedItem.options[1].values[0] !== ' ' && (
            <div className="selected-options">
              <div className="options-left">
                <h4>Avaliable Sizes</h4>
              </div>
              <div className="options-right">
                <p>
                  {selectedItem.options[1].values &&
                    selectedItem.options[1].values.length &&
                    selectedItem.options[1].values.map((v, i) => {
                      return `${v} ${i !== selectedItem.options[1].values.length - 1 ? ', ' : ''}`;
                    })}
                </p>
              </div>
            </div>
          )}
        </div>
        <div className="selected-footer">
          <div className="selected-footer-left">
            <p className="selected-price">${selectedItem.price}</p>
            <p className="selected-oldprice">${selectedItem.old_price}</p>
          </div>
          <button className="add-selection" onClick={() => handleCheck(selectedItem.id)}>
            {checkedOrNot(selectedItem.id)}
          </button>
        </div>
      </div>
    );
  }
}
