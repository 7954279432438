import React from 'react';

export default function SelectWithLabel({
  label,
  data,
  name,
  selectedValue,
  inputStyleName,
  labelStyleName,
  onChange,
  defaultValue,
  id
}) {
  return (
    <label className={`jr-card-label ${labelStyleName}`} htmlFor={name}>
      {label}
      <select
        name={name}
        className={`jr-card-input jr-card-select ${inputStyleName}`}
        onChange={onChange}
        // value={selectedValue}

        id={id}
      >
        {data.map((item, i) => (
          <option selected={selectedValue == item.id ? 'selected' : ''} value={item.id} key={i}>
            {item.name}
          </option>
        ))}
      </select>
    </label>
  );
}
