import React from 'react';
import translate from '../../../../../appUtility/Translate';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from 'highcharts/modules/map';
import proj4 from 'proj4';
import mapDataIE from '@highcharts/map-collection/custom/world.geo.json';

highchartsMap(Highcharts);

if (typeof window !== 'undefined') {
  window.proj4 = window.proj4 || proj4;
}

const Countries = ({ data, styleName, onChangeCategory }) => {
  const mapOptions = {
    chart: {
      borderWidth: 0,
      map: 'custom/world',
      maxHeight: '100%',
      maxWidt: '100%'
    },
    legend: {
      enabled: false
    },
    title: {
      text: ' '
    },
    credits: {
      enabled: false
    },
    mapNavigation: {
      enabled: false
    },
    tooltip: {
      headerFormat: '',
      pointFormat: '{point.keyword}'
    },

    series: [
      {
        borderColor: '#A0A0A0',
        nullColor: 'rgba(200, 200, 200, 0.3)',
        showInLegend: false,
        name: 'Countries',
        color: '#E0E0E0',
        enableMouseTracking: false,
        mapData: mapDataIE
      },
      {
        type: 'mapbubble',
        name: 'Locations',
        color: '#4169E1',
        minSize: 20,
        maxSize: 20,
        data:
          data.mapData.chart ||
          [
            // { z: 1, keyword: 'Usa', lat: 34.27, lon: -85.25 },
            // { z: 1, keyword: 'Georgia', lat: 43.0, lon: 47.3 },
            // { z: 1, keyword: 'Germany', lat: 13.0, lon: 5.3 },
            // { z: 1, keyword: 'Australia', lat: 3.0, lon: 37.3 },
            // { z: 1, keyword: 'Brazil', lat: 15.0, lon: 55.6 },
            // { z: 1, keyword: 'Japan', lat: 22.045, lon: 75.03 },
            // { z: 1, keyword: 'Spain', lat: 53.4, lon: 12.5 }
          ],
        cursor: 'pointer',
        point: {
          events: {
            click: function() {}
          }
        }
      }
    ]
  };
  let visits = 0;
  let payments = 0;
  let users = 0;
  let sales = 0;
  if (data && data.map) {
    if (data.map.visits && data.map.visits.length > 0) {
      visits =
        data.map.visits.length == 1
          ? data.map.visits[0].cnt
          : data.map.visits.reduce((item, nextItem, index) =>
              index === 1 ? Number(item.cnt) + Number(nextItem.cnt) : item + Number(nextItem.cnt)
            );
    }

    if (data.map.payments && data.map.payments.length > 0) {
      payments =
        data.map.payments.length == 1
          ? data.map.payments[0].cnt
          : data.map.payments.reduce((item, nextItem, index) =>
              index === 1 ? Number(item.cnt) + Number(nextItem.cnt) : item + Number(nextItem.cnt)
            );
    }

    if (data.map.users && data.map.users.length > 0) {
      users =
        data.map.users.length == 1
          ? data.map.users[0].cnt
          : data.map.users.reduce((item, nextItem, index) =>
              index === 1 ? Number(item.cnt) + Number(nextItem.cnt) : item + Number(nextItem.cnt)
            );
    }

    if (data.map.sales && data.map.sales.length > 0) {
      sales =
        data.map.sales.length == 1
          ? data.map.sales[0].cnt
          : data.map.sales.reduce((item, nextItem, index) =>
              index === 1 ? Number(item.cnt) + Number(nextItem.cnt) : item + Number(nextItem.cnt)
            );
      sales = Number(sales).toFixed(2);
    }
  }

  const average = data && data.mapData ? data.mapData.average : [];
  return (
    <div className={`dashboard-card dashboard-card--big countries-box ${styleName}`}>
      <div className="countries-aside">
        <h3 className="countries-heading">{translate('countries')}</h3>

        {average && average.length > 0 && (
          <ul className="countries-list">
            <li className="countries-list-item">
              <span className="line" style={{ width: `${average[0].percent}%` }} />
              <p>
                {average[0].country_code} <label>{average[0].percent}%</label>
              </p>
            </li>
            {average[1] && (
              <li className="countries-list-item">
                <span className="line" style={{ width: `${average[1].percent}%` }} />
                <p>
                  {average[1].country_code} <label>{average[1].percent}%</label>
                </p>
              </li>
            )}
            {average[2] && (
              <li className="countries-list-item">
                <span className="line" style={{ width: `${average[2].percent}%` }} />
                <p>
                  {average[2].country_code}
                  <label>{average[2].percent}%</label>
                </p>
              </li>
            )}
          </ul>
        )}

        <div className="countries-boxes">
          <div className="country-box" onClick={() => onChangeCategory('visits')}>
            <h4>{visits}</h4>
            <h5>{translate('Users')}</h5>
          </div>

          <div className="country-box" onClick={() => onChangeCategory('payments')}>
            <h4>{payments}</h4>
            <h5>{translate('successful_payments')}</h5>
          </div>

          <div className="country-box" onClick={() => onChangeCategory('users')}>
            <h4>{users}</h4>
            <h5>{translate('new_users')}</h5>
          </div>

          <div className="country-box rounded" onClick={() => onChangeCategory('sales')}>
            <h4>${sales}</h4>
            <h5>{translate('total_sales')}</h5>
          </div>
        </div>
      </div>

      <div className="countries-map">
        {data && <HighchartsReact constructorType={'mapChart'} highcharts={Highcharts} options={mapOptions} />}
      </div>
    </div>
  );
};

export default Countries;
