import React from 'react';
import { Link } from 'react-router-dom';
import translate from '../../../../appUtility/Translate';

export default function SliderSidebar() {
  return (
    <div className="tab-wrapper tab-wrapper-res">
      <div className="tab-wrapper-content" style={{ width: '200px', height: '100%', backgroundColor: '#f0f0f6' }}>
        <div className="manage-catalog">{translate('edit_category')}</div>
        <div className="add-new-product">
          <Link to="/app/club/category/wizard/">
            <button className="add-new-product-button">{translate('add_new_category')}</button>
          </Link>
        </div>
        <div className="product-filters">
          <div className="filters-container">
            <div style={{ width: '181px', margin: 'auto', marginBottom: '10px' }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
