import * as types from './auth.actionTypes';

const initState = {
  admin_name: '',
  pending: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('token'),
  role: null,
  error: null,
  newPasswordSuccess: false,
  ValidatepasswordResetRoute: true
};

const AuthReducer = (state = initState, action) => {
  switch (action.type) {
    // Sign Up New Merchant
    case types.SIGN_UP_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.SIGN_UP_SUCCESS:
      return {
        ...state,
        pending: false,
        authUser: action.payload.token,
        admin_name: action.payload.admin_name,
        initURL: '/app/dashboard'
      };
    case types.SIGN_UP_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload
      };

    // Sign In
    case types.SIGN_IN_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.SIGN_IN_SUCCESS:
      return {
        ...state,
        pending: false,
        authUser: action.payload.token,
        admin_name: action.payload.admin_name,
        initURL: '/app/dashboard'
      };
    case types.SIGN_IN_ERROR:
      return {
        ...state,
        pending: false,
        alertMessage: action.payload.error,
        showMessage: true
      };

    // Log Out
    case types.LOG_OUT_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.LOG_OUT_SUCCESS:
      return {
        ...state,
        authUser: null,
        initURL: '/signin',
        pending: false,
        showMessage: false,
        alertMessage: ''
      };
    case types.LOG_OUT_ERROR:
      return {
        ...state,
        pending: false,
        showMessage: true,
        alertMessage: action.payload.error
      };

    // Password Update Request
    case types.RESET_PASSWORD_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        newPasswordSuccess: true
      };
    case types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        pending: false,
        newPasswordSuccess: false
      };

    // Check Update Password Route
    case types.CHECK_RESET_PASSWORD_ROUTE_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.CHECK_RESET_PASSWORD_ROUTE_SUCCESS:
      return {
        ...state,
        pending: false
      };
    case types.CHECK_RESET_PASSWORD_ROUTE_ERROR:
      return {
        ...state,
        pending: false,
        ValidatepasswordResetRoute: false
      };

    // Update Password
    case types.UPDATE_PASSWORD_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        pending: false,
        ValidatepasswordResetRoute: false
      };
    case types.UPDATE_PASSWORD_ERROR:
      return {
        ...state,
        pending: false,
        ValidatepasswordResetRoute: false
      };

    // Error Message
    case types.HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        pending: false
      };
    }
    case types.ON_SHOW_LOADER: {
      return {
        ...state,
        pending: true
      };
    }

    default:
      return state;
  }
};

export default AuthReducer;
