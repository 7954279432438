import React, { useState } from 'react';
import Header from 'components/Header/index';
import Product from './Product';
import axios from '../../../../appUtility/Api';
import DeleteHeaderModal from '../../../../components/Header/deleteModal';
import SweetAlert from 'react-bootstrap-sweetalert';
import { CircularProgress } from '@material-ui/core';

export default function ProductContainer(props) {
  const { products, pending, UserRole } = props;

  const [checked, setCheked] = useState([]);
  const [alert, setAlert] = useState(null);

  const handleCheck = item => {
    const checkedArray = [...checked];
    const match = checkedArray.find(i => i === item);

    if (match) {
      const index = checkedArray.indexOf(match);
      checkedArray.splice(index, 1);
    } else {
      checkedArray.push(item);
    }

    setCheked(checkedArray);
  };

  const deleteThisProduct = () => {
    let getAlert = () => {
      return (
        <SweetAlert
          warning
          show
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure that you want to delete?"
          onConfirm={() => confirmDelete()}
          onCancel={hideAlert}
        ></SweetAlert>
      );
    };

    setAlert(getAlert());
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const confirmDelete = () => {
    setAlert(null);
    axios({
      method: 'post',
      url: axios.defaults.baseURL + '/app/club/deleteproducts',
      data: JSON.stringify({ id: checked }),
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    }).then(res => {
      if (res.data.success) {
        window.location.reload();
      }
    });
  };

  const importProducts = () => {
    setAlert(null);
    axios({
      method: 'post',
      url: axios.defaults.baseURL + '/app/club/importFromMerchant',
      data: JSON.stringify({ id: checked }),
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    }).then(res => {
      if (res.data.success) {
        window.location.reload();
      }
    });
  };

  const clearList = () => {
    setCheked([]);
  };

  const getSearchBarValue = e => {
    props.filterProducts(e);
  };

  const submitSearchBox = () => {
    props.submitSearchBox();
  };

  return (
    <>
      {alert}

      <div className="club-main-wrapper">
        {checked.length > 0 ? (
          <DeleteHeaderModal
            count={checked.length}
            confirmDelete={deleteThisProduct}
            handleImport={importProducts}
            clearList={clearList}
            isOwnProducts={props.isOwnProducts}
          />
        ) : (
          <Header
            productsLength={products && products.length > 0 ? products.length : 0}
            search={true}
            getSearchBarValue={getSearchBarValue}
            submitSearchBox={submitSearchBox}
          />
        )}

        <div className="club-products" style={{ height: '100%' }}>
          <div className="resp-filter-container">
            <span className="resp-filter" onClick={() => props.showFilter()}>
              <i className="fas fa-filter"></i>
            </span>
          </div>
          <div className="row club-products-container" style={{ height: '100%' }}>
            {pending ? (
              <div className="progress-container">
                {' '}
                <CircularProgress />{' '}
              </div>
            ) : (
              <>
                {products &&
                  products.length > 0 &&
                  products.map((product, i) => {
                    return (
                      <Product
                        key={i}
                        {...product}
                        UserRole={UserRole}
                        handleDeleteItems={handleCheck}
                        checkedArray={checked}
                        isOwnProducts={props.isOwnProducts}
                      />
                    );
                  })}
              </>
            )}
          </div>
          <div className="text-center">
            {props.page && props.page > 1 && (
              <button className="btn btn-primary" onClick={() => props.changePage(false)}>
                Previous
              </button>
            )}
            {props.page != props.EndPage && (
              <button className="btn btn-primary" onClick={() => props.changePage(true)}>
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
