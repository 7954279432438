import React, { useState } from 'react';
import DateRangePicker from 'react-daterange-picker';
import moment from 'moment';
import { Button } from '@material-ui/core';
import DateRangePeriod from './DateRangePeriods';
import translate from '../../appUtility/Translate';

export default function DateRangePickerComp(props) {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [startDateForCalendar, setStartDateForCalendar] = useState(props.startDate);
  const [endDateForCalendar, setEndDateForCalendar] = useState(props.endDate);
  const [changedDataRange, setChangedDataRange] = useState(false);

  const onSelect = dates => {
    setChangedDataRange(true);
    setStartDateForCalendar(dates.start._d); // on date select, save value to state
    setEndDateForCalendar(dates.end._d);
  };

  const onCancelBtnClick = () => {
    setDialogOpened(!dialogOpened);
    setStartDateForCalendar(props.startDate); // set start and end date to previous
    setEndDateForCalendar(props.endDate);
  };

  const onUpdateBtnClick = () => {
    setDialogOpened(!dialogOpened);
    props.onDateUpdate(startDateForCalendar, endDateForCalendar);
  };

  const setDateRangeCalendar = (start, end) => {
    setChangedDataRange(false);
    setStartDateForCalendar(start);
    setEndDateForCalendar(end);
  };

  return (
    <div key="basic_day" className={'picker picker-red'}>
      <Button
        className={'jr-btn date-range-picker__btn ' + props.customStyleName}
        onClick={() => setDialogOpened(true)}
      >
        {`${moment(props.startDate).format('MMM DD, YYYY')}`} &nbsp; &nbsp;
        <i className="zmdi zmdi-arrow-right"></i> &nbsp; &nbsp;
        {`${moment(props.endDate).format('MMM DD, YYYY')}`} &nbsp; &nbsp;
        <i className="zmdi zmdi-caret-down"></i>
      </Button>

      {dialogOpened && (
        <div className="date-range__picker--wrapper fade-in-effect animated">
          <div className="date-range__picker--left">
            <DateRangePeriod
              changedDataRange={changedDataRange}
              startDate={props.startDate}
              // setStartDateForCalendar={setStartDateForCalendarHandler}
              // setEndDateForCalendar={setEndDateForCalendarHandler}

              setDateRangeCalendar={setDateRangeCalendar}
            />
          </div>

          <div className="date-range__picker--right">
            <DateRangePicker
              onSelect={onSelect}
              value={moment.range(startDateForCalendar, endDateForCalendar)}
              numberOfCalendars={2}
            />

            <div className="date-range__picker--btns">
              <button className="date-range__picker--cancelBtn" onClick={onCancelBtnClick}>
                {translate('cancel')}
              </button>
              <button className="date-range__picker--updateBtn" onClick={onUpdateBtnClick}>
                {translate('update')}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
