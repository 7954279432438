import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import CardBox from 'components/CardBox/index';
import translate from '../../../../../appUtility/Translate';
import SelectWithLabel from '../../../../../components/commonElements/SelectWithLabel';
import TextInputWithLabel from '../../../../../components/commonElements/TextInputWithLabel';
import CreateAttributeValue from './CreateAttributesValue';
import { AppNodeAPI as AxiosNode } from '../../../../../appUtility/Api';
import { AttributeTypeConstantName } from './AttributeUtils';

const CreateNewAttribute = ({ data, onClose, ...props }) => {
  const [attributeData, setAttributeData] = useState({
    label: data ? data.label : '',
    isRequired: data ? data.isRequired : false,
    isGlobal: data ? data.isGlobal : false,
    type: data
      ? data.type
        ? data.type.id
        : (props.attributeTypes[0] || { id: undefined }).id
      : (props.attributeTypes[0] || { id: undefined }).id,
    values: data ? data.values : []
  });

  const [, forceUpdate] = useState();

  const handleChange = event => {
    const newData = { ...attributeData };
    const attr = event.currentTarget.name;
    if (attr === 'isRequired' || attr === 'isGlobal') {
      newData[attr] = event.currentTarget.checked;
    } else {
      switch (attr) {
        case 'type': {
          newData[attr] = parseInt(event.currentTarget.value);
          newData.values = [{ isDefault: false, value: '' }];
          if (!!data) {
            if (newData[attr] === data.type.id) newData.values = data.values;
          }
          break;
        }
        default: {
          newData[attr] = event.currentTarget.value;
          break;
        }
      }
    }
    setAttributeData(newData);
  };

  const validator = React.useRef(new SimpleReactValidator());

  const createAttribute = async () => {
    if (validator.current.allValid()) {
      try {
        await AxiosNode.post(`/attribute`, {
          ...attributeData,
          values: attributeData.values.filter(v => !!v.value)
        });
        await props.getData();
        onClose();
      } catch (error) {
        onClose();
      }
    } else {
      validator.current.showMessages();
      forceUpdate(1);
    }
  };

  const updateAttribute = async () => {
    try {
      await AxiosNode.put(`/attribute/${data.id}`, {
        ...attributeData,
        values: attributeData.values.filter(v => !!v.value)
      });
      await props.getData();
      onClose();
    } catch (error) {
      onClose();
    }
  };

  let showAddButton = true;

  switch (props.attributeTypes.find(type => type.id === attributeData.type).constantName) {
    case AttributeTypeConstantName.TEXTAREA:
    case AttributeTypeConstantName.NUMBER:
    case AttributeTypeConstantName.TEXT: {
      if (attributeData.values.length >= 1) {
        showAddButton = false;
      }
      break;
    }
    case AttributeTypeConstantName.SWITCH: {
      break;
    }
    default: {
      showAddButton = true;
      break;
    }
  }

  return (
    <div className="create-new-attribute">
      <>
        <div className="atrribute-buttons">
          <button onClick={onClose} className="attribute-cancel-button">
            Cancel
          </button>
          <button onClick={data ? updateAttribute : createAttribute}>{translate(data ? 'Update' : 'Create')}</button>
        </div>
      </>
      <div className="club-add-product-content">
        <CardBox heading={translate('Attribute Properties')} styleName="col-12">
          <div className="jr-card-form" style={{ paddingTop: '20px' }}>
            <TextInputWithLabel
              label={translate('Attribute Label')}
              name="label"
              value={attributeData.label}
              onChange={handleChange}
              onBlur={validator.current.hideMessageFor('label')}
            />
            {validator.current.message('label', attributeData.label, 'required')}
            <div className="category_ismain">
              <label htmlFor="switch" className="switch_label">
                {translate('isRequired')}
              </label>
              <label className="switch">
                <input type="checkbox" name="isRequired" checked={attributeData.isRequired} onChange={handleChange} />
                <span className="slider round"></span>
              </label>
            </div>
            <div className="category_ismain">
              <label htmlFor="switch" className="switch_label">
                {translate('isGlobal')}
              </label>
              <label className="switch">
                <input type="checkbox" name="isGlobal" checked={attributeData.isGlobal} onChange={handleChange} />
                <span className="slider round"></span>
              </label>
            </div>
            <SelectWithLabel
              data={(props.attributeTypes || []).map(item => ({
                id: item.id,
                name: item.title
              }))}
              label={translate('Type')}
              name="type"
              selectedValue={attributeData.type}
              onChange={handleChange}
            />
            <CardBox styleName={'custom_attribute_values_card_box'} heading={translate('Attribute Values')}>
              <div className={''}>
                {attributeData.values.map((value, index) => (
                  <CreateAttributeValue
                    key={index}
                    index={index}
                    label={value.value}
                    isDefault={value.isDefault}
                    onTextChange={event => {
                      setAttributeData({
                        ...attributeData,
                        values: attributeData.values.map((stateValue, vindex) =>
                          index === vindex
                            ? {
                                ...stateValue,
                                value: event.currentTarget.value
                              }
                            : stateValue
                        )
                      });
                    }}
                    cancel={i => event => {
                      setAttributeData({
                        ...attributeData,
                        values: attributeData.values.filter((_, vindex) => vindex !== i)
                      });
                    }}
                    onSwitchChange={event => {
                      setAttributeData({
                        ...attributeData,
                        values: attributeData.values.map((stateValue, vindex) =>
                          index === vindex
                            ? {
                                ...stateValue,
                                isDefault: event.currentTarget.checked
                              }
                            : {
                                ...stateValue,
                                isDefault: false
                              }
                        )
                      });
                    }}
                  />
                ))}
                {showAddButton && (
                  <div className="atrribute-buttons" style={{ position: 'static' }}>
                    <button
                      onClick={() => {
                        if (
                          (attributeData.values[attributeData.values.length - 1] &&
                            attributeData.values[attributeData.values.length - 1].value.trim().length !== 0) ||
                          attributeData.values.length === 0
                        ) {
                          setAttributeData({
                            ...attributeData,
                            values: [...attributeData.values, { isDefault: false, value: '' }]
                          });
                        }
                      }}
                    >
                      {translate('Add')}
                    </button>
                  </div>
                )}
              </div>
            </CardBox>
          </div>
        </CardBox>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    attributeTypes: state.AttributesReducer.attributeTypes
  };
};

export default withRouter(connect(mapStateToProps)(CreateNewAttribute));
