import { connect } from 'react-redux';
import { FetchDashboardData, FetchDashboardMapData } from './dashboard.actions';
import {
  selectDashboardData,
  selectDashboardPending,
  selectDashboardError,
  selectDashboardMapPending,
  selectDashboardMapData,
  selectDashboardMapError
} from './dashboard.selector';
import Dashboard from '../../app/routes/Dashboard/index';

const mapStateToProps = state => {
  return {
    Pending: selectDashboardPending(state),
    Data: selectDashboardData(state),
    ErrorMessage: selectDashboardError(state),
    MapDataPending: selectDashboardMapPending(state),
    MapData: selectDashboardMapData(state),
    MapDataErrorMessage: selectDashboardMapError(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchDashboardData: data => dispatch(FetchDashboardData(data)),
    FetchDashboardMapData: data => dispatch(FetchDashboardMapData(data))
  };
};

const DashboardView = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default DashboardView;
