import React, { useState } from 'react';
import upArrow from '../../../assets/icons/arrow.png';
import downArrow from '../../../assets/icons/arrow_down.png';
import translate from '../../../appUtility/Translate';

const ChartCard = ({ value, subValue, title, styleName, percent, icon }) => {
  const [bubbleVisible, setBubbleVisible] = useState(false);
  return (
    <div className={`dashboard-card dashboard-card--small ${styleName}`}>
      {bubbleVisible && (
        <div className="average-bubble">
          <p>{`${translate('average')} ${title}`}</p>
        </div>
      )}
      <div>
        <h1 className="dashboard-card--small__value">
          {value === null || isNaN(value) ? <span>0</span> : <span>{value}</span>}

          {subValue && (
            <span
              className="dashboard-card--small__sub-value"
              onMouseOver={e => setBubbleVisible(true)}
              onMouseOut={() => setBubbleVisible(false)}
            >
              {/*({subValue})*/}
              {subValue === null || isNaN(subValue) ? <span>0</span> : <span>{subValue}</span>}
            </span>
          )}
        </h1>
        <p className="dashboard-card--small__title">{title}</p>
      </div>
      <div className="dashboard--column">
        {percent && !isNaN(percent) ? (
          <div className="dashboard-card--small__percent">
            <img src={percent > 0 ? upArrow : downArrow} alt={'upArrow'} />
            <p className={percent > 0 ? 'positive' : 'negative'}>{percent}%</p>
          </div>
        ) : (
          <></>
        )}
        {icon && <img src={icon} alt={title} />}
      </div>
    </div>
  );
};

export default ChartCard;
