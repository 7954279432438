import React, { useState, useEffect } from 'react';
import CardBox from 'components/CardBox/index';
import translate from '../../../../appUtility/Translate';
import CheckBox from '../../../../components/commonElements/Checkbox';
import TextInputWithLabel from '../../../../components/commonElements/TextInputWithLabel';
import SelectWithLabel from '../../../../components/commonElements/SelectWithLabel';
import { CircularProgress } from '@material-ui/core';
import axios from '../../../../appUtility/Api';

export default function General({
  handleChange,
  handleChangeFeatured,
  categories,
  stores,
  handleCountries,
  pending,
  errors,
  data,
  hide_in,
  show_in,
  removeCountry,
  addCountry
}) {
  const handleInputChange = item => {
    handleCountries(item);
  };
  if (window.location.hostname == 'homerun.webiz.ge') {
    stores = stores.map(function(item) {
      return item.name == 'Clalit' ? (item.name = 'Homerun') : item;
    });
  }
  return (
    <div className="club-add-product-content">
      <>
        <CardBox heading={translate('general_information')} styleName="col-12">
          <form className="jr-card-form">
            <TextInputWithLabel
              label={translate('product_name')}
              name="name"
              value={data.name}
              onChange={handleChange}
            />
            {errors.name && errors.name.status && <p className={'input-error'}>{errors.name.message}</p>}
            <SelectWithLabel
              data={stores}
              label={translate('merchant')}
              name="store_id"
              selectedValue={data.store_id}
              onChange={handleChange}
            />
            <SelectWithLabel
              data={categories}
              label={translate('category')}
              name="category"
              selectedValue={data.category}
              onChange={handleChange}
            />

            <div className="row ">
              <div className="col-12">
                <div className="category_ismain">
                  <label htmlFor="switch" className="switch_label">
                    {translate('visible_for_other_clubs')}
                  </label>
                  <label className="switch" id="switch">
                    <input
                      type="checkbox"
                      name="blocked"
                      checked={data.blocked}
                      value={data.blocked}
                      onChange={handleChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>

              <div className="col-12">
                <div className="category_ismain">
                  <label htmlFor="switch" className="switch_label">
                    {translate('Featured')}
                  </label>
                  <label className="switch" id="switch">
                    <input
                      type="checkbox"
                      name="featured"
                      checked={data.featured}
                      value={data.featured}
                      onChange={handleChangeFeatured}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            {/* <SwitcherWithLabel label={translate('status')} onChange={handleChange} /> */}
          </form>
        </CardBox>
        <CardBox heading={translate('show_on_paylix_club')} styleName="col-12">
          {/*{country.map((item, index) => (*/}
          {/*  // data && data.show_in_countries && data.show_in_countries[index] !== item.id &&*/}
          {/*  <CheckBox*/}
          {/*    key={index}*/}
          {/*    item={item}*/}
          {/*    exclude={data && data.show_in_countries && data.show_in_countries[index]}*/}
          {/*    handleInputChange={handleInputChange}*/}
          {/*  >*/}
          {/*    {item.name}*/}
          {/*  </CheckBox>*/}
          {/*))}*/}
          <div className="col-12">
            <div className="row">
              <CardBox styleName="col-6">
                <div>
                  <h1>{translate('show_in')}</h1>
                  <div className="countries">
                    {show_in &&
                      show_in.map((el, index) => {
                        return (
                          <div key={index} id={index} className="country-select" onClick={() => removeCountry(el)}>
                            <span>{el.name}</span>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </CardBox>
              <CardBox styleName="col-6">
                <div>
                  <h1>{translate('hide_in')}</h1>
                  <div className="countries">
                    {hide_in &&
                      hide_in.map((el, index) => {
                        return (
                          <div key={el.id} id={index} className="country-select" onClick={() => addCountry(el)}>
                            {el.name}
                          </div>
                        );
                      })}
                  </div>
                </div>
              </CardBox>
            </div>
          </div>
        </CardBox>
      </>
    </div>
  );
}
