import React from 'react';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import SidenavContent from './SidenavContent';
import { COLLAPSED_DRAWER, FIXED_DRAWER, MINI_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { toggleCollapsedNav, updateWindowWidth } from 'actions/Setting';
import PaylixLogo from '../../assets/images/paylix_icon.png';
import axios from '../../appUtility/Api';

class SideNav extends React.PureComponent {
  _isMounted = false;
  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  componentDidMount() {
    this._isMounted = true;
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth);
    });
  }

  // componentWillUnmount() {
  //   this._isMounted = false;
  // }

  render() {
    const { navCollapsed, drawerType, width, navigationStyle, role, company } = this.props;
    let drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'd-xl-flex'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? ''
      : 'd-flex';
    let type = 'permanent';
    if (
      drawerType.includes(COLLAPSED_DRAWER) ||
      ((drawerType.includes(FIXED_DRAWER) || drawerType.includes(MINI_DRAWER)) && width < 1200)
    ) {
      type = 'temporary';
    }

    if (navigationStyle === HORIZONTAL_NAVIGATION) {
      drawerStyle = '';
      type = 'temporary';
    }
    return (
      <div className={`app-sidebar d-none ${drawerStyle}`}>
        <Drawer
          className="app-sidebar-content"
          variant={type}
          open={type.includes('temporary') ? navCollapsed : true}
          onClose={this.onToggleCollapsedNav}
          classes={{
            paper: 'side-nav'
          }}
        >
          {/* <UserInfo/> */}

          <SidenavContent role={role} company={company} />
        </Drawer>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { navCollapsed, drawerType, width, navigationStyle } = settings;
  return { navCollapsed, drawerType, width, navigationStyle };
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav, updateWindowWidth })(SideNav));
