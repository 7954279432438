import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Settings from './Settings';
import Auth from './Auth';
import merchantReducer from '../store/signUp/signup.reducer';
import categoryReducer from '../store/category/category.reducer';
import MerchantReducer from '../store/merchant/merchant.reducer';
import ProductReducer from '../store/catalog/catalog.reducer';
import OrdersReducer from '../store/order/order.reducer';
import AuthReducer from '../store/auth/auth.reducer';
import UsersReducer from '../store/user/user.reducer';
import CustomerReducer from '../store/customer/customer.reducer';
import TranslationReducer from '../store/translation/translation.reducer';
import DashboardReducer from '../store/dashboard/dashboard.reducer';
import AttributesReducer from '../store/attributes/attributes.reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    merchantReducer,
    categoryReducer,
    MerchantReducer,
    ProductReducer,
    OrdersReducer,
    AuthReducer,
    UsersReducer,
    CustomerReducer,
    TranslationReducer,
    DashboardReducer,
    AttributesReducer
  });
