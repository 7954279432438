import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import translate from '../../../../appUtility/Translate';

export default function SliderSidebar({ id, changeLanguage, shouldDisplayFilter, onSubmit }) {
  var saveButton = false;

  if (!changeLanguage) {
    saveButton = true;
  }

  return (
    <div className="tab-wrapper slider-resp">
      <div className="manage-slider-resp" style={{ width: '200px', height: '100%', backgroundColor: '#f0f0f6' }}>
        <div className="manage-catalog">{translate('manage_slider')}</div>
        <div className="add-new-product">
          <Link to="/app/club/slider/wizard">
            <button className="add-new-product-button">{translate('add_new_slider')}</button>
          </Link>
        </div>
        {!id && shouldDisplayFilter && (
          <div className="product-filters">
            <h1>{translate('filters')}</h1>
            <div className="filters-container">
              <div style={{ width: '181px', margin: 'auto', marginBottom: '10px' }}>
                <select
                  id="currency"
                  className="browser-default custom-select"
                  onChange={event => {
                    changeLanguage(event);
                  }}
                >
                  <option value="en">{translate('english')}</option>}<option value="ru">{translate('russian')}</option>}
                  <option value="he">{translate('hebrew')}</option>}
                </select>
              </div>
            </div>
          </div>
        )}
        {saveButton && (
          <div className="tab-buttons">
            <button onClick={onSubmit}>Save</button>
            <NavLink className="prepend-icon" to="/app/club/slider/listing">
              <button>Cancel</button>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}
