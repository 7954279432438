import React, { Component } from 'react';
import SearchBox from '../SearchBox';

class SearchBar extends Component {
  render() {
    return <SearchBox onChange={this.props.updateSearchText} value={this.props.value} onSubmit={this.props.onSubmit} />;
  }
}

export default SearchBar;
