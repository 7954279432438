import * as types from './merchant.actionTypes';

// Fetching Merchants
export function FetchMerchantsPending() {
  return {
    type: types.FETCH_MERCHANTS_PENDING
  };
}
export function FetchMerchantsSuccess(merchants) {
  return {
    type: types.FETCH_MERCHANTS_SUCCESS,
    payload: merchants
  };
}
export function FetchMerchantsError(error) {
  return {
    type: types.FETCH_MERCHANTS_ERROR,
    payload: error
  };
}

// Set Merchants Page
export function SetMerchantsPage(page) {
  return {
    type: types.SET_MERCHANTS_TABLE_PAGE,
    payload: page
  };
}
