import React from 'react';
import CardBox from 'components/CardBox/index';
import translate from '../../../../appUtility/Translate';
import TextInputWithLabel from '../../../../components/commonElements/TextInputWithLabel';

export default function Description({ data, handleChange, errors }) {
  return (
    <div>
      <div className="club-add-product-content">
        <CardBox heading={translate('description')} styleName="col-12">
          <div>
            <TextInputWithLabel name="description" value={data.description} onChange={handleChange} multiline={true} />
            {errors.description && errors.description.status && (
              <p className={'input-error'}>{errors.description.message}</p>
            )}
          </div>
        </CardBox>
      </div>
    </div>
  );
}
