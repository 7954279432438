import React, { Component } from 'react';
import axios from '../../../appUtility/Api';
import translate from '../../../appUtility/Translate';
import DateRangePickerComp from '../../../components/Header/DateRangePickerComp';
import moment from 'moment';

class Catalog extends Component {
  state = {
    storeData: [],
    source: '',
    status: '',
    store: '',
    searchText: '',
    dateFrom: moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD'),
    dateTo: moment()
  };

  getStoreData = () => {
    fetch(axios.defaults.baseURL + '/app/club/storedata', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        this.setState({ storeData: data.store });
      });
    axios({
      method: 'get',
      url: axios.defaults.baseURL + '/app/club/storedata',
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    }).then(res => {
      this.setState({ storeData: res.data.store });
    });
  };

  handleChange = async e => {
    await this.setState({
      [e.target.id]: e.target.value
    });
    const filters = {
      source: this.state.source,
      status: this.state.status,
      store: this.state.store
      // "searchText" : this.props.searchText
    };
    this.props.filterValues(filters);
    this.props.handleFilterData(filters);
  };

  handleDateUpdate = async (dateFrom, dateTo) => {
    const formatedFrom = moment(dateFrom).format('YYYY-MM-DD h:mm:ss');
    const formatedTo = moment(dateTo).format('YYYY-MM-DD h:mm:ss');

    await this.setState({
      dateFrom: formatedFrom,
      dateTo: formatedTo
    });

    const filters = {
      source: this.state.source,
      status: this.state.status,
      store: this.state.store,
      date_from: this.state.dateFrom,
      date_to: this.state.dateTo
      // "searchText" : this.props.searchText
    };
    this.props.filterValues(filters);
    this.props.handleFilterData(filters);
  };

  componentDidMount() {
    this.getStoreData();
  }

  render() {
    const { source, status, store } = this.state;

    return (
      <div
        style={{
          width: '200px',
          height: '100%',
          paddingRight: 10,
          paddingLeft: 10,
          backgroundColor: '#f0f0f6',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <div className="manage-catalog">{translate('orders')}</div>
        <div className="product-filters">
          <h1>Filters</h1>
          <div className="filters-container">
            <div style={{ flexGrow: 1, marginBottom: '10px' }}>
              <select
                id="source"
                className="browser-default custom-select"
                onChange={e => this.handleChange(e)}
                value={source}
              >
                <option value="">Platform</option>
                <option value="merchant">Merchant</option>
                <option value="app">Application</option>
              </select>
            </div>
            <div style={{ flexGrow: 1, marginBottom: '10px' }}>
              <select
                id="status"
                className="browser-default custom-select"
                onChange={e => this.handleChange(e)}
                value={status}
              >
                <option value="">Status</option>
                <option value={1}>Pending</option>
                <option value={2}>Processing</option>
                <option value={3}>Completed</option>
                <option value={5}>On hold</option>
                <option value={6}>Cancell</option>
                <option value={8}>Refunded</option>
                <option value={17}>Failed</option>
              </select>
            </div>
            <div style={{ flexGrow: 1, marginBottom: '10px' }}>
              <select
                id="store"
                className="browser-default custom-select"
                onChange={e => this.handleChange(e)}
                value={store}
              >
                <option value="">{translate('store')}</option>
                {this.state.storeData.map((shop, i) => {
                  return (
                    <option key={i} value={shop.store_id}>
                      {shop.store_name}
                    </option>
                  );
                })}
              </select>
            </div>
            <div
              style={{
                background: '#fff',
                border: '1px solid #ced4da',
                borderRadius: '0.25rem',
                width: '100%',
                height: '36px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <DateRangePickerComp
                startDate={this.state.dateFrom}
                endDate={this.state.dateTo}
                onDateUpdate={this.handleDateUpdate}
                customStyleName={'date-range__picker--no-padding'}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Catalog;
