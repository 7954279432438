// Fetching Categories
export const selectCategories = state => {
  return state.categoryReducer.Categories;
};
// Pending
export const selectCategoriesPending = state => {
  return state.categoryReducer.pending;
};
// Error
export const selecCategoriesError = state => {
  return state.categoryReducer.error;
};
