import * as types from './attributes.actionTypes';

const initState = {
  attributes: [],
  attributeTypes: [],
  error: false
};

const AttributesReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_ATTRIBUTES_DATA:
      return {
        ...state,
        attributes: action.payload,
        error: false
      };
    case types.GET_ATTRIBUTES_DATA_FAILD:
      return {
        ...state,
        error: true
      };
    case types.GET_ATTRIBUTES_TYPES:
      return {
        ...state,
        attributeTypes: action.payload
      };
    default:
      return state;
  }
};

export default AttributesReducer;
