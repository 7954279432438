import { connect } from 'react-redux';
import { FetchCustomers, SetPage, DeleteCustomer, UploadCustomersFile, BlockCustomer } from './customer.actions';
import { selectCustomers, selectCustomersPending, selecCustomersError, selecCustomersPage } from './customer.selector';
import Customer from '../../app/routes/Customers/CustomerTable/index';

const mapStateToProps = state => {
  return {
    Pending: selectCustomersPending(state),
    Customers: selectCustomers(state),
    ErrorMessage: selecCustomersError(state),
    page: selecCustomersPage(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchCustomers: data => dispatch(FetchCustomers(data)),
    SetPage: page => dispatch(SetPage(page)),
    DeleteCustomer: id => dispatch(DeleteCustomer(id)),
    UploadCustomersFile: file => dispatch(UploadCustomersFile(file)),
    BlockCustomer: id => dispatch(BlockCustomer(id))
  };
};

const CustomerView = connect(mapStateToProps, mapDispatchToProps)(Customer);

export default CustomerView;
