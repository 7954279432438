import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import translate from '../../../../appUtility/Translate';

class Catalog extends Component {
  combinedFunctions = () => {
    const { categories, merchants } = this.props;
    const filters = {
      // category : categories && categories.length && categories[0].category_id,
      merchant: merchants && merchants.length && merchants[0].store_id
    };

    this.props.ToggleImporterModal();
    this.props.FetchSelectedProducts({ filters });
  };

  render() {
    const { categories, merchants, filterProducts, UserRole, ToggleImporterModal, FetchSelectedProducts } = this.props;
    return (
      <div
        className="club-m club-m-resp"
        style={{
          width: '200px',
          height: '100%',
          backgroundColor: '#f0f0f6',
          display: this.props.showFilter ? 'block' : 'none'
        }}
      >
        <div className="manage-catalog">{translate('manage_catalog')}</div>
        <div className="add-new-product">
          <Link to="/app/club/product/wizard">
            <button className="add-new-product-button">{translate('add_new_product')}</button>
          </Link>
        </div>
        {UserRole > 0 && UserRole !== 2 && (
          window.location.hostname == 'homerun.webiz.ge' ? null : <div className="add-new-product" onClick={this.combinedFunctions}>
            {/*<Link to="/app/club/product/importer">*/}
            {/* <button className="import-products-button">{translate('import_from_clalit_club')}</button>  */} {/* Must be REVERTED in the future */}
            <button className="import-products-button">Import From Internity Club</button>
            {/*</Link>*/}
          </div>


        )}
        <div className="product-filters">
          <h1>{translate('filters')}</h1>
          <div className="filters-container">
            <div style={{ width: '181px', margin: 'auto', marginBottom: '10px' }}>
              <select className="browser-default custom-select" name="category" onChange={e => filterProducts(e)}>
                <option value="">{translate('all_categories')}</option>
                {categories.length > 0 &&
                  categories.map(c => {
                    return (
                      <option value={c.category_id} key={c.id}>
                        {c.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            {UserRole !== 2 && UserRole !== 3 && (
              <div style={{ width: '181px', margin: 'auto', marginBottom: '10px' }}>
                <select
                  name="merchant"
                  className="browser-default custom-select"
                  style={{ width: '181px', margin: 'auto' }}
                  onChange={e => filterProducts(e)}
                >
                  <option value="">{translate('my_store')}</option>
                  {merchants &&
                    merchants.length > 0 &&
                    merchants.map(c => {
                      return (
                        c.store_name && (
                          <option value={c.store_id} key={c.store_id}>
                            {c.store_name}
                          </option>
                        )
                      );
                    })}
                </select>
              </div>
            )}
            <div style={{ width: '181px', margin: 'auto', marginBottom: '10px' }}>
              <select
                name="sort"
                className="browser-default custom-select"
                style={{ width: '181px', margin: 'auto' }}
                onChange={e => filterProducts(e)}
              >
                <option value="id">
                  {translate('sort_by')} : {translate('date')}
                </option>
                <option value="name">
                  {translate('sort_by')} : {translate('name')}
                </option>
                <option value="price">
                  {translate('sort_by')} : {translate('price')}
                </option>
              </select>
            </div>

            <div style={{ width: '181px', margin: 'auto', marginBottom: '10px' }}>
              <select
                name="language"
                className="browser-default custom-select"
                style={{ width: '181px', margin: 'auto' }}
                onChange={e => filterProducts(e)}
              >
                <option value="en">English</option>
                <option value="ru">Russian</option>
                <option value="he">Hebrew</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Catalog;
