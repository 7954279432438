import { FetchUsersPending, FetchUsersSuccess, FetchUsersError, SetUsersPage } from './user.actionCreator';
import UserService from '../../services/User';

// Fetch Orders

export const FetchUsers = searchText => {
  return async dispatch => {
    try {
      dispatch(FetchUsersPending());

      const Orders = await UserService.fetchUsers(searchText);
      dispatch(FetchUsersSuccess(Orders));
    } catch (err) {
      dispatch(FetchUsersError(err));
    }
  };
};

// Set Page
export const SetPage = page => {
  return async dispatch => {
    try {
      dispatch(SetUsersPage(page));
    } catch (err) {
      console.error(err);
    }
  };
};
