import AppAPI from '../appUtility/Api';

class ProductsService {
  static fetchFilteredProducts = filter => {
    return AppAPI.post('/product/products', filter).then(res => {
      return res.data;
    });
  };

  static fetchSelectedProducts = filter => {
    return AppAPI.post('/product/merchantproducts', filter).then(res => {
      return res.data;
    });
  };

  static importProducts = filter => {
    return AppAPI.post('/product/merchantimportproducts', filter).then(res => {
      return res.data;
    });
  };

  static retrievedCountries = () => {
    return AppAPI.get('/shipping/retrievecountries').then(res => {
      return res.data;
    });
  };
}

export default ProductsService;
