import AppAPI from '../appUtility/Api';

class SignUpService {
  static storeMerchant = merchant => {
    return AppAPI.post('/merchant/register', merchant).then(res => {
      return res.data;
    });
  };
}

export default SignUpService;
