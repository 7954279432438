import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import Lock from '../../src/assets/images/loginlocklarge.png';
import CloseXbtn from '../components/commonElements/CloseXbtn';
import { hideMessage } from 'actions/Auth';
import EnviteIcon from '../components/commonElements/PaylixIcon';
import BG1 from '../components/Backgrounds/BG1';
import {
  selectAuthAlertMessage,
  selectAuthMessage,
  selectAuthPending,
  selectAuthUser,
  selectPasswordRequestRoute
} from '../store/auth/auth.selector';
import { CheckPasswordResetRoute, showAuthLoader, UpdatePassword } from '../store/auth/auth.actions';

class RecoverPass extends React.Component {
  state = {
    password: '',
    confirmPassword: '',
    passwordError: false
  };

  componentDidUpdate() {
    if (this.props.showMessage || this.state.passwordError) {
      // hide email validation error after 3 seconds
      setTimeout(() => {
        this.props.hideMessage();
        this.setState({ passwordError: false });
      }, 3000);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
    if (!this.props.valid) {
      this.props.history.push('/signin');
    }
  }

  componentDidMount() {
    this.props.CheckPasswordResetRoute({ url: this.props.match.params.url });
  }

  placeholderStyle = {
    // placeholder styles for UI material, used in TextField component' attribute 'InputLabelProps
    fontSize: '13px',
    color: '#c4c4c4',
    height: '46px'
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        passwordError: true
      });
    } else {
      const data = {
        password: this.state.password,
        url: this.props.match.params.url
      };
      this.props.UpdatePassword(data);
    }
  };

  render() {
    const { password, confirmPassword, passwordError } = this.state;
    const { loader } = this.props;
    return (
      <>
        <BG1 />
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-forget-password">
              <CloseXbtn to="/signin" />

              <div className="lock-image__wrapper" style={{ width: '100px' }}>
                <img src={Lock} alt="lock" className="forgot-password__lock" style={{ width: '100px' }} />
              </div>

              <h1>Reset Your Password</h1>

              {passwordError ? (
                <p className="email-error__message">Please enter valid passwords</p>
              ) : (
                <p>Enter New Password.</p>
              )}

              <TextField
                className="outlined-dense"
                label="New Password"
                InputLabelProps={{
                  style: this.placeholderStyle
                }}
                fullWidth
                type="password"
                onChange={event => this.setState({ password: event.target.value })}
                defaultValue={password}
                margin="dense"
                variant="outlined"
              />
              <TextField
                className="outlined-dense"
                label="Confirm Password"
                InputLabelProps={{
                  style: this.placeholderStyle
                }}
                type="password"
                fullWidth
                onChange={event => this.setState({ confirmPassword: event.target.value })}
                defaultValue={confirmPassword}
                margin="dense"
                variant="outlined"
              />

              <div className="mb-3 d-flex align-items-center justify-content-between">
                <Button className="login-button" variant="contained" color="primary" onClick={this.onSubmit}>
                  <span className="reset-password__text">
                    <IntlMessages id="appModule.resetPassword" />
                  </span>
                </Button>
              </div>

              <EnviteIcon color="white" />
            </div>
          </div>

          {loader && (
            <div className="loader-view">
              <CircularProgress />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: selectAuthUser(state),
    loader: selectAuthPending(state),
    showMessage: selectAuthMessage(state),
    alertMessage: selectAuthAlertMessage(state),
    valid: selectPasswordRequestRoute(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMessage: () => dispatch(hideMessage()),
    showAuthLoader: () => dispatch(showAuthLoader()),
    CheckPasswordResetRoute: email => dispatch(CheckPasswordResetRoute(email)),
    UpdatePassword: data => dispatch(UpdatePassword(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RecoverPass);
