import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import keycode from 'keycode';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import axios from '../../../appUtility/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import { showAuthLoader, hideAuthLoader, showAuthMessage, hideMessage, userSignOutSuccess } from 'actions/Auth';
import translate from '../../../appUtility/Translate';
import { DeleteIconSvg } from '../../../assets/svg/SvgIcons';
import SweetAlert from 'react-bootstrap-sweetalert';
import Header from 'components/Header';
import Loader from '../../../appUtility/Loader';
import { Modal } from 'reactstrap';
import CardBox from 'components/CardBox/index';

class Catalog extends Component {
  state = {
    data: {
      keyword: ''
    }
  };

  handleChange = e => {
    const data = { ...this.state.data };
    data[e.target.id] = e.target.value;
    this.setState({ data });
  };

  render() {
    return (
      <div
        className="translations-container-resp"
        style={{ width: '200px', height: '100%', backgroundColor: '#f0f0f6' }}
      >
        <div className="manage-catalog">Translations Management</div>
        <div className="add-new-product">
          <Link to="/app/translations/wizard">
            <button className="add-new-product-button">Add New Keyword</button>
          </Link>
        </div>
        <div className="product-filters">
          <h1>Filters</h1>
          <div className="filters-container">
            <div style={{ width: '181px', margin: 'auto', marginBottom: '10px' }} className="filter-en">
              <select
                id="lang"
                className="browser-default custom-select filters"
                onChange={e => this.props.filterData(e, this.state.data.keyword)}
              >
                <option value="en" defaultValue>
                  {translate('english')}
                </option>
                <option value="ru"> {translate('russian')}</option>
                <option value="he"> {translate('hebrew')}</option>
              </select>
            </div>
            <div style={{ width: '181px', margin: 'auto', marginBottom: '10px' }} className="filter-source">
              <select
                id="source"
                className="browser-default custom-select filters"
                onChange={e => this.props.filterData(e, this.state.data.keyword)}
              >
                <option value="">{translate('source')}</option>
                <option value="app">{translate('application')}</option>
                <option value="admin">{translate('admin')}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class DataTable extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };
  handleChange = event => {
    let platform = { ...this.state.platform };
    let attr = event.target.id;
    let value = event.target.value;
    platform[attr] = value;
    this.setState({ platform });
  };
  filterData = () => {
    this.props.showAuthLoader();
    const filters = {};
    Array.from(document.querySelectorAll('.filters')).forEach(el => {
      if (el.value !== '') {
        filters[el.id] = el.value;
      }
    });
    if (this.state.searchText) {
      filters['keyword'] = this.state.searchText;
    }

    fetch(axios.defaults.baseURL + '/app/translate/filter', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ filters: filters })
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        this.props.hideAuthLoader();
        const translations = response.data;
        this.setState({ data: translations, lang: filters['lang'] });
      });
  };
  getTranslations = lang => {
    fetch(axios.defaults.baseURL + '/app/translate/gettranslations', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ lang: lang || 'en' })
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        this.props.hideAuthLoader();
        const translations = response.data;
        this.setState({ data: translations, lang: lang || 'en' });
      });
  };
  saveTranslation = (key, word, lang) => {
    fetch(axios.defaults.baseURL + '/app/translate/savelanguages', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ key, word, lang })
    });
  };
  handleChangePage = (event, page) => {
    this.props.SetPage(page);
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;
  handleWordChange = (e, n) => {
    const data = { ...this.state.data };
    data[n] = e.target.value;
    this.setState({ data });
  };
  handleWordClick = e => {
    e.target.nextSibling.style.display = 'inline-block';
    e.target.nextSibling.nextSibling.style.display = 'inline-block';
  };
  onBlur = e => {
    e.target.nextSibling.style.display = 'none';
    e.target.nextSibling.nextSibling.style.display = 'none';
  };
  constructor(props, context) {
    super(props, context);

    this.state = {
      selected: [],
      lang: '',
      order: 'asc',
      orderBy: 'id',
      data: [],
      page: 0,
      rowsPerPage: 50,
      html: '',
      alert: null,
      searchText: '',
      single: null,
      modal: false
    };
    this._isMounted = false;
  }
  componentDidMount() {
    this._isMounted = true;
    this._isMounted && this.getTranslations();
    this.props.showAuthLoader();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  removeTranslate = key => {
    let getAlert = () => {
      return (
        <SweetAlert
          warning
          show
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure that you want to delete?"
          onConfirm={() => this.removeKey(key)}
          onCancel={this.hideAlert}
        ></SweetAlert>
      );
    };

    this.setState({
      alert: getAlert()
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  removeKey = key => {
    this.setState({
      alert: null
    });

    fetch(axios.defaults.baseURL + '/app/translate/deletetranslation', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ key })
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.success) {
          window.location.reload();
        }
      });
  };

  getSearchBarValue = e => {
    this.setState({
      searchText: e
    });
  };

  submitSearchBox = () => {
    this.setState({ page: 0 });
    this.filterData();
    this.props.SetPage(0);
  };

  filterValues = e => {
    this.setState({
      e: e.value
    });
  };

  enableModal = n => {
    this.setState({
      modal: true,
      single: n
    });
  };

  handleChange = e => {
    const { single } = this.state;

    single &&
      single.filter(i => {
        if (i.code == e.target.name) {
          i.text = e.target.value;
        }
      });

    this.setState({
      single
    });
  };

  changeSource = e => {
    const { single } = this.state;

    single && single.forEach(i => (i['source'] = e.target.value));

    this.setState({
      single
    });
  };

  updateTranslation = () => {
    const { single } = this.state;

    fetch(axios.defaults.baseURL + '/app/translate/updatelanguage', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ translation: single })
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        if (response.success) {
          window.location.reload();
        }
      });
  };

  render() {
    const { handleChange, changeSource } = this;
    const { data, rowsPerPage, alert, lang, single, modal } = this.state;
    const { loader, page } = this.props;

    return (
      <>
        <div className="translations-container">
          <Header
            merchants="translations"
            getSearchBarValue={this.getSearchBarValue}
            submitSearchBox={this.submitSearchBox}
            search={true}
          />
          {alert}
          <div className="flex translations-resp" style={{ display: 'flex', width: '100%', height: '100%' }}>
            <Catalog filterData={this.filterData} />
            <Loader loading={loader}>
              <div className="flex-auto" style={{ width: '85%' }}>
                {this.state.alert}
                <Modal
                  isOpen={modal}
                  toggle={() => {
                    this.setState({
                      modal: !this.state.modal
                    });
                  }}
                >
                  {single && (
                    <CardBox
                      styleName="col-12"
                      cardStyle="p-0"
                      style={{ borderRadius: '4px', border: '1px solid rgb(222,220,236)' }}
                      headerOutside
                    >
                      <div>
                        <div className="modal-input p-3">
                          <label htmlFor="key">{translate('key')}</label>
                          <input
                            id="key"
                            className="form-control mt-2"
                            type="text"
                            name="key"
                            autoComplete="off"
                            value={(single[0] && single[0].name) || ''}
                            disabled
                            onChange={handleChange}
                          />
                        </div>
                        {single &&
                          single.map(m => {
                            return (
                              <div className="modal-input p-3">
                                <label htmlFor={m.code}>{translate(m.code)}</label>
                                <input
                                  id={m.code}
                                  type="text"
                                  className="form-control mt-2"
                                  name={m.code}
                                  autoComplete="off"
                                  value={m.text}
                                  onChange={handleChange}
                                />
                              </div>
                            );
                          })}

                        <div className="modal-input p-3">
                          <label htmlFor="source">{translate('source')}</label>
                          <select
                            id="source"
                            name="source"
                            className="form-control mt-2"
                            defaultValue={single && single[0] && single[0].source}
                            onChange={changeSource}
                          >
                            <option value="app">{translate('app')}</option>
                            <option value="admin">{translate('admin')}</option>
                          </select>
                        </div>
                        <button className="btn btn-success ml-3" onClick={this.updateTranslation}>
                          Save
                        </button>
                      </div>
                    </CardBox>
                  )}
                </Modal>
                <div className="table-responsive-material m-4">
                  <Table className="clubproduct_table">
                    <TableHead>
                      <TableRow>
                        <TableCell>{translate('key')}</TableCell>
                        <TableCell>{translate('word')}</TableCell>
                        <TableCell>{translate('Remove')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        Object.keys(data)
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((n, i) => {
                            const isSelected = this.isSelected(n.id);
                            return (
                              <TableRow
                                hover
                                onKeyDown={event => this.handleKeyDown(event, n.id)}
                                role="checkbox"
                                aria-checked={isSelected}
                                tabIndex={-1}
                                key={i}
                                selected={isSelected}
                              >
                                <TableCell>{n}</TableCell>
                                <TableCell
                                  style={{ width: '50%', maxWidth: '50%' }}
                                  onClick={() => this.enableModal(data[n])}
                                >
                                  {/* <ContentEditable
                                    html={data[n]}
                                    disabled={false}
                                    spellCheck={false}
                                    onFocus={this.handleWordClick}
                                    onBlur={this.onBlur}
                                    className="editable"
                                    onChange={e => this.handleWordChange(e, n)}
                                  /> */}
                                  {data[n] &&
                                    data[n].map(e => {
                                      return e.code && e.code == lang && e.text;
                                    })}
                                  <button
                                    className="save"
                                    onMouseDown={e => {
                                      this.saveTranslation(n, data[n], this.state.lang);
                                      return false;
                                    }}
                                    style={{
                                      outline: 'none',
                                      border: 'none',
                                      background: 'none',
                                      color: 'blue',
                                      display: 'none'
                                    }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="cancel"
                                    onClick={e => {
                                      e.target.style.display = 'none';
                                      e.target.previousSibling.style.display = 'none';
                                    }}
                                    style={{
                                      outline: 'none',
                                      border: 'none',
                                      background: 'none',
                                      color: 'blue',
                                      display: 'none'
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </TableCell>
                                <TableCell onClick={() => this.removeTranslate(n)}>
                                  {' '}
                                  <i
                                    className="fas fa-trash-alt"
                                    style={{ fontSize: '16px', color: 'rgba(0,0,0,0.54)' }}
                                  ></i>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        {data && (
                          <TablePagination
                            count={data && Object.keys(data).length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        )}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              </div>
            </Loader>
          </div>
          {this.props.showMessage && NotificationManager.success(this.props.alertMessage)}
          <NotificationContainer />
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, showMessage, alertMessage, role, store } = auth;
  return { loader, showMessage, alertMessage, role, store };
};

export default withRouter(
  connect(mapStateToProps, {
    showAuthLoader,
    hideAuthLoader,
    showAuthMessage,
    hideMessage,
    userSignOutSuccess
  })(DataTable)
);
