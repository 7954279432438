import { FetchCategoriesPending, FetchCategoriesSuccess, FetchCategoriesError } from './category.actionCreator';
import CategoryService from '../../services/Category';

let counter = 0;

function createData(category_id, name, is_main, icon, parent, order, edit, remove) {
  counter += 1;
  return { id: counter, category_id, name, is_main, icon, parent, order, edit, remove };
}

// Fetch Categpries
export const FetchCategories = () => {
  return async dispatch => {
    try {
      dispatch(FetchCategoriesPending());

      const Categories = await CategoryService.fetchCategories();

      let formatedData = [];
      Categories.category.forEach(el => {
        formatedData.push(
          createData(el.id, el.name[localStorage.getItem('lang') || 'en'], el.is_main, el.icon, el.parent, el.order)
        );
        if (el.children[0]) {
          if (el.children[0].parent) {
            el.children.forEach(ch => {
              formatedData.push(
                createData(
                  ch.id,
                  ch.name[localStorage.getItem('lang') || 'en'],
                  ch.is_main,
                  ch.icon,
                  ch.parent,
                  ch.order
                )
              );
            });
          }
        }
      });

      dispatch(FetchCategoriesSuccess(formatedData));

      if (Categories.data.error) {
        dispatch(FetchCategoriesError(Categories.data.error));
      }
    } catch (err) {
      dispatch(FetchCategoriesError(err));
    }
  };
};
