import {
  FetchOrdersPending,
  FetchOrdersSuccess,
  FetchOrdersError,
  SetOrdersPage,
  FetchCountries
} from './order.actionCreator';
import OrderService from '../../services/Order';

// Fetch Orders

export const FetchOrders = (filter, page, rowsPerPage) => {
  return async dispatch => {
    try {
      dispatch(FetchOrdersPending());

      const Orders = await OrderService.fetchOrdersService(filter, page, rowsPerPage);
      dispatch(FetchOrdersSuccess(Orders));
    } catch (err) {
      dispatch(FetchOrdersError(err));
    }
  };
};

// Fetch Countries
export const FetchCountriesAction = () => {
  return async dispatch => {
    try {
      const Countries = await OrderService.fetchCountries();
      dispatch(FetchCountries(Countries));
    } catch (err) {
      console.error(err);
    }
  };
};

// Set Page
export const SetPage = page => {
  return async dispatch => {
    try {
      dispatch(SetOrdersPage(page));
    } catch (err) {
      console.error(err);
    }
  };
};
