import React from 'react';
import { Link } from 'react-router-dom';
import translate from '../../../appUtility/Translate';

export default function Catalog() {
  return (
    <div className="users-resp" style={{ width: '200px', height: '100%', backgroundColor: '#f0f0f6' }}>
      <div className="manage-catalog">{translate('users')}</div>
      <div className="add-new-product">
        <Link to="/app/users/wizard">
          <button className="add-new-product-button">{translate('add_new_user')}</button>
        </Link>
      </div>
      {/*<div className="product-filters">
           <h1>Filters</h1>
                <div className="filters-container">
                    <div style={{width:'181px', margin:'auto', marginBottom:'10px'}}>
                        <select id="currency" className="browser-default custom-select" >
                            <option value="">Filter 01</option>
                        </select>
                    </div>
                    <div style={{width:'181px', margin:'auto', marginBottom:'10px'}}>
                        <select id="currency" className="browser-default custom-select" style={{width:'181px', margin:'auto'}}>
                            <option value="">Filter 02</option>
                        </select>
                    </div>
                    <div style={{width:'181px', margin:'auto', marginBottom:'10px'}}>
                        <select id="currency" className="browser-default custom-select" style={{width:'181px', margin:'auto'}}>
                            <option value="">Sort by: Name</option>
                        </select>
                    </div>
                </div>
            </div>*/}
    </div>
  );
}
