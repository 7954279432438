import {
  FetchTranslationsPending,
  FetchTranslationsSuccess,
  FetchTranslationsError,
  SetTranslationsPage
} from './translation.actionCreator';
import TranslationService from '../../services/Translation';

// Fetch Translations
export const FetchTranslations = searchText => {
  return async dispatch => {
    try {
      dispatch(FetchTranslationsPending());

      const Merchant = await TranslationService.fetchTranslations(searchText);

      dispatch(FetchTranslationsSuccess(Merchant));
    } catch (err) {
      dispatch(FetchTranslationsError(err));
    }
  };
};

// Set Page
export const SetPage = page => {
  return async dispatch => {
    try {
      dispatch(SetTranslationsPage(page));
    } catch (err) {
      console.error(err);
    }
  };
};
