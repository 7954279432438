// Fetching Merchants
export const selectCustomers = state => {
  return state.CustomerReducer.Customers;
};
// Pending
export const selectCustomersPending = state => {
  return state.CustomerReducer.pending;
};
// Error
export const selecCustomersError = state => {
  return state.CustomerReducer.error;
};

// Page
export const selecCustomersPage = state => {
  return state.CustomerReducer.page;
};
