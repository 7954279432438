// Fetching Merchants
export const selectMerchants = state => {
  return state.MerchantReducer.Merchants;
};
// Pending
export const selectMerchantsPending = state => {
  return state.MerchantReducer.pending;
};
// Error
export const selecMerchantsError = state => {
  return state.MerchantReducer.error;
};
// Page
export const selectMerchantsTablePage = state => {
  return state.MerchantReducer.page;
};
