const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'Russian',
    locale: 'ru',
    name: 'Russian',
    icon: 'ru'
  },
  {
    languageId: 'Hebrew',
    locale: 'he',
    name: 'Hebrew',
    icon: 'he'
  }
];
export default languageData;
