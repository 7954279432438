import React from 'react';
import { withRouter } from 'react-router-dom';
import keycode from 'keycode';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from '../../../../appUtility/Api';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { showAuthLoader, hideAuthLoader } from 'actions/Auth';
import { PlusIconSvg } from '../../../../assets/svg/SvgIcons';
import { ParentItem, ChildItem } from './Card/Item';
import translate from '../../../../appUtility/Translate';

let counter = 0;

function createData(category_id, name, is_main, icon, parent, order, edit, remove) {
  counter += 1;
  return { id: counter, category_id, name, is_main, icon, parent, order, edit, remove };
}

class DataTable extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id, category) => {
    if (event.target.closest('div').className === 'actions') {
      return;
    }
    this.props.history.push('/app/club/category/wizard/' + category);
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
      alert: null,
      newcat: '',
      newChild: '',

      parents: [],
      children: [],
      active: 0,
      activeChild: 0,
      anchorEl: null,
      open: false,
      activeBoth: 0
    };
    this._isMounted = false;
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  handleClickDelete = (event, id, child) => {
    if (child) {
      this.setState({ open: true, anchorEl: event.currentTarget, activeChild: id, delete: id });
    } else {
      this.setState({ open: true, anchorEl: event.currentTarget, active: id, delete: id });
    }
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  getCategory = () => {
    fetch(axios.defaults.baseURL + '/app/club/admincategory', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        let formatedData = [];
        data.category.forEach(el => {
          formatedData.push(
            createData(el.id, el.name[localStorage.getItem('lang') || 'en'], el.is_main, el.icon, el.parent, el.order)
          );
          if (el.children[0]) {
            if (el.children[0].parent) {
              el.children.forEach(ch => {
                formatedData.push(
                  createData(
                    ch.id,
                    ch.name[localStorage.getItem('lang') || 'en'],
                    ch.is_main,
                    ch.icon,
                    ch.parent,
                    ch.order
                  )
                );
              });
            }
          }
        });

        var parents = formatedData.filter(c => {
          return c.parent === 0;
        });

        var children = formatedData.filter(c => {
          return c.parent === parents[0].category_id;
        });

        this.props.hideAuthLoader();
        this._isMounted && this.setState({ data: formatedData, parents, children, active: parents[0].category_id });
      });
  };

  // Remove Category
  deleteThisGoal = item => {
    const getAlert = () => (
      <SweetAlert
        warning
        showCancel
        confirmBtnText="Yes, delete it!"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Are you sure that you want to delete?"
        onConfirm={() => this.removeCategory(item)}
        onCancel={this.hideAlert}
      ></SweetAlert>
    );

    this.setState({
      alert: getAlert()
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  removeCategory = id => {
    this.props.showAuthLoader();
    this.setState({
      alert: null
    });
    fetch(axios.defaults.baseURL + '/app/club/deletecategory', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ id })
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.success) {
          this._isMounted && this.getCategory();
          this.setState({
            active: 0,
            activeChild: 0,
            anchorEl: null,
            open: false
          });
        }
      });
  };

  addCategory = parent => {
    var newcat = this.state.newcat;
    var newChild = this.state.newChild;
    var data = {
      name: {
        en: ''
      },
      parent: 0,
      is_main: 0,
      icon: '',
      pictures: ''
    };

    if (parent) {
      data.parent = parent;
      data.name['en'] = newChild;
    } else {
      data.name['en'] = newcat;
    }

    fetch(axios.defaults.baseURL + '/app/club/addcategory', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        res.json();
      })
      .then(() => {
        window.location.reload();
      });
  };

  onSubmit = (event, parent) => {
    event.preventDefault();

    if (parent) {
      if (this.state.newChild.length > 0) {
        this.addCategory(parent);
      }
    } else {
      if (this.state.newcat.length > 0) {
        this.addCategory();
      }
    }

    this.setState({
      newChild: '',
      newcat: ''
    });
  };

  componentDidMount() {
    this._isMounted = true;
    this.props.showAuthLoader();
    this._isMounted && this.getCategory();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // Active Item
  handleActiveItem = item => {
    this.setState({ active: item });

    var children = this.state.data.filter(c => {
      return c.parent === item;
    });

    this.setState({
      children
    });
  };

  // Active Child
  handleActiveChild = item => {
    this.setState({
      activeChild: item
    });
  };

  // After Parents Drop
  changeStateAfterOrder = items => {
    this.setState({
      parents: items
    });
    this.handleParentsOrder(items);
  };

  // After Children Drop
  changeChildState = items => {
    this.setState({
      children: items
    });
    this.handleChildrenOrder(items);
  };

  // Actions Modal
  toggleActionsModal = (e, i) => {
    this.setState({ open: true, anchorEl: e.currentTarget, activeBoth: i });
  };

  // Close Modal
  handleRequestClose = () => {
    this.setState({ open: false });
  };

  // Reorder Parents
  handleParentsOrder = items => {
    const body = {
      data: items
    };
    fetch(axios.defaults.baseURL + '/app/club/ordercategory', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(body)
    })
      .then(res => {
        return res.json();
      })
      .then(data => {});
  };

  // Reorder Children
  handleChildrenOrder = items => {
    const body = {
      data: items
    };
    fetch(axios.defaults.baseURL + '/app/club/orderchildcategory', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(body)
    })
      .then(res => {
        return res.json();
      })
      .then(data => {});
  };

  render() {
    const { parents, children } = this.state;
    // console.log('%cState' , 'color: darkred; font-weight:bold;font-size:20px' , this.state);
    return (
      <>
        {this.state.alert}

        <div className="categories-table">
          <div className="categories-table__levels">
            {/*Level One*/}
            <div className="categories-table-levels__level level-one levelOne">
              <div className="level-header flex">
                <h3>{translate('parent_categories')}</h3>
              </div>
              <div className="level-addnew">
                <div className="level-addnew_flex">
                  <input
                    type="text"
                    placeholder="New Category"
                    className="level-addnew_input"
                    name="newcat"
                    onChange={this.handleInputChange}
                    value={this.state.newcat}
                  />
                  <div onClick={e => this.onSubmit(e)}>
                    <PlusIconSvg color={'#009ee3'} />
                  </div>
                </div>
              </div>

              <ParentItem
                items={parents}
                handleActiveItem={this.handleActiveItem}
                active={this.state.active}
                changeState={this.changeStateAfterOrder}
                toggleActionsModal={this.toggleActionsModal}
                open={this.state.open}
                anchorEl={this.state.anchorEl}
                handleRequestClose={this.handleRequestClose}
                deleteThisGoal={this.deleteThisGoal}
                activeBoth={this.state.activeBoth}
              />
            </div>

            {/*Level Two*/}
            <div className="categories-table-levels__level level-two">
              <div className="categories-table-levels__level level-one">
                <div className="level-header flex">
                  <h3>{translate('child_categories')}</h3>
                </div>
                <div className="level-addnew">
                  <div className="level-addnew_flex">
                    <input
                      type="text"
                      placeholder="New Category"
                      className="level-addnew_input"
                      name="newChild"
                      onChange={this.handleInputChange}
                      value={this.state.newChild}
                    />
                    <div onClick={e => this.onSubmit(e, this.state.active)}>
                      <PlusIconSvg color={'#009ee3'} />
                    </div>
                  </div>
                </div>
                <ChildItem
                  items={children}
                  handleActiveItem={this.handleActiveChild}
                  active={this.state.activeChild}
                  changeChildState={this.changeChildState}
                  toggleActionsModal={this.toggleActionsModal}
                  open={this.state.open}
                  anchorEl={this.state.anchorEl}
                  handleRequestClose={this.handleRequestClose}
                  deleteThisGoal={this.deleteThisGoal}
                  activeBoth={this.state.activeBoth}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader } = auth;
  return { loader };
};

export default withRouter(
  connect(mapStateToProps, {
    showAuthLoader,
    hideAuthLoader
  })(DataTable)
);
