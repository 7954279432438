import * as types from './translation.actionTypes';

const initState = {
  Translations: {},
  pending: false,
  error: null,
  page: 0
};

const TranslationReducer = (state = initState, action) => {
  switch (action.type) {
    // Fetching Translations
    case types.FETCH_TRANSLATIONS_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        Translations: action.payload.data
      };
    case types.FETCH_TRANSLATIONS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    // Set Page
    case types.SET_TRANSLATIONS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload
      };

    default:
      return state;
  }
};

export default TranslationReducer;
