import { connect } from 'react-redux';
import { StoreNewMerchant } from './signup.actions';
import { selectMerchant, selectMerchantPending, selectMerchantError, selectAuthUser } from './signup.selector';
import SignUp from '../../components/SignUp/SignUp';

const mapStateToProps = state => {
  return {
    Pending: selectMerchantPending(state),
    Merchant: selectMerchant(state),
    ErrorMessage: selectMerchantError(state),
    authUser: selectAuthUser(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    StoreNewMerchant: merchant => dispatch(StoreNewMerchant(merchant))
  };
};

const SignUpView = connect(mapStateToProps, mapDispatchToProps)(SignUp);

export default SignUpView;
