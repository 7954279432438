import React, { useState } from 'react';
import { Link } from 'react-router-dom';

export default function Product({
  name,
  old_price,
  price,
  discount,
  store_name,
  images,
  id,
  handleDeleteItems,
  checkedArray,
  isOwnProducts,
  currency,
  quickView,
  handleActiveItem,
  UserRole,
  imported
}) {
  const [checked, setChecked] = useState(false);

  const handleInputChange = id => {
    setChecked(!checked);
    handleDeleteItems(id);
  };
  if (checkedArray.length === 0 && checked) {
    setChecked(!checked);
  }

  const renderCurrency = cur => {
    switch (cur) {
      case 0:
        return '$';
      case 1:
        return '₴';
      case 2:
        return '₪';
      default:
        return '$';
    }
  };

  return (
    <div
      className={`col-12 col-sm-6 col-md-4 col-lg-2 product-box ${quickView && 'hovered-product-box'}`}
      style={{ paddingLeft: '8px', paddingRight: '7px' }}
    >
      {quickView && (
        <div className="over-product-box">
          <button onClick={() => handleActiveItem(id)}>More Details</button>
        </div>
      )}
      <div className="product-select-button" onClick={() => handleInputChange(id)}>
        <input type="checkbox" checked={checked && 'checked'} onChange={() => console.log('just for warning')} />
        <span className="checkmark"></span>
      </div>

      <Link
        to={UserRole !== 1 && imported ? '/' : `/app/club/product/wizard/${id}`}
        style={{ textDecoration: 'none' }}
        disabled={UserRole !== 1 && imported}
        className={UserRole !== 1 && imported ? 'disabled-link' : ''}
      >
        <div className={`club-product ${checked && ' greenBordered'}`}>
          <div className="product-image">
            <img src={images[0]} alt=""></img>
          </div>
          <div className="product-description">
            {window.location.hostname == 'homerun.webiz.ge' ?
              <div className="product-domain">HomeRun</div> :
              <div className="product-domain">Internity</div> 
              // <div className="product-domain">{store_name}</div> Must be REVERTED in the Future
            }

            <div className="product-name">{name}</div>
            <div className="product-price">
              <span className="product-old-price">
                {renderCurrency(currency)}
                {old_price}
              </span>
              <span className="product-new-price">
                {renderCurrency(currency)}
                {price}
              </span>
              <div className="product-discount">- {Math.round(discount) || 0}%</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
