import * as types from './user.actionTypes';

// Fetching Users

export function FetchUsersPending() {
  return {
    type: types.FETCH_USERS_PENDING
  };
}
export function FetchUsersSuccess(users) {
  return {
    type: types.FETCH_USERS_SUCCESS,
    payload: users
  };
}
export function FetchUsersError(error) {
  return {
    type: types.FETCH_USERS_ERROR,
    payload: error
  };
}

// Set Users Page
export function SetUsersPage(page) {
  return {
    type: types.SET_USERS_TABLE_PAGE,
    payload: page
  };
}
