import * as types from './category.actionTypes';

const initState = {
  Categories: {},
  pending: false,
  error: null
};

const categoryReducer = (state = initState, action) => {
  switch (action.type) {
    // Fetching Categories
    case types.FETCH_CATEGORIES_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.FETCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        pending: false,
        Categories: action.payload
      };
    case types.FETCH_CATEGORIES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export default categoryReducer;
