import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  MINI_DRAWER,
  HORIZONTAL_MENU_POSITION,
  INSIDE_THE_HEADER,
  SWITCH_LANGUAGE,
  TOGGLE_COLLAPSED_NAV,
  VERTICAL_NAVIGATION,
  WINDOW_WIDTH,
  MENU_TYPE,
  SECONDARY_MENU_CONTENT,
  TOP_MENU_STATUS,
  TOP_MENU_CONTENT
} from 'constants/ActionTypes';

const rltLocale = ['ar'];
const initialSettings = {
  navCollapsed: false,
  drawerType: MINI_DRAWER,
  menuType: null,
  width: window.innerWidth,
  isDirectionRTL: false,
  navigationStyle: VERTICAL_NAVIGATION,
  horizontalNavPosition: INSIDE_THE_HEADER,
  secondaryMenuContent: null,
  topMenuStatus: false,
  topMenuContent: null,
  locale: {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      return {
        ...state,
        navCollapsed: false
      };
    case TOGGLE_COLLAPSED_NAV:
      return {
        ...state,
        navCollapsed: action.isNavCollapsed
      };
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width
      };
    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        isDirectionRTL: rltLocale.includes(action.payload.locale)
      };
    case CHANGE_DIRECTION:
      return {
        ...state,
        isDirectionRTL: !state.isDirectionRTL
      };

    case CHANGE_NAVIGATION_STYLE:
      return {
        ...state,
        navigationStyle: action.payload
      };

    case HORIZONTAL_MENU_POSITION:
      return {
        ...state,
        horizontalNavPosition: action.payload
      };

    case MENU_TYPE: {
      const app_container = document.querySelector('.app-container');
      if (app_container) {
        app_container.classList.remove(state.menuType);
        app_container.classList.add(action.menuType);
      }
      return {
        ...state,
        menuType: action.menuType
      };
    }

    case SECONDARY_MENU_CONTENT: {
      return {
        ...state,
        secondaryMenuContent: action.content
      };
    }

    case TOP_MENU_STATUS: {
      return {
        ...state,
        topMenuStatus: action.status
      };
    }

    case TOP_MENU_CONTENT: {
      return {
        ...state,
        topMenuContent: action.content
      };
    }

    default:
      return state;
  }
};

export default settings;
