import React from 'react';
import { BurgerMenuIconSvg, DeleteIconSvg, DotsMenuIconSvg } from '../../../../../assets/svg/SvgIcons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Menu from '@material-ui/core/Menu';
import { IconButton } from '@material-ui/core';
import { Link } from 'react-router-dom';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  height: isDragging && '44px',

  // styles we need to apply on draggables
  ...draggableStyle
});

function Parent({
  parent,
  index,
  handleActiveItem,
  active,
  toggleActionsModal,
  anchorEl,
  open,
  handleRequestClose,
  deleteThisGoal,
  activeBoth
}) {
  return (
    <Draggable draggableId={String(parent.id)} index={index}>
      {provided => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`category-list__item ${parent.category_id === active && 'active'}`}
          style={getItemStyle(provided.isDragging, provided.draggableProps.style)}
        >
          <td className="flex" style={{ width: '100%' }}>
            <div className="list-item__toggle">
              <BurgerMenuIconSvg color={'#ffffff '} />
            </div>
            <div className="list-item__name" onClick={() => handleActiveItem(parent.category_id)}>
              {parent.name}
            </div>
            <div className="list-item__dots" onClick={event => toggleActionsModal(event, parent.category_id)}>
              <DotsMenuIconSvg color={'#ffffff'} />
            </div>
            <Menu className="user-info" id="simple-menu" anchorEl={anchorEl} open={open} onClose={handleRequestClose}>
              <Link to={'/app/club/category/wizard/' + activeBoth} className="category-edit_icon">
                <IconButton aria-label="Edit">
                  <i className="fas fa-pencil-alt" style={{ fontSize: '16px' }}></i>
                </IconButton>
              </Link>
              <IconButton aria-label="Delete" onClick={() => deleteThisGoal(activeBoth)}>
                <i className="fas fa-trash-alt" style={{ fontSize: '16px', color: 'rgba(0,0,0,0.54)' }}></i>
              </IconButton>
            </Menu>
          </td>
        </tr>
      )}
    </Draggable>
  );
}

const ParentList = React.memo(function QuoteList({
  parents,
  handleActiveItem,
  active,
  toggleActionsModal,
  anchorEl,
  open,
  handleRequestClose,
  deleteThisGoal,
  activeBoth
}) {
  return parents.map((parent, index) => (
    <Parent
      parent={parent}
      index={index}
      key={parent.id}
      handleActiveItem={handleActiveItem}
      toggleActionsModal={toggleActionsModal}
      active={active}
      anchorEl={anchorEl}
      open={open}
      handleRequestClose={handleRequestClose}
      deleteThisGoal={deleteThisGoal}
      activeBoth={activeBoth}
    />
  ));
});

export function ParentItem(props) {
  const {
    items,
    handleActiveItem,
    active,
    changeState,
    toggleActionsModal,
    anchorEl,
    open,
    handleRequestClose,
    deleteThisGoal,
    activeBoth
  } = props;

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    reorder(items, result.source.index, result.destination.index);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    changeState(result);
    return result;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <table className="category-list" ref={provided.innerRef} {...provided.droppableProps}>
            <tbody>
              <ParentList
                parents={items}
                handleActiveItem={handleActiveItem}
                active={active}
                toggleActionsModal={toggleActionsModal}
                anchorEl={anchorEl}
                open={open}
                handleRequestClose={handleRequestClose}
                deleteThisGoal={deleteThisGoal}
                activeBoth={activeBoth}
              />
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  );
}

// ============================================================

function Child({ child, index, handleActiveItem, active, toggleActionsModal, activeBoth }) {
  return (
    <Draggable draggableId={String(child.id)} index={index}>
      {provided => (
        <tr
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className={`category-list__item ${child.category_id === active && 'active'}`}
        >
          <td className="flex" style={{ width: '100%' }}>
            <div className="list-item__toggle">
              <BurgerMenuIconSvg color={'#ffffff '} />
            </div>
            <div className="list-item__name" onClick={() => handleActiveItem(child.category_id)}>
              {child.name}
            </div>
            <div className="list-item__dots" onClick={event => toggleActionsModal(event, child.category_id)}>
              <DotsMenuIconSvg color={'#ffffff'} />
            </div>
          </td>
        </tr>
      )}
    </Draggable>
  );
}

const ChildList = React.memo(function QuoteList({
  children,
  handleActiveItem,
  active,
  toggleActionsModal,
  activeBoth
}) {
  return (
    children &&
    children.map((child, index) => (
      <Child
        child={child}
        index={index}
        key={child.id}
        handleActiveItem={handleActiveItem}
        toggleActionsModal={toggleActionsModal}
        active={active}
        activeBoth={activeBoth}
      />
    ))
  );
});

export function ChildItem(props) {
  const { items, handleActiveItem, active, changeChildState, toggleActionsModal, activeBoth } = props;

  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    reorder(items, result.source.index, result.destination.index);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    changeChildState(result);
    return result;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="list">
        {provided => (
          <table className="category-list" ref={provided.innerRef} {...provided.droppableProps}>
            <tbody>
              <ChildList
                children={items}
                handleActiveItem={handleActiveItem}
                active={active}
                toggleActionsModal={toggleActionsModal}
                activeBoth={activeBoth}
              />
            </tbody>
          </table>
        )}
      </Droppable>
    </DragDropContext>
  );
}
