import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import translate from '../../../../appUtility/Translate';
import General from './General';

export default function Tabs({ activeTab, handleChange, handleClick, tabs }) {
  return (
    <div className="tab-wrapper">
      <div className="tab-header">{translate('add_new_product')}</div>
      <div className="tab-container">
        <div
          id="general"
          className={
            'tab-item' +
            (tabs.includes('general') ? ' tab-item--error' : activeTab === 'general' ? ' tab-item--active' : '')
          }
          onClick={() => handleChange('general')}
        >
          <span>{translate('general')}</span>
        </div>
        <div
          id="description"
          className={
            'tab-item' +
            (tabs.includes('description') ? ' tab-item--error' : activeTab === 'description' ? ' tab-item--active' : '')
          }
          onClick={() => handleChange('description')}
        >
          <span>{translate('description')}</span>
        </div>
        <div
          id="price"
          className={
            'tab-item' +
            (tabs.includes('price') ? ' tab-item--error' : activeTab === 'price' ? ' tab-item--active' : '')
          }
          onClick={() => handleChange('price')}
        >
          <span>{translate('price')}</span>
        </div>
        <div
          id="images"
          className={
            'tab-item' +
            (tabs.includes('images') ? ' tab-item--error' : activeTab === 'images' ? ' tab-item--active' : '')
          }
          onClick={() => handleChange('images')}
        >
          <span>images</span>
        </div>
        <div
          id="attribute"
          className={
            'tab-item' +
            (tabs.includes('attribute') ? ' tab-item--error' : activeTab === 'attribute' ? ' tab-item--active' : '')
          }
          onClick={() => handleChange('attribute')}
        >
          <span>{translate('Attribute')}</span>
        </div>
        <div
          id="options"
          className={
            'tab-item' +
            (tabs.includes('options') ? ' tab-item--error' : activeTab === 'options' ? ' tab-item--active' : '')
          }
          onClick={() => handleChange('options')}
        >
          <span>{translate('options')}</span>
        </div>
        <div className="tab-buttons">
          <button onClick={handleClick}>Save</button>
          <NavLink className="prepend-icon" to="/app/club/catalog">
            <button>{translate('cancel')}</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}
