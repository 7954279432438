import { connect } from 'react-redux';
import { FetchMerchants, SetPage } from './merchant.actions';
import {
  selectMerchants,
  selectMerchantsPending,
  selecMerchantsError,
  selectMerchantsTablePage
} from './merchant.selector';
import Merchant from '../../app/routes/Merchants/index';

const mapStateToProps = state => {
  return {
    Pending: selectMerchantsPending(state),
    Merchants: selectMerchants(state),
    ErrorMessage: selecMerchantsError(state),
    page: selectMerchantsTablePage(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchMerchants: searchText => dispatch(FetchMerchants(searchText)),
    SetPage: page => dispatch(SetPage(page))
  };
};

const MerchantView = connect(mapStateToProps, mapDispatchToProps)(Merchant);

export default MerchantView;
