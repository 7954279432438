import React, { useState, useEffect } from 'react';
import Header from 'components/Header';
import DataTable from './CustomerTable';
import Catalog from '../Catalog';

const userTable = props => {
  const [searchText, setsearchText] = useState(null);
  const [file, setFile] = useState(null);

  const handleFileChange = event => {
    console.log(event.target.files[0]);
    setFile(event.target.files[0]);
  };

  const uploadFile = async () => {
    const companyId = Number(JSON.parse(localStorage.getItem('verificationData')).company.id);
    await props.UploadCustomersFile({ file, companyId });
    setFile(null);
  };

  const getSearchBarValue = e => {
    setsearchText(e);
  };

  const submitSearchBox = () => {
    const companyId = Number(JSON.parse(localStorage.getItem('verificationData')).company.id);
    props.FetchCustomers({ searchText: searchText, companyId });
    props.SetPage(0);
  };

  const handleBlock = item => {
    props.BlockCustomer(item.id);
  };

  useEffect(() => {
    const companyId = Number(JSON.parse(localStorage.getItem('verificationData')).company.id);
    props.FetchCustomers({ searchText: '', companyId });
  }, []);

  const companyId = Number(JSON.parse(localStorage.getItem('verificationData')).company.id);

  return (
    <div className="users-wrapper">
      {companyId !== 1 && (
        <Catalog
          onChangeFile={handleFileChange}
          onUploadFile={uploadFile}
          resetFile={() => setFile(null)}
          file={file}
        />
      )}
      <div className="customers-container">
        <Header
          merchants="customers"
          search={true}
          getSearchBarValue={getSearchBarValue}
          submitSearchBox={submitSearchBox}
        />

        <DataTable
          data={props.Customers}
          DeleteCustomer={props.DeleteCustomer}
          FetchCustomers={props.FetchCustomers}
          onBlock={handleBlock}
          pending={props.Pending}
          page={props.page}
          setPage={props.SetPage}
          hasBlock={companyId !== 1}
        />
      </div>
    </div>
  );
};

export default userTable;
