import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';

export default function DateRangePeriods(props) {
  const { setDateRangeCalendar, changedDataRange } = props;
  const [currentSelect, setCurrentSelect] = useState('');

  useEffect(() => {
    changedDataRange && setCurrentSelect('');
  }, [changedDataRange]);

  const onRangePeriodSelect = e => {
    setCurrentSelect(e.target.textContent);

    switch (e.target.textContent) {
      case 'Lifetime':
        setDateRangeCalendar(
          moment()
            .startOf('year')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        );
        break;
      case 'Today':
        setDateRangeCalendar(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'));
        break;

      // case 'Yesterday':
      //   setDateRangeCalendar(
      //     moment()
      //       .subtract(1, 'days')
      //       .format('YYYY-MM-DD'),
      //     moment()
      //       .subtract(1, 'days')
      //       .format('YYYY-MM-DD')
      //   );
      //   break;
      case 'Yesterday':
        setDateRangeCalendar(
          moment()
            .subtract(1, 'days')
            .format('YYYY-MM-DD')
          // moment().format('YYYY-MM-DD')
        );
        break;

      case 'Last 7 days':
        setDateRangeCalendar(
          moment()
            .subtract(1, 'weeks')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        );
        break;
      case 'Last 14 days':
        setDateRangeCalendar(
          moment()
            .subtract(2, 'weeks')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        );
        break;
      case 'Last 30 days':
        setDateRangeCalendar(
          moment()
            .subtract(1, 'months')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        );
        break;
      case 'This week':
        setDateRangeCalendar(
          moment()
            .startOf('isoWeek')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        );
        break;
      case 'Last week':
        setDateRangeCalendar(
          moment()
            .subtract(1, 'weeks')
            .startOf('isoWeek')
            .format('YYYY-MM-DD'),
          moment()
            .subtract(1, 'weeks')
            .endOf('isoWeek')
            .format('YYYY-MM-DD')
        );
        break;
      case 'This month':
        setDateRangeCalendar(
          moment()
            .startOf('month')
            .format('YYYY-MM-DD'),
          moment().format('YYYY-MM-DD')
        );
        break;
      case 'Last month':
        setDateRangeCalendar(
          moment()
            .subtract(1, 'months')
            .date(1)
            .format('YYYY-MM-DD'),
          moment()
            .subtract(1, 'months')
            .endOf('month')
            .format('YYYY-MM-DD')
        );
        break;
      default:
      // code block
    }
  };

  return (
    <>
      <h4>Date Presets</h4>
      <p className={currentSelect === 'Lifetime' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        Lifetime
      </p>
      <p className={currentSelect === 'Today' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        Today
      </p>
      <p className={currentSelect === 'Yesterday' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        Yesterday
      </p>
      <p className={currentSelect === 'Last 7 days' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        Last 7 days
      </p>
      <p className={currentSelect === 'Last 14 days' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        Last 14 days
      </p>
      <p className={currentSelect === 'Last 30 days' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        Last 30 days
      </p>
      <p className={currentSelect === 'This week' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        This week
      </p>
      <p className={currentSelect === 'Last wee' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        Last week
      </p>
      <p className={currentSelect === 'This month' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        This month
      </p>
      <p className={currentSelect === 'Last month' ? 'active-selected__period' : ''} onClick={onRangePeriodSelect}>
        Last month
      </p>
    </>
  );
}
