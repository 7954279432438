import * as types from './customer.actionTypes';

const initState = {
  Customers: {},
  pending: false,
  error: null,
  page: 0
};

const CustomerReducer = (state = initState, action) => {
  switch (action.type) {
    // Fetching Customers
    case types.FETCH_CUSTOMERS_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.FETCH_CUSTOMERS_SUCCESS:
      return {
        ...state,
        pending: false,
        Customers: action.payload.users
      };
    case types.FETCH_CUSTOMERS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    // Delete Customer
    case types.DELETE_CUSTOMERS_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.DELETE_CUSTOMERS_SUCCESS:
      const data = state.Customers;

      let index = data.findIndex(i => i.id === action.payload.id);

      data.splice(index, 1);

      return {
        ...state,
        pending: false,
        Customers: data
      };
    case types.DELETE_CUSTOMERS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    // case types.BLOCK_CUSTOMER_PENDING:
    //   return {
    //     ...state,
    //     pending: true
    //   };
    case types.BLOCK_CUSTOMER_SUCCESS:
      const oldData = state.Customers;
      let newData = oldData.map(i => {
        return i.id === action.payload.id ? { ...i, blocked: i.blocked == 0 ? '1' : '0' } : i;
      });
      console.log('sdfdsfs', action);
      return {
        ...state,
        pending: false,
        Customers: newData
      };
    case types.BLOCK_CUSTOMER_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    // Set Page
    case types.SET_CUSTOMERS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload
      };
    default:
      return state;
  }
};

export default CustomerReducer;
