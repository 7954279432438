import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IntlMessages from 'util/IntlMessages';
import { LogOutMerchant } from '../../store/auth/auth.actions';

class UserInfo extends React.Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        <div onClick={this.handleClick} className="d-flex justify-content-center align-items-center">
          <Avatar alt="..." src={'https://via.placeholder.com/150x150'} className="user-avatar size-35" />
          <div className="user-detail d-none d-sm-flex">
            <h4 className="user-name">
              {JSON.parse(localStorage.getItem('admin_name'))}
              <i className="material-icons">keyboard_arrow_down</i>
            </h4>
          </div>
        </div>
        <Menu
          className="user-info"
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0
            }
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.history.push(
                `/app/merchants/wizard/${JSON.parse(localStorage.getItem('verificationData')).user}`
              );
              this.handleRequestClose();
            }}
          >
            <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
            <IntlMessages id="popup.profile" />
          </MenuItem>
          {/*<MenuItem*/}
          {/*  onClick={() => {*/}
          {/*    this.props.history.push(*/}
          {/*      `/app/merchants/wizard/${JSON.parse(localStorage.getItem('verificationData')).user}`*/}
          {/*    );*/}
          {/*    this.handleRequestClose();*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <i className="zmdi zmdi-settings zmdi-hc-fw mr-2" />*/}
          {/*  <IntlMessages id="popup.setting" />*/}
          {/*</MenuItem>*/}
          <MenuItem
            onClick={() => {
              this.handleRequestClose();
              localStorage.removeItem('token');
              localStorage.removeItem('admin_name');
              localStorage.removeItem('store_id');
              // dispatch(LogOutSuccess(Merchant));
              window.location.assign('/signin');
            }}
          >
            <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

            <IntlMessages id="popup.logout" />
          </MenuItem>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default connect(mapStateToProps, { LogOutMerchant })(UserInfo);
