// Add New Merchant
export const selectMerchant = state => {
  return state.merchantReducer.Merchant;
};
export const selectMerchantPending = state => {
  return state.merchantReducer.pending;
};
export const selectMerchantError = state => {
  return state.merchantReducer.error;
};
export const selectAuthUser = state => {
  return state.merchantReducer.authUser;
};
export const selectAuthUserRole = state => {
  return Number(state.auth.role);
};
