import React from 'react';
import UploadImage from './UploadImage/UploadImage';

export default function Images(props) {
  const { data, handleChange, removeProductImage, type, UpdateCroppedImage } = props;
  return (
    <>
      <div className="club-add-product-content">
        <div className="club-add-product-content__images-wrapper">
          <UploadImage
            data={data}
            handleChange={handleChange}
            type={type}
            removeImage={removeProductImage}
            UpdateCroppedImage={UpdateCroppedImage}
          />
        </div>
      </div>
    </>
  );
}
