import React from 'react';
import CardBox from 'components/CardBox/index';
import translate from '../../../../appUtility/Translate';
import TextInputWithLabel from '../../../../components/commonElements/TextInputWithLabel';
import SelectWithLabel from '../../../../components/commonElements/SelectWithLabel';

export default function Billing({ data, handleChange, Countries }) {
  return (
    <div className="club-add-merchant-content">
      <CardBox
        styleName="col-12"
        cardStyle="p-0"
        style={{ borderRadius: '4px', border: '1px solid rgb(222,220,236)' }}
        headerOutside
      >
        <div>
          <h1>{translate('billing_data')}</h1>
          <form className="">
            <div>
              <TextInputWithLabel
                label={translate('first_name')}
                id="first_name"
                name="first_name"
                value={data.firstName}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div>
              <TextInputWithLabel
                label={translate('last_name')}
                id="lastName"
                name="lastName"
                value={data.lastName}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div>
              <TextInputWithLabel
                label={translate('company')}
                id="shippingCompany"
                name="shippingCompany"
                value={data.shippingCompany}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div>
              <TextInputWithLabel
                label={translate('city')}
                id="shippingCity"
                name="shippingCity"
                value={data.shippingCity}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div>
              <TextInputWithLabel
                label={translate('post_code')}
                id="zip"
                name="zip"
                value={data.zip}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div>
              <SelectWithLabel
                defaultValue={data.shippingCountry}
                data={Countries && Countries}
                label={translate('country')}
                id={'shippingCountry'}
                name="shippingCountry"
                value={data[0] ? data[0].id : ''}
                onChange={handleChange}
              />
            </div>
            <div>
              <TextInputWithLabel
                label={translate('state')}
                id="shippingState"
                name="shippingState"
                value={data.shippingState}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
          </form>
        </div>
      </CardBox>
    </div>
  );
}
