import React, { useRef, useEffect, useState } from 'react';
import Activity from './charts/Activity';
import './styles/style.css';
import AcountActivation from './charts/AcountActivation';
import { withRouter } from 'react-router-dom';
import translate from '../../../appUtility/Translate';
import ChartCard from '../../../components/dashboard/Common/ChartCard';
import personIcon from '../../../assets/icons/statsPerson.svg';
import tickIcon from '../../../assets/icons/statsTick.svg';
import cartIcon from '../../../assets/icons/statsCart.svg';
import clockIcon from '../../../assets/icons/statsClock.svg';
import cardIcon from '../../../assets/icons/statsCard.svg';
import starIcon from '../../../assets/icons/statsStar.svg';
import BestSellers from './charts/BestSellers/BestSellers';
import LastOrders from './charts/LastOrders/LastOrders';
import Countries from './charts/Countries/Countries';
import { CircularProgress } from '@material-ui/core';

const Charts = props => {
  const chatsUnsubscribe = useRef(() => {});
  const productsUnsubscribe = useRef(() => {});
  const statisticsUnsubscribe = useRef(() => {});
  const [active] = useState(false);
  const [selectedWebsiteId, setSelectedWebsiteId] = useState(null);
  const [clickEvents, setClickEvents] = useState({});
  const [loginEvents, setLoginEvents] = useState({});
  const [logoutEvents, setLogoutEvents] = useState({});
  const [updateCartEvents, setUpdateCartEvents] = useState({});
  const [userJoinedChatEvents, setUserJoinedChatevents] = useState({});
  const [newCartAddedEvent, setNewCartAddedEvent] = useState({});
  const [newInvitationEvents, setNewInvitationEvents] = useState({});
  const [newChatEvents, setNewChatEvents] = useState({});
  const [visitEvents, setVisitEvents] = useState({});
  const [chatDeletedEvents, setChatDeletedEvents] = useState({});
  const [productsDiscussedEvents, setProductsDiscussedEvents] = useState({});
  const [navigationEvents, setNavigationEvents] = useState({});
  const [submitOrderEvents, setSubmitOrderEvents] = useState({});
  const [ordersQuantity, setOrdersQuantity] = useState({});
  const [ordersTotalCostWeekly, setOrdersTotalCostWeekly] = useState({});
  const [ordersTotalCost, setOrdersTotalCost] = useState({});
  const [soldItemsQuantityWeekly, setSoldItemsQuantityWeekly] = useState({});
  const [soldItemsQuantity, setSoldItemsQuantity] = useState({});
  const [allSalesQuantity, setAllSalesQuantity] = useState(0);
  const [totalSalesCost, setTotalSalesCost] = useState(0);
  const [totalSalesCostByDays, setTotalSalesCostByDays] = useState({});
  //Weekly Events Data]
  const [clickEventsWeekly, setClickEventsWeekly] = useState({});
  const [newCartAddedEventWeekly, setNewCartAddedEventWeekly] = useState({});
  const [newInvitationEventsWeekly, setNewInvitationEventsWeekly] = useState({});
  const [newChatEventsWeekly, setNewChatEventsWeekly] = useState({});
  const [chatDeletedEventsWeekly, setChatDeletedEventsWeekly] = useState({});
  const [submitOrderEventsWeekly, setSubmitOrderEventsWeekly] = useState({});
  const [ordersQuantityWeekly, setOrdersQuantityWeekly] = useState({});
  const [visitEventsWeekly, setVisitEventsWeekly] = useState({});

  const [ownerHasSite, setOwnerHasSite] = useState(true);

  const [event, setEvent] = useState({ id: null });
  const owner = {};

  useEffect(() => {
    initStatistics();
    return () => {
      unsubscribeEvents();
    };
  }, []);

  useEffect(() => {
    sumTotalSales();
    sumSaleQuantity();
  }, [submitOrderEvents]);

  useEffect(() => {
    props.selectedWebsiteId && setSelectedWebsiteId(props.selectedWebsiteId);
  }, [props.selectedWebsiteId]);

  useEffect(() => {
    if (event.key) {
      assignBykey(event.key, event.data, 'update', false);
    }
  }, [event]);

  useEffect(() => {
    processOrdersData(ordersTotalCost);
  }, [ordersTotalCost]);

  const unsubscribeEvents = () => {
    // statistics.current.unsubscribe();
    // eventsUnsubscribe.current();
    chatsUnsubscribe.current();
    productsUnsubscribe.current();
    statisticsUnsubscribe.current();
  };

  const initStatistics = async () => {
    // const newStatisticsInitStatus = await statistics.current.init();
    // setStatisticsInitStatus(newStatisticsInitStatus);
  };

  const assignBykey = (eventName, eventDataOb, type, weekly) => {
    if (!weekly) {
      switch (eventName) {
        case 'app_button_click':
          setClickEvents(type === 'set' ? { ...eventDataOb } : { ...clickEvents, ...eventDataOb });
          break;
        case 'login':
          setLoginEvents(type === 'set' ? { ...eventDataOb } : { ...loginEvents, ...eventDataOb });
          break;
        case 'logout':
          setLogoutEvents(type === 'set' ? { ...eventDataOb } : { ...logoutEvents, ...eventDataOb });
          break;
        case 'update_cart':
          setUpdateCartEvents(type === 'set' ? { ...eventDataOb } : { ...updateCartEvents, ...eventDataOb });
          break;
        case 'user_joined_chat':
          setUserJoinedChatevents(type === 'set' ? { ...eventDataOb } : { ...userJoinedChatEvents, ...eventDataOb });
          break;
        case 'product_added_to_cart':
          setNewCartAddedEvent(type === 'set' ? { ...eventDataOb } : { ...newCartAddedEvent, ...eventDataOb });
          break;
        case 'new_invitation':
          setNewInvitationEvents(type === 'set' ? { ...eventDataOb } : { ...newInvitationEvents, ...eventDataOb });
          break;
        case 'visit':
          setVisitEvents(type === 'set' ? { ...eventDataOb } : { ...visitEvents, ...eventDataOb });
          break;
        case 'new_chat':
          setNewChatEvents(type === 'set' ? { ...eventDataOb } : { ...newChatEvents, ...eventDataOb });
          break;
        case 'product_discussed':
          setProductsDiscussedEvents(
            type === 'set' ? { ...eventDataOb } : { ...productsDiscussedEvents, ...eventDataOb }
          );
          break;
        case 'chat_deleted':
          setChatDeletedEvents(type === 'set' ? { ...eventDataOb } : { ...chatDeletedEvents, ...eventDataOb });
          break;
        case 'navigate':
          setNavigationEvents(type === 'set' ? { ...eventDataOb } : { ...navigationEvents, ...eventDataOb });
          break;
        case 'product_submit_order':
          setSubmitOrderEvents(type === 'set' ? { ...eventDataOb } : { ...submitOrderEvents, ...eventDataOb });
          break;
        case 'orders_quantity':
          setOrdersQuantity(type === 'set' ? { ...eventDataOb } : { ...ordersQuantity, ...eventDataOb });
          break;
        case 'orders_total_cost':
          setOrdersTotalCost(type === 'set' ? { ...eventDataOb } : { ...ordersTotalCost, ...eventDataOb });
          break;
        case 'sold_items_quantity':
          setSoldItemsQuantity(type === 'set' ? { ...eventDataOb } : { ...soldItemsQuantity, ...eventDataOb });
          break;
      }
    } else {
      switch (eventName) {
        case 'app_button_click':
          setClickEventsWeekly({ ...clickEventsWeekly, ...eventDataOb });
          break;
        case 'product_added_to_cart':
          setNewCartAddedEventWeekly({ ...newCartAddedEventWeekly, ...eventDataOb });
          break;
        case 'new_invitation':
          setNewInvitationEventsWeekly({ ...newInvitationEventsWeekly, ...eventDataOb });
          break;
        case 'visit':
          setVisitEventsWeekly({ ...visitEventsWeekly, ...eventDataOb });
          break;
        case 'new_chat':
          setNewChatEventsWeekly({ ...newChatEventsWeekly, ...eventDataOb });
          break;
        case 'chat_deleted':
          setChatDeletedEventsWeekly({ ...chatDeletedEventsWeekly, ...eventDataOb });
          break;
        case 'product_submit_order':
          setSubmitOrderEventsWeekly({ ...submitOrderEventsWeekly, ...eventDataOb });
          break;
        case 'orders_quantity':
          setOrdersQuantityWeekly({ ...ordersQuantityWeekly, ...eventDataOb });
          break;
        case 'orders_total_cost':
          setOrdersTotalCostWeekly({ ...ordersTotalCostWeekly, ...eventDataOb });
          break;
        case 'sold_items_quantity':
          setSoldItemsQuantityWeekly({ ...soldItemsQuantityWeekly, ...eventDataOb });
          break;
      }
    }
  };

  const sumObjectsByKey = (...objs) => {
    // sum function of object key values
    return objs.reduce((a, b) => {
      for (let k in b) {
        if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
      }
      return a;
    }, {});
  };

  const processOrdersData = inputData => {
    let newObject = {};
    Object.entries(inputData).forEach(([key, value]) => {
      //changing object to array
      let splittedDate = key.slice(5, 10).split('-'); // getting only day/month without hours and year
      let newDateStructure = `${splittedDate[1]}/${splittedDate[0]}`; // changing day/month to month/day
      if (newObject[newDateStructure]) {
        // if day already exists (this happens when date is same but hours different)
        let sum = sumObjectsByKey(newObject[newDateStructure], value); // sum previous and new day(hours)
        newObject = { ...newObject, [newDateStructure]: sum }; // set this sum as new value
      } else {
        newObject = { ...newObject, [newDateStructure]: value };
      }
    });
    setTotalSalesCostByDays(newObject);
  };

  const sumSaleQuantity = () => {
    let saleQuantity = 0;

    Object.entries(submitOrderEvents).forEach(([key, value]) => {
      // sum of object keys (total sales quantity)
      saleQuantity += value.sales;
    });

    setAllSalesQuantity(saleQuantity);
  };

  const sumTotalSales = () => {
    let totalSalesArr = [];
    Object.entries(submitOrderEvents).forEach(([key, value]) => {
      //changing object to array
      totalSalesArr = [...totalSalesArr, value.total_sales];
    });

    let totalSales = [
      totalSalesArr.reduce((acc, n) => {
        // sum of object keys (cost)
        for (var prop in n) {
          if (acc.hasOwnProperty(prop)) acc[prop] += n[prop];
          else acc[prop] = n[prop];
        }
        return acc;
      }, {})
    ];

    let totalSalesString = '';

    const entries = Object.entries(totalSales[0]); // changing array to object

    entries.map(entry => {
      // changing array to string
      let currencySign;

      switch (
        entry[0] // changing symbols
      ) {
        case 'USD':
          currencySign = '$';
          break;
        case 'GBP':
          currencySign = '£';
          break;
        case 'EUR':
          currencySign = '€';
          break;
        case 'GEL':
          currencySign = '₾';
          break;
        default:
          currencySign = entry[0];
      }

      totalSalesString += `${entry[1]}${currencySign} `;
    });

    setTotalSalesCost(totalSalesString);
  };

  const calculatePercentage = (total, last) => {
    return total > last ? Math.floor((last / total) * 100) : Math.floor((total / last) * 100);
  };

  const { data, pending } = props;

  return (
    <>
      <>
        <div className="row charts-cards__wrappers">
          <div className="col-lg-2 col-md-4 col-sm-6 col-12 dashboard-card--wrapper">
            <ChartCard
              value={data.visits && data.visits.selected}
              title={translate('Customers')}
              /*percent={calculatePercentage(
                  data.visits && data.visits.selected,
                  data.visits && data.visits.last_month
                )}*/
              icon={personIcon}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12 dashboard-card--wrapper">
            <ChartCard
              value={data.payments && data.payments.selected}
              title={translate('successful_payments')}
              percent={calculatePercentage(
                data.payments && data.payments.selected,
                data.payments && data.payments.last_month
              )}
              icon={tickIcon}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12 dashboard-card--wrapper">
            <ChartCard
              value={data.sales && '$' + data.sales.selected}
              subValue={`$${data.sales && data.sales.average_order}`}
              title={translate('total_sales')}
              percent={calculatePercentage(data.sales && data.sales.selected, data.sales && data.sales.last_month)}
              icon={cartIcon}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12 dashboard-card--wrapper">
            <ChartCard
              value={data.pendingOrders && data.pendingOrders.selected}
              subValue={data.pendingOrders && '$' + data.pendingOrders.money}
              title={translate('pending_approve')}
              icon={clockIcon}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12 dashboard-card--wrapper">
            <ChartCard
              value={data.declinedOrders && data.declinedOrders.selected}
              subValue={data.declinedOrders && '$' + data.declinedOrders.money}
              title={translate('declined_orders')}
              icon={cardIcon}
            />
          </div>
          <div className="col-lg-2 col-md-4 col-sm-6 col-12 dashboard-card--wrapper">
            <ChartCard
              value={data.customers && data.customers.selected}
              title={translate('new_customers')}
              percent={calculatePercentage(
                data.customers && data.customers.selected,
                data.customers && data.customers.last_month
              )}
              icon={starIcon}
            />
          </div>
        </div>

        {owner && ownerHasSite ? (
          <div className="row">
            <div className="col-12">
              <Activity info={data.chart && data.chart} />
            </div>
          </div>
        ) : (
          <AcountActivation></AcountActivation>
        )}

        <div className="row little-charts__wrapper" style={{ marginTop: '15px' }}>
          <div className="chats-activity__wrapper">
            <BestSellers data={data.bestSellers && data.bestSellers} />
          </div>
          <div className="top-products__wrapper">
            <LastOrders data={data.lastOrders && data.lastOrders} />
          </div>
          <div className="top-members__wrapper last">
            <Countries data={{ mapData: data.mapData, map: data.map }} onChangeCategory={props.onChangeMapCategory} />
          </div>
        </div>
      </>
    </>
  );
};

export default withRouter(Charts);
