import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import { NavLink, withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from '../../../appUtility/Api';
import { connect } from 'react-redux';
import { showAuthLoader, hideAuthLoader } from 'actions/Auth';
import { CircularProgress } from '@material-ui/core';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../appUtility/Loader';
import Checkbox from '@material-ui/core/Checkbox';

import { getComparator, stableSort } from '../../../util/EnhancedTableFunctions';

import EnhancedTableHead from './../../../components/EnhancedTable/EnhancedTableHead';

const columnData = [
  { id: 'user_icon', align: false, disablePadding: true, sortable: false, label: '' },
  { id: 'id', align: false, disablePadding: true, sortable: true, label: 'User ID' },
  { id: 'email', align: true, disablePadding: false, sortable: true, label: 'Email' },
  { id: 'first_name', align: true, disablePadding: false, sortable: true, label: 'First Name' },
  { id: 'last_name', align: true, disablePadding: false, sortable: true, label: 'Last Name' },
  { id: 'edit', align: true, disablePadding: false, sortable: false, label: 'Edit' },
  { id: 'remove', align: true, disablePadding: false, sortable: false, label: 'Remove' }
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {/*<TableCell padding="checkbox">*/}
          {/*    <Checkbox color="primary"*/}
          {/*              indeterminate={numSelected > 0 && numSelected < rowCount}*/}
          {/*              checked={numSelected === rowCount}*/}
          {/*              onChange={onSelectAllClick}*/}
          {/*    />*/}
          {/*</TableCell>*/}
          {columnData.map(column => {
            return (
              <TableCell key={column.id} align={column.numeric} padding={column.disablePadding ? 'none' : 'default'}>
                <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                  {column.id === 'edit' || column.id === 'remove' ? (
                    <div active={orderBy === column.id} direction={order} onClick={this.createSortHandler(column.id)}>
                      {column.label}
                    </div>
                  ) : (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={order}
                        onClick={this.createSortHandler(column.id)}
                      >
                        {column.label}
                      </TableSortLabel>
                    )}
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

let DataTableToolbar = props => {
  const { numSelected } = props;

  return (
    <Toolbar className="table-header">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
            <Typography variant="title">Nutrition</Typography>
          )}
      </div>
      <div className="spacer" />
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

class DataTable extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    let data = [...this.state.data];
    order === 'desc'
      ? data.sort((a, b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase() ? 1 : -1))
      : (data = data.sort((a, b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase() ? -1 : 1)));
    this.setState({ data, order, orderBy });
  };
  
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });

      const data = this.state.data;
      const sliced = data.slice(0, this.state.rowsPerPage);
      const idArray = [];
      sliced.forEach(s => {
        idArray.push(parseInt(s.id));
      });
      this.props.handleChangeSelectedRows(idArray);
      return;
    }
    this.props.handleChangeSelectedRows([]);
    this.setState({ selected: [] });
  };
  handleKeyDown = (event, id) => {
    event.preventDefault();
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    this.props.handleChangeSelectedRows(newSelected);
    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.props.setPage(page);
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 10
    };
  }

  deleteThisUser = id => {
    let getAlert = () => {
      return (
        <SweetAlert
          warning
          show
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure that you want to delete?"
          onConfirm={() => this.deleteUser(id)}
          onCancel={this.hideAlert}
        ></SweetAlert>
      );
    };

    this.setState({
      alert: getAlert()
    });
  };
  hideAlert = () => {
    this.setState({
      alert: null
    });
  };
  deleteUser = id => {
    const data = [...this.state.data];
    let obj = data.find(d => d.id === id);
    let index = data.indexOf(obj);
    data.splice(index, 1);
    this.setState({
      alert: null,
      data
    });
    axios({
      method: 'post',
      url: axios.defaults.baseURL + '/admin/deletecoreuser',
      data: JSON.stringify({ id }),
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    }).then(res => {
      if (res.data.success) {
        window.location.reload();
      }
    });
  };
  clearList = () => {
    this.setState({
      selected: []
    });
  };

  componentWillReceiveProps(nextProps) {
    nextProps.data && this.setState({ data: nextProps.data });
    if (nextProps.selected.length === 0 && this.state.selected.length > 0) {
      this.setState({ selected: [] });
    }
  }

  render() {
    const { data, order, orderBy, selected, rowsPerPage, alert } = this.state;
    const { pending, page } = this.props;
    return (
      <>
        {alert}
        <Loader loading={pending}>
          <div className="users-card mt-4" style={{ height: '100%' }}>
            <div className="MuiPaper-elevation1">
              <div className="flex-auto" style={{ height: '100%' }}>
                <div className="table-responsive-material" style={{ height: '100%' }}>
                  <Table>
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={data.length}
                      headCells={columnData}
                    />

                    <TableBody>
                      {data.length &&
                        stableSort(data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map((n, index) => {
                          const isItemSelected = this.isSelected(n.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              // onClick={event => this.handleClick(event, n.id)}
                              onKeyDown={event => this.handleKeyDown(event, n.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={n.id}
                              selected={isItemSelected}
                              onClick={(event) => this.handleClick(event, n.id)}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </TableCell>
                              <TableCell padding='none'>
                                <i class="fa fa-user-circle" style={{ fontSize: '30px', color: "#e1e2ea" }} aria-hidden="true"></i>
                              </TableCell>
                              <TableCell padding="none">{n.id}</TableCell>
                              <TableCell align="right">{n.email}</TableCell>
                              <TableCell align="right">{n.first_name}</TableCell>
                              <TableCell align="right">{n.last_name}</TableCell>
                              <TableCell align="right">
                                <NavLink
                                  className="prepend-icon "
                                  style={{ color: '#000000' }}
                                  to={'/app/users/wizard/' + n.id}
                                >
                                  <i
                                    className="fas fa-pencil-square-o"
                                    style={{ fontSize: '16px', color: 'rgba(0,0,0,0.54)' }}
                                  ></i>
                                </NavLink>
                              </TableCell>
                              <TableCell align="right">
                                <i
                                  className="fas fa-trash-alt"
                                  style={{ fontSize: '16x', color: 'rgba(0,0,0,0.54)' }}
                                  onClick={() => this.deleteThisUser(n.id)}
                                ></i>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={data && data.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={this.handleChangePage}
                          onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </Loader>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, role, store } = auth;
  return { loader, role, store };
};

export default withRouter(
  connect(mapStateToProps, {
    showAuthLoader,
    hideAuthLoader
  })(DataTable)
);
