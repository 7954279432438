import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import { NavLink, withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from '../../../../appUtility/Api';
import { connect } from 'react-redux';
import { showAuthLoader, hideAuthLoader } from 'actions/Auth';
import { CircularProgress } from '@material-ui/core';
import translate from '../../../../appUtility/Translate';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../../appUtility/Loader';

import EnhancedTableHead from './../../../../components/EnhancedTable/EnhancedTableHead';
import { getComparator, stableSort } from '../../../../util/EnhancedTableFunctions';

let counter = 0;

function createData(customerId, username, email, first_name, last_name, shipping_data, order_data, credit_data, action) {
  counter += 1;
  return { id: counter, customerId, username, email, first_name, last_name, shipping_data, order_data, credit_data, action };
}

const headCells = [
  { id: 'customerId', sort: true, align: false, disablePadding: true, sortable: true, label: 'User ID' },
  { id: 'username', sort: true, align: false, disablePadding: true, sortable: true, label: 'Mobile' },
  { id: 'email', sort: true, align: false, disablePadding: true, sortable: true, label: 'Email' },
  { id: 'first_name', sort: true, align: true, disablePadding: false, sortable: true, label: 'First Name' },
  { id: 'last_name', sort: true, align: true, disablePadding: false, sortable: true, label: 'Last Name' },
  { id: 'shipping_data', sort: false, align: true, disablePadding: false, sortable: true, label: 'Shipping Data' },
  { id: 'order_data', sort: false, align: true, disablePadding: false, sortable: true, label: 'Order Data' },
  { id: 'card_data', sort: false, align: true, disablePadding: false, sortable: true, label: 'Card Data' },
  { id: 'action', sort: false, align: true, disablePadding: false, sortable: false, label: 'Action' }
];

let DataTableToolbar = props => {
  const { numSelected } = props;

  return (
    <Toolbar className="table-header">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
            <Typography variant="title">Nutrition</Typography>
          )}
      </div>
      <div className="spacer" />
      <div className="actions">
        {numSelected > 0 && (
          <div>
            <p>fdsfdsf</p>
            <Tooltip title="Delete">
              <IconButton aria-label="Delete">
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

class DataTable extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    let data = [...this.state.data];
    this.props.FetchCustomers({ sort: { orderBy, order } });

    // order === 'desc'
    //   ? data.sort((a, b) => {
    //       const x = a[orderBy] || '';
    //       const y = b[orderBy] || '';
    //       return x.toUpperCase() > y.toUpperCase() ? 1 : -1;
    //     })
    //   : (data = data.sort((a, b) => {
    //       const x = a[orderBy] || '';
    //       const y = b[orderBy] || '';
    //       return x.toUpperCase() > y.toUpperCase() ? -1 : 1;
    //     }));

    this.setState({ data, order, orderBy });

    console.log('dataaa', data);
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };
  handleKeyDown = (event, id) => {
    event.preventDefault();
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  deleteThisUser = id => {
    const data = [...this.state.data];
    let obj = data[id];
    let index = data.indexOf(obj);
    data.splice(index, 1);
    this.setState({ data });
    axios({
      method: 'post',
      url: axios.defaults.baseURL + '/admin/customer/deletecustomer',
      data: JSON.stringify({ id: id }),
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    }).then(res => {
      this.props.FetchCustomers();
    });
  };

  handleClick = (event, id) => {
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.props.setPage(page);
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 10,
      alert: null
    };
  }

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  deleteThisProduct = id => {
    let getAlert = () => {
      return (
        <SweetAlert
          warning
          show
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure that you want to delete?"
          onConfirm={() => this.deleteCustomer(id)}
          onCancel={this.hideAlert}
        ></SweetAlert>
      );
    };
    this.setState({
      alert: getAlert()
    });
  };

  deleteCustomer = id => {
    this.setState({
      alert: null
    });
    this.props.DeleteCustomer(id);
    // const data = this.state.data;
    // let index = data.findIndex(i => i.id === id.id);
    //
    // data.splice(index, 1);
    //
    //
    //
    // this.setState({ data });
    // axios({
    //   method: 'post',
    //   url: axios.defaults.baseURL + '/admin/customer/deletecustomer',
    //   data: JSON.stringify({ id }),
    //   headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    // });
    // this.setState({ data });
  };

  componentWillReceiveProps(nextProps) {
    nextProps.data && nextProps.data.length > 0 && this.setState({ data: nextProps.data });
  }

  render() {
    const { data, order, orderBy, selected, rowsPerPage, alert } = this.state;
    const { pending, page, onBlock, hasBlock } = this.props;
    return (
      <>
        <Loader loading={pending}>
          <>
            {alert}
            <div className="customers-card" style={{ height: '100%' }}>
              <div className="MuiPaper-elevation1">
                <div className="flex-auto">
                  <div className="table-responsive-material">
                    <Table className="" editable={true}>
                      {/* <DataTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        onRequestSort={this.handleRequestSort}
                        rowCount={data.length}
                      /> */}
                      <EnhancedTableHead
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={this.handleSelectAllClick}
                        onRequestSort={this.handleRequestSort}
                        headCells={headCells}
                      />
                      <TableBody editable={true}>
                        {data.length &&
                          stableSort(data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), getComparator(order, orderBy)).map((n, index) => {
                            const isItemSelected = this.isSelected(n.id);
                            const labelId = `enhanced-table-checkbox-${index}`;

                            return (
                              <TableRow
                                hover
                                // onClick={event => this.handleClick(event, n.id)}
                                onKeyDown={event => this.handleKeyDown(event, n.id)}
                                role="checkbox"
                                aria-checked={isItemSelected}
                                tabIndex={-1}
                                key={n.id}
                                selected={isItemSelected}
                                className={n.blocked == 0 && 'table-row--disabled'}
                              >
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={isItemSelected}
                                    inputProps={{ "aria-labelledby": labelId }}
                                  />
                                </TableCell>
                                <TableCell padding="none">{n.id}</TableCell>
                                <TableCell padding="none">{n.username}</TableCell>
                                <TableCell align="right">{n.email}</TableCell>
                                <TableCell align="right">{n.first_name}</TableCell>
                                <TableCell align="right">{n.last_name}</TableCell>
                                <TableCell align="right">
                                  <NavLink className="prepend-icon" to={'/app/customers/' + n.id + '/shipping'}>
                                    Shipping Data
                                  </NavLink>
                                </TableCell>
                                <TableCell align="right">
                                  <NavLink className="prepend-icon" to={'/app/customers/' + n.id + '/orders'}>
                                    Order Data
                                  </NavLink>
                                </TableCell>
                                <TableCell align="right">
                                  <NavLink className="prepend-icon" to={'/app/customers/' + n.id + '/card'}>
                                    Card Data
                                  </NavLink>
                                </TableCell>
                                <TableCell>
                                  <i
                                    className="fas fa-trash-alt"
                                    style={{ fontSize: '16px', color: 'rgba(0,0,0,0.54)' }}
                                    onClick={() => this.deleteThisProduct(n)}
                                  />
                                  {hasBlock && (
                                    <div
                                      className={
                                        'table-actions__block' +
                                        (n.blocked == 0 ? ' table-actions__block--unblock' : '')
                                      }
                                      onClick={() => onBlock(n)}
                                    >
                                      <span>{n.blocked == 0 ? translate('unblock') : translate('block')}</span>
                                    </div>
                                  )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            count={data && data.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={this.handleChangePage}
                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Loader>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, role, store } = auth;
  return { loader, role, store };
};

export default withRouter(
  connect(mapStateToProps, {
    showAuthLoader,
    hideAuthLoader
  })(DataTable)
);
