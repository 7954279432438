import React, { useState, useRef } from 'react';
import { BarChart, Bar, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid } from 'recharts';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import translate from '../../../../appUtility/Translate';
import personIcon from '../../../../assets/icons/statsPerson.svg';
import tickIcon from '../../../../assets/icons/statsTick.svg';
import cartIcon from '../../../../assets/icons/statsCart.svg';
import clockIcon from '../../../../assets/icons/statsClock.svg';
import cardIcon from '../../../../assets/icons/statsCard.svg';
import starIcon from '../../../../assets/icons/statsStar.svg';

const Activity = props => {
  const { data, processData, startDate, endDate, formatDate, emptyData, totalSalesCostByDays, info } = props;
  const [, setLoading] = useState(true);
  const [shownExpanded, setShownExpanded] = useState({ visitEvents: true });
  const [interval, setInterval] = useState(props.interval);
  const selectedStartDate = useRef(startDate);
  const selectedEndDate = useRef(endDate);
  const [selectActivity, setSelectActivity] = useState({ image: cartIcon, value: 2 });

  const [menusItem] = useState([
    {
      image: cartIcon,
      value: 2,
      title: translate('sales')
    }
  ]);

  const [nodata, setNodata] = useState('activityChart-nodate');

  const useStyles = makeStyles(theme => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 300,
      maxWidth: 500,
      textAlign: 'left'
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    chip: {
      margin: 2
    },
    noLabel: {
      marginTop: theme.spacing(3)
    }
  }));

  const [labelWidth, setLabelWidth] = useState(0);

  const getMonthName = month => {
    const monthNames = [
      '',
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];
    let [day, monthNumber] = month.split('/');

    if (monthNumber.charAt(0) === '0') {
      monthNumber = monthNumber.slice(1, 2);
    }

    return `${day} ${monthNames[monthNumber]}`;
  };

  const CustomTooltip = ({ data, active, label, payload }) => {
    const item = data && data.length && data.find(d => d.date == label);

    if (active) {
      return (
        item && (
          <div className="dashboard-custom-tooltip">
            <div className="tooltip-header">
              <div className="header-left">
                <h4>{getMonthName(item.date)}</h4>
                <h2>${item.total_orders_money}</h2>
                <h5>{item.total_orders} Orders</h5>
              </div>
              <div className="header-right">28%</div>
            </div>
            <div className="tooltip-merchants">
              {item.merchants.length &&
                item.merchants.map(i => {
                  return (
                    <div className="merchant-item" key={`tooltip - item - ${i.name} `}>
                      <div className="item-left">
                        <h4> {i.name} </h4>
                        <span> {i.total_orders} Orders </span>
                      </div>

                      <div className="item-right">
                        {' '}
                        <span>${i.total_orders_money}</span>{' '}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        )
      );
    }

    return null;
  };
  const maxValue =
    info && info.length > 0
      ? info.reduce((item, nextItem) => {
          const current = typeof item === 'object' ? item.total_orders : item;
          const next = nextItem.total_orders;
          return next > current ? next : current;
        })
      : 100;
  return (
    <div className="card dashboard_main-chart shadow text-center">
      <div className="dashboard_main-chart_header">
        <FormControl variant="outlined">
          <Select
            value={selectActivity.value}
            // onChange={e => handleLegendClick(e)}
            classes={{ root: 'p-sm dashboard_main-chart_select' }}
            labelWidth={labelWidth}
            inputProps={{
              name: 'activity',
              id: 'outlined-activity-select'
            }}
          >
            {menusItem &&
              menusItem.map((item, i) => (
                <MenuItem value={item.value} key={i}>
                  <span className="dashboard_main-chart_select_item">
                    <img src={item.image} />
                    {item.title}
                  </span>
                </MenuItem>
              ))}
          </Select>
        </FormControl>

        <div className="d-flex flex-lg-row flex-column align-items-center col-auto">
          <div className="col-lg-auto col-12"></div>

          <div className="col-lg-3 col-md-6 col-auto ml-lg-4 ml-3 mr-auto select-website"></div>

          <div className="jr-tabs-up-no-border col-lg-auto col-12"></div>
        </div>
      </div>
      <div className="px-3 pb-3 pt-0">
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={info} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
            <XAxis dataKey="date" axisLine={false} />
            <YAxis
              dataKey="total_orders"
              domain={[0, 'dataMax + 10']}
              tickCount={(10 + maxValue + (maxValue % 5)) / 5 + 1}
              axisLine={false}
            />
            <CartesianGrid vertical={false} />>
             {data && <Tooltip content={<CustomTooltip data={info} />} cursor={{ fill: 'transparent' }} />}

            <Bar
              barSize={8}
              dataKey="total_orders"
              strokeWidth={0}
              fill="#009ee3"
              radius={[10, 10, 0, 0]}
              // key={'Area-' + k}
              // stackId={stacked ? 0 : i}
              // stroke={chartColors[k][1]}
              // fillOpacity={0.9}
              // fill={chartColors[k][0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default Activity;
