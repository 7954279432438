import React from 'react';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import Rocket from '../../src/assets/images/rocket.png';
import CloseXbtn from '../components/commonElements/CloseXbtn';
import EnviteIcon from '../components/commonElements/PaylixIcon';
import BG1 from '../components/Backgrounds/BG1';
import WhiteLogo from '../assets/images/logo/logo-white.png';
class ResetPass extends React.Component {
  componentDidUpdate() {
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <>
        <BG1 />
        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-forget-password app-forget-password__sent">
              <CloseXbtn to="/signin" />

              <h1>Reset Your Password</h1>

              <p>An email is on its way to you. Follow the instructions to reset your password.</p>

              <div className="rocket-image__wrapper">
                <img src={Rocket} alt="lock" className="forgot-password__rocket" />
              </div>

              <div className="mb-3 d-flex align-items-center justify-content-between">
                <Button className="login-button" variant="contained" color="primary">
                  <Link className="reset-password__text" to="/signin">
                    <IntlMessages id="appModule.signIn" />
                  </Link>
                </Button>
              </div>
              <img src={WhiteLogo} className="envite-logo" />
              {/*<EnviteIcon color="white" />*/}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ResetPass;
