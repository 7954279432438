import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import axios from '../../../../appUtility/Api';
import SimpleReactValidator from 'simple-react-validator';
import UploadImage from '../Product/UploadImage/UploadImage';
import SliderSidebar from './sliderSidebar';
import Header from 'components/Header';
import translate from '../../../../appUtility/Translate';
import Select from 'react-select';

class Wizard extends Component {
  state = {
    image: null,
    productId: '',
    searchValue: '',
    products: [],
    selectedProduct: '',
    isValid: {
      productId: true,
      image: true
    }
  };

  onDrop = picture => {
    this.setState({
      image: [picture],
      isValid: { ...this.state.isValid, image: true }
    });
  };

  onDeleteImage = () => {
    this.setState({
      image: null
    });
  };
  onSubmit = event => {
    event.preventDefault();
    const param = this.props.match.params.id;
    const isValid = {
      image: this.state.image !== null,
      productId: !!this.state.productId
    };
    this.setState({ isValid });
    if (isValid.image && isValid.productId) {
      if (param) {
        this.editData(param);
      } else {
        this.addData();
      }
    } else {
      this.forceUpdate();
    }
  };

  editData = id => {
    let image = this.state.image;
    const productId = this.state.productId;

    fetch(axios.defaults.baseURL + '/app/club/editslider?id=' + id, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ id: id, image: image, productId: productId })
    })
      .then()
      .then(() => {
        this.props.history.push('/app/club/slider/listing');
      });
  };

  addData = () => {
    const image = this.state.image;
    const productId = this.state.productId;
    fetch(axios.defaults.baseURL + '/app/club/addslider', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ image: image, productId: productId })
    })
      .then()
      .then(() => {
        this.props.history.push('/app/club/slider/listing');
      });
  };

  getData = id => {
    fetch(axios.defaults.baseURL + '/app/club/singleslider', {
      method: 'POST',
      body: JSON.stringify({
        id: id
      }),
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        this.setState({ image: data.image, productId: data.productId ? data.productId : '' });
      });
  };

  async componentDidMount() {
    const param = this.props.match.params.id;
    if (param) {
      this.getData(param);
    }
    await this.props.FetchFilteredProducts();
    const formatedProducts = this.props.Products.map(item => ({ value: item.id, label: item.name }));
    this.setState({ products: formatedProducts });
  }

  UpdateCroppedImage = img => {
    this.setState({ image: [img] });
  };

  handleProductChange = item => {
    this.setState({
      selectedProduct: item,
      productId: item.value,
      isValid: { ...this.state.isValid, productId: true }
    });
  };

  handleSearch = async value => {
    this.setState({ searchValue: value });
    await this.props.FetchFilteredProducts({ filters: { searchText: value } });
    const formattedProducts = this.props.Products.map(item => ({ value: item.id, label: item.name }));
    this.setState({ products: formattedProducts });
  };

  render() {
    return (
      <div className="merchant-wrapper">
        <SliderSidebar data={this.state.image} onSubmit={this.onSubmit} id={this.props.match.params.id} />
        <div className="merchant-container">
          <Header merchants="Slider" />
          <div className="merchant-card ">
            <div className="row m-4">
              <form
                onSubmit={this.onSubmit}
                style={{
                  width: '100%'
                }}
              >
                <div className="col-md-9">
                  <label htmlFor="">{translate('product_id')}</label>
                  <br />
                  <br />
                  <Select
                    options={this.state.products}
                    onInputChange={this.handleSearch}
                    inputValue={this.state.searchValue}
                    value={this.state.selectedProduct}
                    onChange={this.handleProductChange}
                  />
                </div>

                <div className="col-md-3">
                  {!this.state.isValid.productId && (
                    <p className="srv-validation-message">The product field is required.</p>
                  )}
                  <br />
                  <UploadImage
                    data={this.state.image}
                    handleChange={this.onDrop}
                    type={false}
                    removeImage={this.onDeleteImage}
                    UpdateCroppedImage={this.UpdateCroppedImage}
                  />
                  {!this.state.isValid.image && <p className="srv-validation-message">The image field is required.</p>}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Wizard;
