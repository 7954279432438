import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Sidebar from 'containers/SideNav/index';
import { COLLAPSED_DRAWER, FIXED_DRAWER } from 'constants/ActionTypes';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import SecondaryMenu from '../components/SecondaryMenu';
import axios from '../appUtility/Api';
import { verifyRole } from 'actions/Auth';
import { PrivateRoute } from '../components/PrivateRoute/PrivateRoute';
import CategoryView from '../store/category/category.view';
import CatalogView from '../store/catalog/catalog.view';
import MerchantView from '../store/merchant/merchant.view';
import OrderView from '../store/order/order.view';
import UserView from '../store/user/user.view';
import CustomerView from '../store/customer/customer.view';
import TranslationView from '../store/translation/translation.view';
import DashboardView from '../store/dashboard/dashboard.view';
import SliderView from '../store/slider/slider.view';
import Header from 'components/Header';
import ImporterModal from './routes/Club/Catalog/ImporterModal';
import { ToggleImporterModal, FetchSelectedProducts, ImportProducts } from '../store/catalog/catalog.action';
import OrderSingleView from '../store/order/order.single.view';
import ProductView from '../store/product/product.view';

class App extends React.Component {
  state = {
    role: null
  };

  verify = async role => {
    await fetch(axios.defaults.baseURL + '/admin/verify', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ token: JSON.parse(localStorage.getItem('token')) })
    })
      .then(response => {
        if (response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = '/signin';
        } else {
          return response.json();
        }
      })
      .then(async data => {
        if (role !== 'route') {
          this.props.verifyRole(data.role, data.store);
          await this.setState({ authenticated: true, role: data.role, company: data.company });
          localStorage.setItem('verificationData', JSON.stringify(data));
        }
      });
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.verify('route');
    }
  }

  componentWillMount() {
    this.verify();
  }

  render() {
    const { role, company } = this.state;
    const {
      match,
      drawerType,
      SelectedProducts,
      ImportProducts,
      pending,
      Categories,
      Merchants,
      imported
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? 'fixed-drawer'
      : drawerType.includes(COLLAPSED_DRAWER)
      ? 'collapsible-drawer'
      : 'mini-drawer';
    // const menuStyle = menuType;
    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height');
    } else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height');
    }
    return (
      <>
        <ImporterModal
          modal={this.props.ImporterModal}
          categories={Categories}
          merchants={Merchants}
          Products={SelectedProducts}
          pending={pending}
          imported={imported}
          ToggleImporterModal={this.props.ToggleImporterModal}
          FetchSelectedProducts={this.props.FetchSelectedProducts}
          ImportProducts={ImportProducts}
        />
        <div className={`app-container ${drawerStyle}`}>
          {/* <Tour/> */}

          <Sidebar role={role} company={company} />
          <div className="app-main-container">
            <div className="app-main">
              <SecondaryMenu />
              <main className="app-main-content-wrapper">
                <div className="app-main-content">
                  <Switch>
                    <Route path={`${match.url}/dashboard`} component={DashboardView} />
                    <Route path={`${match.url}/club/catalog`} component={CatalogView} />
                    <Route
                      path={`${match.url}/club/product/importer`}
                      component={asyncComponent(() => import('./routes/Club/Catalog/Importer'))}
                    />
                    <PrivateRoute roles={role} path={`${match.url}/club/category/listing`} component={CategoryView} />
                    <PrivateRoute
                      roles={role}
                      path={`${match.url}/club/category/wizard/:id`}
                      component={asyncComponent(() => import('./routes/Club/Categories/Wizard'))}
                    />
                    <PrivateRoute
                      roles={role}
                      path={`${match.url}/club/category/wizard`}
                      component={asyncComponent(() => import('./routes/Club/Categories/Wizard'))}
                    />

                    <Route path={`${match.url}/club/product/wizard/:id`} component={ProductView} />
                    <Route path={`${match.url}/club/product/wizard`} component={ProductView} />
                    {/*Slider*/}
                    <PrivateRoute
                      roles={role}
                      path={`${match.url}/club/slider/listing`}
                      component={asyncComponent(() => import('./routes/Club/Slider/listing'))}
                    />
                    <PrivateRoute roles={role} path={`${match.url}/club/slider/wizard/:id`} component={SliderView} />
                    <PrivateRoute roles={role} path={`${match.url}/club/slider/wizard`} component={SliderView} />

                    <PrivateRoute roles={role} path={`${match.url}/users/listing`} component={UserView} />
                    <PrivateRoute
                      roles={role}
                      path={`${match.url}/users/wizard/:id`}
                      component={asyncComponent(() => import('./routes/Users/Wizard/index'))}
                    />
                    <PrivateRoute
                      roles={role}
                      path={`${match.url}/users/wizard`}
                      component={asyncComponent(() => import('./routes/Users/Wizard/index'))}
                    />
                    <PrivateRoute roles={role} path={`${match.url}/customers/listing`} component={CustomerView} />
                    <PrivateRoute
                      roles={role}
                      path={`${match.url}/customers/:id/shipping`}
                      component={asyncComponent(() => import('./routes/Customers/ShippingTable/index'))}
                    />
                    <PrivateRoute
                      roles={role}
                      path={`${match.url}/customers/:id/orders`}
                      component={asyncComponent(() => import('./routes/Customers/OrderTable/index'))}
                    />
                    <PrivateRoute
                      roles={role}
                      path={`${match.url}/customers/:id/card`}
                      component={asyncComponent(() => import('./routes/Customers/CardTable/index'))}
                    />
                    <PrivateRoute roles={role} path={`${match.url}/orders/listing`} component={OrderView} />
                    <PrivateRoute roles={role} path={`${match.url}/orders/:id`} component={OrderSingleView} />

                    <Route
                      path={`${match.url}/logs/listing`}
                      component={asyncComponent(() => import('./routes/Logs/index'))}
                    />
                    <PrivateRoute roles={role} path={`${match.url}/translations/listing`} component={TranslationView} />
                    <PrivateRoute
                      roles={role}
                      path={`${match.url}/translations/wizard`}
                      component={asyncComponent(() => import('./routes/Translations/Wizard/index'))}
                    />
                    <Route path={`${match.url}/merchants/listing`} component={MerchantView} />
                    <Route
                      path={`${match.url}/merchants/wizard/:id`}
                      component={asyncComponent(() => import('./routes/Merchants/Wizard/index'))}
                    />
                    <Route
                      path={`${match.url}/merchants/wizard/`}
                      component={asyncComponent(() => import('./routes/Merchants/Wizard/index'))}
                    />
                    <Route component={asyncComponent(() => import('components/Error404'))} />
                  </Switch>
                </div>
                {/* <Footer/> */}
              </main>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ settings, ProductReducer, MerchantReducer, categoryReducer }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  const { ImporterModal, SelectedProducts, pending, imported } = ProductReducer;
  const { Merchants } = MerchantReducer;
  const { Categories } = categoryReducer;
  return {
    drawerType,
    navigationStyle,
    horizontalNavPosition,
    ImporterModal,
    imported,
    SelectedProducts,
    pending,
    Merchants,
    Categories
  };
};

export default withRouter(
  connect(mapStateToProps, {
    verifyRole,
    ToggleImporterModal,
    FetchSelectedProducts,
    ImportProducts
  })(App)
);
