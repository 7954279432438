import React from 'react';
import closeXbtn from '../../assets/icons/close-x.png';
import { Link } from 'react-router-dom';

export default function CloseXbtn({ to }) {
  return (
    <div className="close-x__button--wrapper">
      <Link className="close-x__button--link" to={to}>
        <img src={closeXbtn} alt="close button" className="close-x__button" />
      </Link>
    </div>
  );
}
