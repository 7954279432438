import { AddMerchantPending, AddMerchantSuccess, AddMerchantError } from './signup.actionCreator';
import SignUpService from '../../services/SignUp';

// Add New Merchant
export const StoreNewMerchant = merchant => {
  return async dispatch => {
    try {
      dispatch(AddMerchantPending());
      const Merchant = await SignUpService.storeMerchant(merchant);
      dispatch(AddMerchantSuccess(Merchant));

      if (Merchant.data.error) {
        dispatch(AddMerchantError(Merchant.data.error));
      }
    } catch (err) {
      dispatch(AddMerchantError(err));
    }
  };
};
