import React, { useState } from 'react';
import translate from '../../../../../appUtility/Translate';

const BestSellers = ({ data, styleName }) => {
  const [index, setIndex] = useState(0);

  const slider = dir => {
    if (dir) {
      if (data && data[index + 1]) {
        setIndex(index + 1);
      } else {
        setIndex(0);
      }
    } else {
      if (data && data[index - 1]) {
        setIndex(index - 1);
      } else {
        setIndex(data.length - 1);
      }
    }
  };

  const item = data && data.length && data[index];

  return (
    <div className={`dashboard-card dashboard-card--big bestSellers ${styleName}`}>
      <h1 className="dashboard-card--big_heading">{translate('best_sellers')}</h1>
      {item && (
        <div className="dashboard-card_best-sellers__item">
          <img src={item.image_url} className="dashboard-card_best-sellers_image" />
          <p className="dashboard-card_best-sellers_details_header">{item.orders} Orders</p>
          <div className="dashboard-card_best-sellers_details">
            <p className="dashboard-card_best-sellers_details_desc">{item.oitem_desc}</p>
            <div className="dashboard-card_best-sellers_details_arrows">
              <div className="dashboard-card_best-sellers_details_arrow" onClick={() => slider()}>
                <i className="zmdi zmdi-chevron-left" />
              </div>
              <div className="dashboard-card_best-sellers_details_arrow" onClick={() => slider(true)}>
                <i className="zmdi zmdi-chevron-right" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BestSellers;
