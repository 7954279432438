import React, { useState } from 'react';
import CardBox from 'components/CardBox/index';
import translate from '../../../../../appUtility/Translate';
import Modal from '../../../../../components/Modal/Modal';
import AttributesTable from './AttributesTable';
import { AttributeTypeConstantName } from './AttributeUtils';

const Attribute = ({ data, bulkRemove, getData }) => {
  const [show, setShow] = useState(false);
  const handleClick = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };

  return (
    <div className="d-flex justify-content-between">
      <div className="club-add-product-content">
        <Modal title="Add Atrribute" show={show} onClose={onClose}>
          <AttributesTable
            getData={getData}
            bulkRemove={bulkRemove}
            data={data}
            show={show}
            onClose={onClose}
            handleClick={handleClick}
          />
        </Modal>
        <CardBox heading={translate('Attributes')} styleName="col-12">
          <div className="jr-card-form" style={{ paddingTop: '20px' }}>
            {data
              .map(item => {
                switch (item.type.constantName) {
                  case AttributeTypeConstantName.TEXT: {
                    return (
                      <React.Fragment key={item.id}>
                        {item.label}
                        <input
                          readOnly={true}
                          defaultValue={((item.values || [])[0] || { value: undefined }).value}
                          autoComplete={'off'}
                          type={'text'}
                          className={'jr-card-input'}
                        />
                      </React.Fragment>
                    );
                  }
                  case AttributeTypeConstantName.NUMBER: {
                    return (
                      <React.Fragment key={item.id}>
                        {item.label}
                        <input
                          readOnly={true}
                          defaultValue={((item.values || [])[0] || { value: undefined }).value}
                          autoComplete={'off'}
                          type={'number'}
                          className={'jr-card-input'}
                        />
                      </React.Fragment>
                    );
                  }
                  case AttributeTypeConstantName.TEXTAREA: {
                    return (
                      <React.Fragment key={item.id}>
                        {item.label}
                        <textarea
                          readOnly={true}
                          autoComplete={'off'}
                          className={'jr-card-input jr-card-input--area'}
                        />
                      </React.Fragment>
                    );
                  }
                  case AttributeTypeConstantName.SELECT: {
                    return (
                      <React.Fragment key={item.id}>
                        {item.label}
                        <select
                          value={
                            (
                              (item.values || []).find(value => !!value.isDefault) || {
                                id: -1
                              }
                            ).id
                          }
                          onChange={event => event.preventDefault()}
                          className={'jr-card-input jr-card-select'}
                        >
                          {(item.values || []).map(value => (
                            <option key={value.id} value={value.id} selected={value.isDefault ? 'selected' : ''}>
                              {value.value}
                            </option>
                          ))}
                        </select>
                      </React.Fragment>
                    );
                  }
                  case AttributeTypeConstantName.MULTIPLE_SELECT: {
                    return (
                      <React.Fragment key={item.id}>
                        {item.label}
                        <select
                          value={
                            (
                              (item.values || []).find(value => !!value.isDefault) || {
                                id: -1
                              }
                            ).id
                          }
                          onChange={event => event.preventDefault()}
                          className={'jr-card-input jr-card-select'}
                        >
                          <option value="-1" />
                          {(item.values || []).map(value => (
                            <option key={value.id} value={value.id} selected={value.isDefault ? 'selected' : ''}>
                              {value.value}
                            </option>
                          ))}
                        </select>
                      </React.Fragment>
                    );
                  }
                  case AttributeTypeConstantName.RADIO: {
                    return (
                      <React.Fragment key={item.id}>
                        {item.label}
                        <div className={'jr-card-div'}>
                          {(item.values || []).map(value => (
                            <div key={value.id}>
                              <input
                                readOnly={true}
                                checked={!!value.isDefault}
                                key={value.id}
                                type={'radio'}
                                id={`${item.id}_radio_${value.id}`}
                                name={item.id + '_radio'}
                                value={value.id}
                              />
                              <label key={value.id + '_label'} htmlFor={`${item.id}_radio_${value.id}`}>
                                {value.value}
                              </label>
                            </div>
                          ))}
                        </div>
                      </React.Fragment>
                    );
                  }
                  case AttributeTypeConstantName.SWITCH: {
                    return (
                      <React.Fragment key={item.id}>
                        {item.label}
                        <div className={'jr-card-div'}>
                          <div style={{ left: 0, top: 0, paddingBottom: 5 }} className={'switcher'}>
                            <input
                              disabled={true}
                              type="checkbox"
                              checked={
                                (
                                  (item.values || [])[0] || {
                                    isDefault: false
                                  }
                                ).isDefault
                              }
                            />
                            <span className="slider round"></span>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  }
                  default: {
                    break;
                  }
                }
              })
              .map((item, index) => (
                <label key={index} className={'jr-card-label'}>
                  {item}
                </label>
              ))}
          </div>
        </CardBox>
      </div>
      <button className="add-new-product-button mr-4 mt-4" onClick={handleClick}>
        Add Attribute
      </button>
    </div>
  );
};

export default Attribute;
