import {
  CHANGE_DIRECTION,
  CHANGE_NAVIGATION_STYLE,
  DARK_THEME,
  DRAWER_TYPE,
  HORIZONTAL_MENU_POSITION,
  SWITCH_LANGUAGE,
  THEME_COLOR,
  TOGGLE_COLLAPSED_NAV,
  WINDOW_WIDTH,
  MENU_TYPE,
  SECONDARY_MENU_CONTENT,
  SAVE_TRANSLATIONS,
  TOP_MENU_STATUS,
  TOP_MENU_CONTENT
} from 'constants/ActionTypes';

export function toggleCollapsedNav(isNavCollapsed) {
  return { type: TOGGLE_COLLAPSED_NAV, isNavCollapsed };
}

export function setDrawerType(drawerType) {
  return { type: DRAWER_TYPE, drawerType };
}

export function setMenuType(menuType) {
  return { type: MENU_TYPE, menuType };
}

export function setTopMenuStatus(status) {
  console.log({ type: TOP_MENU_STATUS, status });
  return { type: TOP_MENU_STATUS, status };
}

export function setTopMenuContent(content) {
  return { type: TOP_MENU_CONTENT, content };
}

export function setSecondaryMenuContent(content) {
  return { type: SECONDARY_MENU_CONTENT, content };
}

export function updateWindowWidth(width) {
  return { type: WINDOW_WIDTH, width };
}

export function setThemeColor(color) {
  return { type: THEME_COLOR, color };
}

export function setDarkTheme() {
  return { type: DARK_THEME };
}

export function changeDirection() {
  return { type: CHANGE_DIRECTION };
}

export function changeNavigationStyle(layoutType) {
  return {
    type: CHANGE_NAVIGATION_STYLE,
    payload: layoutType
  };
}

export function setHorizontalMenuPosition(navigationPosition) {
  return {
    type: HORIZONTAL_MENU_POSITION,
    payload: navigationPosition
  };
}

export function switchLanguage(locale) {
  return {
    type: SWITCH_LANGUAGE,
    payload: locale
  };
}

export function getTranslation(translations) {
  return {
    type: SAVE_TRANSLATIONS,
    payload: translations
  };
}
