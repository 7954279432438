import { connect } from 'react-redux';

import User from '../../app/routes/Users/index';

import { FetchUsers, SetPage } from './user.action';

import {
  selectUsers,
  selectUsersError,
  selectUsersPending,
  selectDataLength,
  selectUsersTablePage
} from './user.selector';

const mapStateToProps = state => {
  return {
    Pending: selectUsersPending(state),
    ErrorMessage: selectUsersError(state),
    Users: selectUsers(state),
    dataLength: selectDataLength(state),
    page: selectUsersTablePage(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchUsers: searchText => dispatch(FetchUsers(searchText)),
    SetPage: page => dispatch(SetPage(page))
  };
};

const UserView = connect(mapStateToProps, mapDispatchToProps)(User);

export default UserView;
