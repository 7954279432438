import React from 'react';
// import TextInput from './TextInput';

export default function TextInputWithLabel({
  label,
  name,
  value,
  onChange,
  multiline,
  labelStyleName,
  inputStyleName,
  ...props
}) {
  return (
    <label className={`jr-card-label ${labelStyleName}`} htmlFor={name}>
      {label}
      {multiline ? (
        <textarea
          autoComplete="off"
          {...props}
          className={`jr-card-input jr-card-input--area ${inputStyleName}`}
          name={name}
          value={value}
          onChange={onChange}
        />
      ) : (
        <input
          type="text"
          autoComplete="off"
          {...props}
          className={`jr-card-input ${inputStyleName}`}
          name={name}
          value={value}
          onChange={onChange}
        />
      )}
    </label>
  );
}
