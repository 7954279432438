import {
  FetchCustomersPending,
  FetchCustomersSuccess,
  FetchCustomersError,
  SetCustomersPage,
  DeleteCustomerPending,
  DeleteCustomerSuccess,
  DeleteCustomerError,
  UploadCustomersFilePending,
  UploadCustomersFileError,
  BlockCustomerPending,
  BlockCustomerSuccess,
  BlockCustomerError
} from './customer.actionCreator';
import CustomerService from '../../services/Customer';

// Fetch Customers
export const FetchCustomers = data => {
  return async dispatch => {
    try {
      dispatch(FetchCustomersPending());

      const Customers = await CustomerService.fetchCustomers(data);
      dispatch(FetchCustomersSuccess(Customers));
    } catch (err) {
      dispatch(FetchCustomersError(err));
    }
  };
};
export const UploadCustomersFile = file => {
  return async dispatch => {
    try {
      dispatch(UploadCustomersFilePending());
      const Customers = await CustomerService.uploadCustomersFile(file);
      dispatch(FetchCustomersSuccess(Customers));
    } catch (err) {
      dispatch(UploadCustomersFileError(err));
    }
  };
};
export const BlockCustomer = id => {
  return async dispatch => {
    try {
      dispatch(BlockCustomerPending());

      const Customers = await CustomerService.blockCustomer(id);
      dispatch(BlockCustomerSuccess(id));
    } catch (err) {
      dispatch(BlockCustomerError(err));
    }
  };
};

// Delete Customer
export const DeleteCustomer = id => {
  return async dispatch => {
    try {
      dispatch(DeleteCustomerPending());

      const Customer = await CustomerService.deleteCustomer(id.id);

      dispatch(DeleteCustomerSuccess(id));
    } catch (err) {
      dispatch(DeleteCustomerError(err));
    }
  };
};

// Set Page
export const SetPage = page => {
  return async dispatch => {
    try {
      dispatch(SetCustomersPage(page));
    } catch (err) {
      console.error(err);
    }
  };
};
