import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Header from 'components/Header';
import General from './General';
import axios, { AppNodeAPI as AxiosNode } from '../../../../appUtility/Api';
import Description from './Description';
import Price from './Price';
import Images from './Images';
import Options from './Options';
import Attribute from './Attributes/Attributes';
import { connect } from 'react-redux';
import { getAttributesData, getAttributeTypesData } from '../../../../store/attributes/attributes.actionCreators';
import Tabs from './Tabs';
import validator from 'validator';
import translate from '../../../../appUtility/Translate';
import Loader from '../../../../appUtility/Loader';

class Index extends Component {
  state = {
    tabs: [],
    data: {
      name: '',
      price: '',
      category: '',
      product_url: '',
      old_price: '',
      description: '',
      store_id: '',
      currency: '',
      discount: '',
      images: [],
      options: [],
      attribute: '',
      show_in_countries: [],
      featured: false,
      blocked: false
    },
    variations: [],
    options: {},
    value: 0,
    stores: [],
    categories: [],
    currencies: [],
    activeTab: 'general',
    baseImage: null,
    errors: {},
    countries: [],
    show_in: [],
    hide_in: []
  };

  changePrice = event => {
    const data = { ...this.state.data };
    const attr = event.target.name;
    // console.log("target nameeeee", attr)
    const value = event.target.value;
    if (value !== '' && (!Number(value) || Number(value) < 0)) {
      return;
    }
    data[attr] = value;
    // console.log("dada", data)
    if (attr === 'price') {
      // console.log("attttttttr",attr)
      // console.log('valll',data.price)
      // let discount = parseFloat((100 - ((event.target.value / data.old_price) * 100)).toFixed(2));

      let discount = parseFloat((100 - (data.price / data.old_price) * 100).toFixed(2));

      discount = isNaN(discount) ? '' : discount;
      data['discount'] = discount;
    }

    if (attr === 'old_price') {
      let discount = parseFloat((100 - (data.price / data.old_price) * 100).toFixed(2));
      discount = isNaN(discount) ? '' : discount;
      data['discount'] = discount;
    }

    this.setState({ data });
  };
  changeDiscount = event => {
    const data = { ...this.state.data };
    const attr = event.target.name;
    const club_price = data.old_price - (data.old_price * event.target.value) / 100;
    data[attr] = event.target.value;
    data['price'] = club_price;
    this.setState({ data });
  };
  getBase64 = (file, cb) => {
    if (file instanceof Blob) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        cb(reader.result);
      };
      reader.onerror = function(error) {
        console.log('Error: ', error);
      };
    }
  };
  onDrop = picture => {
    const data = { ...this.state.data };
    data.images.push(picture);
    this.setState({ data });
  };
  // Remove Product Image
  removeProductImage = image => {
    const Data = { ...this.state.data };
    Data.images = image;
    this.setState({
      data: Data
    });
  };

  handleChange = event => {
    const data = { ...this.state.data };
    const attr = event.target.name;
    if (attr === 'blocked') {
      data.blocked = event.target.checked;
    } else {
      data[attr] = event.target.value;
    }
    this.setState({ data });
  };

  handleChangeFeatured = event => {
    const data = { ...this.state.data };
    const attr = event.target.name;
    if (attr === 'featured') {
      data.featured = event.target.checked;
    } else {
      data[attr] = event.target.value;
    }
    this.setState({ data });
  };

  handleCountries = country => {
    const { countries } = this.state;
    const indexOf = countries.indexOf(country);
    let cloned = [...countries];
    country.status = !country.status;

    cloned[indexOf] = country;
    this.setState({ countries: cloned });

    let data = { ...this.state.data };
    const exists =
      data.show_in_countries &&
      data.show_in_countries.filter(function(c) {
        return c === country.id;
      });
    if (exists.length > 0) {
      const index = data.show_in_countries.indexOf(exists[0]);
      data.show_in_countries.splice(index, 1);
    } else {
      data.show_in_countries.push(country.id);
    }

    this.setState({
      data
    });
  };
  // Add Variation
  handlePlus = () => {
    this.forceUpdate();
    let { variations } = { ...this.state };
    let variation = {
      size: '',
      color: '',
      attributes: { regularPrice: '', salePrice: '', image: '' },
      state: { loading: false }
    };
    variations.push(variation);
    this.setState({ variations });
  };

  // Edit Variation
  onVariationChange = (e, i) => {
    let { variations } = { ...this.state };
    let attr = e.target.name;

    if (attr in variations[i]['attributes']) {
      variations[i]['attributes'][attr] = e.target.value;
    } else {
      variations[i][attr] = e.target.value;
    }
    this.setState({ variations });
  };
  // Upload Variation Image
  uploadVariationImage = (e, index) => {
    let { variations } = { ...this.state };
    variations[index]['state']['loading'] = true;
    this.setState({ variations });
    fetch(axios.defaults.baseURL + '/app/club/variationImage', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({
        image: e,
        productId: this.props.match.params.id || localStorage.getItem('productId')
      })
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        // this.props.hideAuthLoader();
        let newVariations = [...this.state.variations];
        newVariations[index]['state']['loading'] = false;
        newVariations[index]['state']['variationId'] = data.id;
        newVariations[index]['attributes']['image'] = data.image;
        this.setState({ variations: newVariations });
      });
  };

  getOptions = () => {
    fetch(axios.defaults.baseURL + '/app/club/getoptions', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ productId: this.props.match.params.id || localStorage.getItem('productId') })
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        let variations = [];
        let newVariation;
        response.data.forEach(el => {
          newVariation = {
            size: el.size,
            color: el.color,
            attributes: { regularPrice: el.regularPrice, salePrice: el.salePrice, image: el.image },
            state: { loading: false }
          };
          variations.push(newVariation);
        });
        if (variations.length > 0) {
          this.setState({ variations, options: response.available_options });
        }
      });
  };

  addProduct = () => {
    fetch(axios.defaults.baseURL + '/app/club/addproduct', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        localStorage.setItem('productId', data.id);
        this.getOptions();

        this.getCountries();
      });
  };
  deleteVariationImage = index => {
    let { variations } = { ...this.state };
    variations[index]['state']['loading'] = false;
    variations[index]['attributes']['image'] = '';
    this.setState({ variations });
    fetch(axios.defaults.baseURL + '/app/club/deleteVariationImage', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ productId: this.props.match.params.id || localStorage.getItem('productId') })
    }).then(res => {
      return res.json();
    });
  };
  sendVariations = () => {
    let isValid = true;
    for (let item of this.state.variations) {
      isValid = this.validateVariations();
    }
    if (isValid) {
      const productId = this.props.match.params.id || localStorage.getItem('productId');
      const { variations } = { ...this.state };
      const feautured = this.state.data.featured;
      fetch(axios.defaults.baseURL + '/app/club/options', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify({ variations, productId, feautured })
      });
    } else {
      console.log('NOT VALID @', this.state.errors);
    }
  };

  validationRules = {
    name: value => {
      if (validator.isEmpty(value)) {
        return { status: true, message: translate('value_must_not_be_empty') };
      }
      return { status: false, message: '' };
    },
    description: value => {
      if (validator.isEmpty(value)) {
        return { status: true, message: translate('value_must_not_be_empty') };
      }
      return { status: false, message: '' };
    },
    price: value => {
      if (validator.isEmpty(value)) {
        return { status: true, message: translate('value_must_not_be_empty') };
      }
      if (!validator.isNumeric(value)) {
        return { status: true, message: translate('value_can_only_be_numeric') };
      }
      if (Number(value) > Number(this.state.data.old_price)) {
        return { status: true, message: translate('price_must_be_less_or_equal') };
      }
      return { status: false, message: '' };
    },
    old_price: value => {
      if (validator.isEmpty(value)) {
        return { status: true, message: translate('value_must_not_be_empty') };
      }
      if (!validator.isNumeric(value)) {
        return { status: true, message: translate('value_can_only_be_numeric') };
      }
      return { status: false, message: '' };
    }
  };

  optionsValidationRules = {
    size: value => {
      if (validator.isEmpty(value)) {
        return { status: true, message: translate('value_must_not_be_empty') };
      }
      return { status: false, message: '' };
    },
    color: value => {
      if (validator.isEmpty(value)) {
        return { status: true, message: translate('value_must_not_be_empty') };
      }
      return { status: false, message: '' };
    },
    salePrice: value => {
      if (validator.isEmpty(value)) {
        return { status: true, message: translate('value_must_not_be_empty') };
      }
      if (!validator.isNumeric(value)) {
        return { status: true, message: translate('value_can_only_be_numeric') };
      }
      const RegularPrice = Number(this.state.variations.find(item => item.attributes.salePrice === value));
      if (Number(value) >= RegularPrice && RegularPrice.attributes.regularPrice) {
        return { status: true, message: translate('price_must_be_less_or_equal') };
      }
      return { status: false, message: '' };
    },
    regularPrice: value => {
      if (validator.isEmpty(value)) {
        return { status: true, message: translate('value_must_not_be_empty') };
      }
      if (!validator.isNumeric(value)) {
        return { status: true, message: translate('value_can_only_be_numeric') };
      }
      return { status: false, message: '' };
    }
  };

  validate = (rules, data) => {
    const { errors } = { ...this.state };
    let isValid = true;
    for (let key in rules) {
      const result = rules[key](data[key].toString());
      errors[key] = result;
      isValid = result.status ? false : isValid;
    }
    this.setState({ errors });
    return isValid;
  };

  validateVariations = () => {
    const { variations, errors } = this.state;

    let isValid;

    if (variations.length === 0) {
      isValid = true;
    } else {
      variations.forEach(v => {
        isValid = true;
        errors.size = { status: false, message: translate('value_must_not_be_empty') };
        errors.color = { status: false, message: translate('value_must_not_be_empty') };
        var reg = /^[0-9]+$/;
        const RegularPrice = v.attributes.regularPrice && v.attributes.regularPrice.toString().match(reg);
        const SalePrice = v.attributes.salePrice && v.attributes.salePrice.toString().match(reg);

        if (v.size.length == 0 || v.size === undefined || v.size === null || v.size == false) {
          v.size = false;
          isValid = false;
          errors.size = { status: true, message: translate('value_must_not_be_empty') };
        }
        if (v.color.length == 0 || v.color === undefined || v.color === null || v.color == false) {
          v.color = false;
          isValid = false;
          errors.color = { status: true, message: translate('value_must_not_be_empty') };
        }

        if (!RegularPrice) {
          v.attributes.regularPrice = null;
          isValid = false;
          errors.size = { status: true, message: translate('value_must_not_be_empty') };
        } else if (
          (v.attributes.regularPrice && v.attributes.regularPrice.length == 0) ||
          v.attributes.regularPrice === undefined ||
          v.attributes.regularPrice === null ||
          v.attributes.regularPrice == false
        ) {
          v.attributes.regularPrice = false;

          isValid = false;
          errors.size = { status: true, message: translate('only_numbers_available') };
        }

        if (!SalePrice) {
          v.attributes.salePrice = null;
          isValid = false;
          errors.size = { status: true, message: translate('only_numbers_available') };
        } else if (
          (v.attributes.salePrice && v.attributes.salePrice.length == 0) ||
          v.attributes.salePrice === undefined ||
          v.attributes.salePrice === null ||
          v.attributes.salePrice == false
        ) {
          v.attributes.salePrice = false;

          isValid = false;
          errors.size = { status: true, message: translate('value_must_not_be_empty') };
        }
      });
    }

    return isValid;
  };

  saveProducts = () => {
    const isValid = this.validate(this.validationRules, this.state.data);
    let isValidOptions = true;

    isValidOptions = this.validateVariations();
    if (isValid && isValidOptions) {
      const data = { ...this.state.data };
      if (!data.category) {
        data.category =
          this.props.Categories && this.props.Categories.length > 0 ? this.props.Categories[0].category_id : 30;
      }
      this.setState({ loading: true });

      const id = this.props.match.params.id ? this.props.match.params.id : localStorage.getItem('productId');
      fetch(axios.defaults.baseURL + '/app/club/editproduct?id=' + id, {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify(data)
      }).then(res => {
        if (res.status === 401) {
          localStorage.removeItem('token');
          this.props.history.push('/');
          this.props.userSignOutSuccess();
          this.props.hideAuthLoader();
        } else if (res.status === 200) {
          // this.closeModal();

          this.setState({ loading: false });
          this.props.history.push('/app/club/catalog');
          // this.getProductData();
        }
      });
    } else {
      const { errors } = this.state;
      var i;
      const tabs = new Set([]);
      for (i in errors) {
        if (errors[i].status) {
          if (i == 'size' || i == 'color' || i == 'salePrice' || i == 'regularPrice') {
            tabs.add('options');
          }
          if (i === 'name') {
            tabs.add('general');
          }
          if (i === 'description') {
            tabs.add('description');
          }
          if (i === 'price' || i === 'old_price') {
            tabs.add('price');
          }
        }
      }

      var tabsArray = [...tabs];
      this.setState({ tabs: tabsArray, activeTab: tabsArray[0] });
    }
  };
  getCountries = async () => {
    fetch(axios.defaults.baseURL + '/shipping/retrievecountries', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(countries => {
        const { data } = this.state;

        const hideArray = [];

        countries.forEach(c => {
          data.show_in_countries &&
            data.show_in_countries.filter(e => {
              return c.id === e && hideArray.push(c);
            });
        });

        const showArray = countries.filter(c => {
          return hideArray && !hideArray.includes(c);
        });

        this.setState({
          countries,
          show_in: showArray,
          hide_in: hideArray
        });
      });
  };
  getSingleProduct = async id => {
    let data = { id };

    fetch(axios.defaults.baseURL + '/product/detailedproduct', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        return res.json();
      })
      .then(newData => {
        localStorage.setItem('productId', newData.product.id);

        const show_in = newData && newData.product && newData.product.show_in_countries;
        const { countries } = this.state;

        for (let i = 0; i < countries.length; i++) {
          for (let a = 0; a < show_in.length; a++) {
            if (countries[i].id === show_in[a]) {
              countries[i].status = false;
            }
          }
        }
        this.getOptions();
        this.setState({ data: newData.product }, () => this.getCountries());
      });
  };
  // componentWillUnmount() {
  //   this._isMounted = true;
  //
  // }

  async componentDidMount() {
    this.setState({
      currencies: [
        { id: 'USD', name: 'USD' },
        { id: 'UAH', name: 'UAH' },
        { id: 'ILS', name: 'ILS' }
      ]
    });

    this.getAttributeDataTypes();

    this.getAttributeData({
      sortColumn: 'createdAt',
      sortDirection: 'DESC'
    });

    const param = this.props.match.params.id;
    this.props.FetchCategories();
    this.getStores(param);
    this.getCategories();
    if (param) {
      await this.getSingleProduct(param);
      await this.getCountries();
    } else {
      this.addProduct();
    }

    // await this.getCountries();
  }

  getAttributeData = params => {
    return this.props.onGetAttributesData(params);
  };

  getAttributeDataTypes = () => {
    return this.props.onGetAttributeTypesData();
  };

  getCategories = isEdit => {
    fetch(axios.defaults.baseURL + '/app/club/admincategory', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        const categoriesNew = data.category.map(item => {
          return { id: item.category_id, name: item.name.en }; //TODO:localization
        });
        // const newData = !isEdit ? { ...this.state.data, category: categoriesNew[0].id } : this.state.data;
        // this.setState({ categories: categoriesNew, data: newData });
      });
  };
  getStores = isEdit => {
    fetch(axios.defaults.baseURL + '/app/club/storedata', {
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        const newStores = data.store.map(item => {
          return { id: item.store_id, name: item.store_name };
        });
        const newData = !isEdit ? { ...this.state.data, store_id: newStores[0].id } : this.state.data;
        this.setState({
          stores: newStores,
          storesData: data.store,
          data: newData
        });
      });
  };

  // Update Product

  UploaderType = e => {
    return typeof e !== 'string';
  };

  UpdateCroppedImage = (oldImage, NewImage) => {
    let data = { ...this.state.data };
    data.images[0] = oldImage;

    this.setState({ data });
  };

  // from show_in to hide_in
  removeCountry = e => {
    const { hide_in, show_in, data } = this.state;
    const index = show_in.indexOf(e);
    show_in.splice(index, 1);
    data.show_in_countries.push(e.id);
    hide_in.push(e);
    this.setState({
      hide_in,
      show_in,
      data
    });
  };

  // from hide_in to show_in
  addCountry = e => {
    const { hide_in, show_in, data } = this.state;
    const index = hide_in.indexOf(e);
    hide_in.splice(index, 1);

    const dataIndex = data.show_in_countries.indexOf(e.id);
    data.show_in_countries.splice(dataIndex, 1);

    show_in.push(e);

    this.setState({
      hide_in,
      show_in,
      data
    });
  };
  // Delete Variation
  onVariationDelete = (e, index) => {
    e.stopPropagation();
    const { variations } = this.state;
    variations.splice(index, 1);

    this.setState(pre => ({ ...pre, variations }));
  };

  bulkAttributeRemove = (ids) => new Promise(async resolve => {
    const deletedItems = await Promise.all(ids.map(id => AxiosNode.delete(`/attribute/${id}`)))
    resolve(deletedItems);
  })

  render() {
    const images = this.state.data.images;
    const { tabs, hide_in, show_in } = this.state;
    const { Categories, Pending } = this.props;
    const cat = Categories && Array.from(Categories).map(item => ({ ...item, id: item.category_id }));
    return (
      <div className="product-wizard-wrapper">
        <div className="product-wizard-container">
          <Tabs
            activeTab={this.state.activeTab}
            handleChange={tab => this.setState({ activeTab: tab })}
            handleClick={() => {
              this.sendVariations();
              this.saveProducts();
            }}
            tabs={tabs}
          />
          <Loader loading={Pending}>
            <div className="product-attributes">
              <Header newProduct={this.state.activeTab} />
              {this.state.activeTab === 'general' && (
                <General
                  handleChange={this.handleChange}
                  handleChangeFeatured={this.handleChangeFeatured}
                  stores={this.state.stores}
                  handleCountries={this.handleCountries}
                  id={this.props.match.params.id}
                  errors={this.state.errors}
                  data={this.state.data}
                  pending={Pending}
                  categories={cat}
                  country={this.state.countries}
                  removeCountry={this.removeCountry}
                  addCountry={this.addCountry}
                  hide_in={hide_in}
                  show_in={show_in}
                />
              )}
              {this.state.activeTab === 'description' && (
                <Description data={this.state.data} handleChange={this.handleChange} errors={this.state.errors} />
              )}
              {this.state.activeTab === 'price' && (
                <Price
                  data={this.state.data}
                  handleChange={this.handleChange}
                  currencies={this.state.currencies}
                  errors={this.state.errors}
                  changePrice={this.changePrice}
                  changeDiscount={this.changeDiscount}
                />
              )}
              {this.state.activeTab === 'images' && (
                <Images
                  data={images}
                  type={this.UploaderType(images)}
                  handleChange={this.onDrop}
                  removeProductImage={this.removeProductImage}
                  UpdateCroppedImage={this.UpdateCroppedImage}
                />
              )}
              {this.state.activeTab === 'options' && (
                <Options
                  data={this.state.data}
                  addImage={this.onDrop}
                  removeImage={this.removeProductImage}
                  variations={this.state.variations}
                  handleDeleteVariation={this.onVariationDelete}
                  handlePlus={this.handlePlus}
                  handleChange={this.onVariationChange}
                  uploadVariationImage={this.uploadVariationImage}
                  getBase64={this.getBase64}
                  deleteVariationImage={this.deleteVariationImage}
                  errors={this.state.errors}
                />
              )}
              {this.state.activeTab === 'attribute' && (
                <Attribute 
                  getData={this.getAttributeData}
                  bulkRemove={this.bulkAttributeRemove}
                  data={this.props.attributesData} 
                  handleChange={this.handleChange} 
                />
              )}
            </div>
          </Loader>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    attributesData: state.AttributesReducer.attributes
  };
};
const mapDispatchToProps = dispatch => {
  return {
    onGetAttributesData: params => dispatch(getAttributesData(params)),
    onGetAttributeTypesData: () => dispatch(getAttributeTypesData())
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
// export default Index;
