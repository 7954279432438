import React, { useEffect } from 'react';
import Header from 'components/Header/index';
import CategoryListing from './CategoryListing';
import CategorySidebar from './CategorySidebar';
import Loader from '../../../../appUtility/Loader';

export default function index(props) {
  useEffect(() => {
    props.FetchCategories();
  }, []);

  // console.log(props)

  return (
    <div className="category-wrapper">
      <CategorySidebar />
      <div className="category-container">
        <Header category="Manage Categories" />
        <Loader loading={props.Pending}>
          <CategoryListing categories={props.Categories} />
        </Loader>
      </div>
    </div>
  );
}
