// Fetching Orders
export const selectOrders = state => {
  return state.OrdersReducer.Orders;
};

// Data Length
export const selectDataLength = state => {
  return state.OrdersReducer.dataLength;
};

// Pending
export const selectOrdersPending = state => {
  return state.OrdersReducer.pending;
};
// Error
export const selectOrdersError = state => {
  return state.OrdersReducer.error;
};

// Page
export const selectOrdersPage = state => {
  return state.OrdersReducer.page;
};

// Countries
export const selectCountries = state => {
  return state.OrdersReducer.Countries;
};
