import * as types from './auth.actionTypes';

// Sign Up
export function SignUpPending() {
  return {
    type: types.SIGN_UP_PENDING
  };
}
export function SignUpSuccess(merchant) {
  return {
    type: types.SIGN_UP_SUCCESS,
    payload: merchant
  };
}
export function SignUpError(error) {
  return {
    type: types.SIGN_UP_ERROR,
    payload: error
  };
}

// Sign In
export function SignInPending() {
  return {
    type: types.SIGN_IN_PENDING
  };
}
export function SignInSuccess(merchant) {
  return {
    type: types.SIGN_IN_SUCCESS,
    payload: merchant
  };
}
export function SignInError(error) {
  return {
    type: types.SIGN_IN_ERROR,
    payload: error
  };
}

// Log Out
export function LogOutPending() {
  return {
    type: types.LOG_OUT_PENDING
  };
}
export function LogOutSuccess(merchant) {
  return {
    type: types.LOG_OUT_SUCCESS,
    payload: merchant
  };
}
export function LogOutError(error) {
  return {
    type: types.LOG_OUT_ERROR,
    payload: error
  };
}

// Reset Password Request
export function ResetPasswordPending() {
  return {
    type: types.RESET_PASSWORD_PENDING
  };
}
export function ResetPasswordSuccess(merchant) {
  return {
    type: types.RESET_PASSWORD_SUCCESS,
    payload: merchant
  };
}
export function ResetPasswordError(error) {
  return {
    type: types.RESET_PASSWORD_ERROR,
    payload: error
  };
}

// Reset Password Route Validation
export function ResetPasswordRoutePending() {
  return {
    type: types.CHECK_RESET_PASSWORD_ROUTE_PENDING
  };
}
export function ResetPasswordRouteSuccess(url) {
  return {
    type: types.CHECK_RESET_PASSWORD_ROUTE_SUCCESS,
    payload: url
  };
}
export function ResetPasswordRouteError(error) {
  return {
    type: types.CHECK_RESET_PASSWORD_ROUTE_ERROR,
    payload: error
  };
}

// Update Password
export function UpdatePasswordPending() {
  return {
    type: types.UPDATE_PASSWORD_PENDING
  };
}
export function UpdatePasswordSuccess(data) {
  return {
    type: types.UPDATE_PASSWORD_SUCCESS,
    payload: data
  };
}
export function UpdatePasswordError(error) {
  return {
    type: types.UPDATE_PASSWORD_ERROR,
    payload: error
  };
}
