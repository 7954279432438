import * as types from './customer.actionTypes';

// Fetching Customers
export function FetchCustomersPending() {
  return {
    type: types.FETCH_CUSTOMERS_PENDING
  };
}
export function FetchCustomersSuccess(merchants) {
  return {
    type: types.FETCH_CUSTOMERS_SUCCESS,
    payload: merchants
  };
}
export function FetchCustomersError(error) {
  return {
    type: types.FETCH_CUSTOMERS_ERROR,
    payload: error
  };
}
export function UploadCustomersFilePending() {
  return {
    type: types.UPLOAD_CUSTOMERS_FILE_PENDING
  };
}
export function UploadCustomersFileSuccess(merchants) {
  return {
    type: types.UPLOAD_CUSTOMERS_FILE_SUCCESS,
    payload: merchants
  };
}
export function UploadCustomersFileError(error) {
  return {
    type: types.UPLOAD_CUSTOMERS_FILE_ERROR,
    payload: error
  };
}
export function BlockCustomerPending() {
  return {
    type: types.BLOCK_CUSTOMER_PENDING
  };
}
export function BlockCustomerSuccess(id) {
  return {
    type: types.BLOCK_CUSTOMER_SUCCESS,
    payload: { id }
  };
}
export function BlockCustomerError(error) {
  return {
    type: types.BLOCK_CUSTOMER_ERROR,
    payload: error
  };
}

// Delete Customer
export function DeleteCustomerPending() {
  return {
    type: types.DELETE_CUSTOMERS_PENDING
  };
}
export function DeleteCustomerSuccess(id) {
  return {
    type: types.DELETE_CUSTOMERS_SUCCESS,
    payload: id
  };
}
export function DeleteCustomerError(error) {
  return {
    type: types.DELETE_CUSTOMERS_ERROR,
    payload: error
  };
}

// Set Customers Page
export function SetCustomersPage(page) {
  return {
    type: types.SET_CUSTOMERS_TABLE_PAGE,
    payload: page
  };
}
