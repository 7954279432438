import { connect } from 'react-redux';

import Catalog from '../../app/routes/Club/Catalog/index';

import { FetchCategories } from '../category/category.actions';
import { FetchMerchants } from '../merchant/merchant.actions';
import { FetchFilteredProducts, ToggleImporterModal, FetchSelectedProducts } from './catalog.action';

import { selectCategories } from '../category/category.selector';
import { selectMerchants } from '../merchant/merchant.selector';
import {
  selectProducts,
  selectProductsPending,
  selectProductsError,
  selectProductsCount,
  selectProductsPerPage
} from './catalog.selector';
import { selectAuthUserRole } from '../signUp/signup.selector';

const mapStateToProps = state => {
  return {
    Pending: selectProductsPending(state),
    ErrorMessage: selectProductsError(state),
    Products: selectProducts(state),
    Categories: selectCategories(state),
    Merchants: selectMerchants(state),
    UserRole: selectAuthUserRole(state),
    ProductsCount: selectProductsCount(state),
    PerPage: selectProductsPerPage(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchCategories: () => dispatch(FetchCategories()),
    FetchMerchants: () => dispatch(FetchMerchants()),
    FetchFilteredProducts: filter => dispatch(FetchFilteredProducts(filter)),
    ToggleImporterModal: () => dispatch(ToggleImporterModal()),
    FetchSelectedProducts: filter => dispatch(FetchSelectedProducts(filter))
  };
};

const CatalogView = connect(mapStateToProps, mapDispatchToProps)(Catalog);

export default CatalogView;
