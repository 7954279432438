import AppAPI from '../appUtility/Api';

class CustomerService {
  static fetchCustomers = data => {
    return AppAPI.post('/admin/customer/getcustomers', data).then(res => {
      return res.data;
    });
  };

  static uploadCustomersFile = data => {
    const payload = new FormData();
    for (let key in data) {
      payload.append(key, data[key]);
    }
    return AppAPI.post('/admin/customer/uploadcustomers', payload).then(res => {
      return res.data;
    });
  };

  static blockCustomer = id => {
    return AppAPI.post('/admin/customer/blockcustomer', JSON.stringify({ id })).then(res => {
      return res.data;
    });
  };

  static deleteCustomer = id => {
    return AppAPI.post('/admin/customer/deletecustomer', JSON.stringify({ id })).then(res => {
      return res.data;
    });
  };
}

export default CustomerService;
