import React from 'react';
import { connect } from 'react-redux';
import { setMenuType } from '../../actions/Setting';
import { CSSTransition } from 'react-transition-group';
import Drawer from '@material-ui/core/Drawer';
import './style.css';

const SecondaryMenu = props => {
  const { menuType, secondaryMenuContent, setMenuType, width } = props;

  const content = (
    <div className={`secondary-menu-wrapper ${menuType === 'secondary-menu' ? 'active' : ''}`}>
      {secondaryMenuContent}
    </div>
  );

  return width < 992 ? (
    <Drawer anchor="bottom" open={menuType === 'secondary-menu'} onClose={() => setMenuType(null)}>
      <div tabIndex={0} role="button" onClick={() => setMenuType(null)} onKeyDown={() => setMenuType(null)}>
        {content}
      </div>
    </Drawer>
  ) : (
    <CSSTransition
      in={menuType === 'secondary-menu'}
      timeout={{
        appear: 500,
        enter: 500,
        exit: 500
      }}
      classNames="secondary-menu"
      unmountOnExit
      // onEnter={() => setShowButton(false)}
      // onExited={() => setShowButton(true)}
    >
      {content}
    </CSSTransition>
  );
};

const mapStateToProps = ({ settings }) => {
  const { drawerType, menuType, secondaryMenuContent, width } = settings;
  return { drawerType, menuType, secondaryMenuContent, width };
};

const mapDispatchToProps = dispatch => {
  return {
    setMenuType: menu_type => dispatch(setMenuType(menu_type))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryMenu);
