import React, { useState } from 'react';
import { UploadIconSvg } from '../../../../../assets/svg/SvgIcons';
import UploadView from './UploadView';
import translate from '../../../../../appUtility/Translate';
import CropImage from './CropImage';
import CustomModal from '../../../../../components/Modal/Modal';
import axios from '../../../../../appUtility/Api';
import CircularProgress from '../../../../../components/CircularProgress';

// =============================
// Documentation
// #1 if type = false function is rendering single image
//
// =============================

const UploadImage = props => {
  const { data, handleChange, removeImage, type, UpdateCroppedImage } = props;

  const getBase64 = (file, cb) => {
    if (file instanceof Blob) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function() {
        cb(reader.result);
      };
      reader.onerror = function(error) {
        console.log('Error: ', error);
      };
    }
  };

  const onDrop = picture => {
    getBase64(picture, result => {
      handleChange(result);
    });
  };

  // Get Uploaded File
  const handleInputChange = e => {
    onDrop(e.target.files[0]);
    e.target.value = '';
  };

  // Remove Image
  const removeImageItem = image => {
    if (type) {
      const newArray = data.filter(i => i !== image);
      removeImage(newArray);
    } else {
      removeImage(false);
    }
  };

  const [imageUrl, setUrl] = useState(null);
  const [showModal, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [loader, setLoader] = useState(false);

  // Open Cropping Modal
  const show = async i => {
    setLoader(true);
    if (i[0].includes('data:image')) {
      setImage(i);
      setLoader(false);
    } else {
      setUrl(i);
      fetch(axios.defaults.baseURL + '/admin/imagetobase', {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: JSON.stringify({ image: i })
      })
        .then(res => {
          return res.json();
        })
        .then(response => {
          setImage(response.image);
          setLoader(false);
        });
    }
    await setShow(!showModal);
  };

  // Close CroppingModal
  const onClose = () => {
    setShow(!showModal);
  };

  // Save Cropped Image
  const saveCroppedImage = img => {
    UpdateCroppedImage(img);
    onClose();
  };

  return (
    <>
      <CustomModal show={showModal} onClose={onClose} title={'Crop Image'}>
        {loader ? <CircularProgress /> : <CropImage src={image} saveCroppedImage={saveCroppedImage} />}
      </CustomModal>
      <div className="image-upload-content">
        {/*Upload Images Input*/}
        <label htmlFor="imageUploadInput" className="image-upload_button">
          <UploadIconSvg />
          <span>{translate(`Upload ${type ? 'Images' : 'Image'}`)}</span>
        </label>
        <input type="file" onChange={handleInputChange} id="imageUploadInput" accept=".jpg, .gif, .png , .jpeg " />

        <div className="uploaded-images-container">
          {data && type
            ? data.map(i => {
                return (
                  <UploadView key={i} image={i} removeImageItem={() => removeImageItem(i)} handleCrop={() => show(i)} />
                );
              })
            : data && (
                // Single Upload
                <UploadView image={data} removeImageItem={() => removeImageItem(data)} handleCrop={() => show(data)} />
              )}
        </div>
      </div>
    </>
  );
};

export default UploadImage;
