import * as types from './user.actionTypes';

const initState = {
  Users: {},
  pending: false,
  error: null,
  dataLength: 0,
  page: 0
};

const UsersReducer = (state = initState, action) => {
  switch (action.type) {
    // Fetching Users
    case types.FETCH_USERS_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        pending: false,
        Users: action.payload.users,
        dataLength: action.payload.length
      };
    case types.FETCH_USERS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    // Set Page
    case types.SET_USERS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload
      };
    default:
      return state;
  }
};

export default UsersReducer;
