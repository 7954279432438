import React from 'react';
import { TextField } from '@material-ui/core';
const SearchBox = ({ onChange, value, onSubmit }) => {
  return (
    <div className={'search-bar right-side-icon bg-transparent'}>
      <TextField
        classes={{ root: 'search-input' }}
        margin="normal"
        variant="outlined"
        placeholder="Search..."
        onChange={onChange}
        onKeyDown={onChange}
        value={value}
      />
      <button className="search-icon" onClick={onSubmit}>
        <i className="material-icons">search</i>
      </button>
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: '',
  value: ''
};
