import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { COLLAPSED_DRAWER, FIXED_DRAWER, MINI_DRAWER, HORIZONTAL_NAVIGATION } from 'constants/ActionTypes';
import { switchLanguage, toggleCollapsedNav, getTranslation } from '../../actions/Setting';
import UserInfo from 'components/UserInfo';
import Notificationimg from '../../assets/images/navigation/not.png';
import TopMenu from '../TopMenu';
import { Button } from '@material-ui/core';
import axios from '../../appUtility/Api';
import LanguageMenu from './languageMenu';
import SearchBar from './searchBar';
import translate from '../../appUtility/Translate';
import DateRangePickerComp from './DateRangePickerComp';
import moment from 'moment';

class Header extends React.Component {
  onAppNotificationSelect = () => {
    this.setState({
      appNotification: !this.state.appNotification
    });
  };
  onMailNotificationSelect = () => {
    this.setState({
      mailNotification: !this.state.mailNotification
    });
  };
  onLangSwitcherSelect = event => {
    this.setState({
      langSwitcher: !this.state.langSwitcher,
      anchorEl: event.currentTarget
    });
  };
  onSearchBoxSelect = () => {
    this.setState({
      searchBox: !this.state.searchBox
    });
  };
  onAppsSelect = () => {
    this.setState({
      apps: !this.state.apps
    });
  };
  onUserInfoSelect = () => {
    this.setState({
      userInfo: !this.state.userInfo
    });
  };
  handleRequestClose = () => {
    this.setState({
      langSwitcher: false,
      userInfo: false,
      mailNotification: false,
      appNotification: false,
      searchBox: false,
      apps: false
    });
  };
  onToggleCollapsedNav = e => {
    const val = !this.props.navCollapsed;
    this.props.toggleCollapsedNav(val);
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      searchBox: false,
      searchText: '',
      mailNotification: false,
      userInfo: false,
      langSwitcher: false,
      appNotification: false
    };
  }

  updateSearchText(evt) {
    if (evt.keyCode === 13) {
      this.props.submitSearchBox();
    }
    this.setState({
      searchText: evt.target.value
    });
    this.props.getSearchBarValue(evt.target.value);
  }

  submitSearchBox = () => {
    this.props.submitSearchBox();
  };

  getTranslation = lang => {
    fetch(axios.defaults.baseURL + '/app/translate/index', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ lang: lang || 'en' })
    })
      .then(res => {
        return res.json();
      })
      .then(response => {
        const translations = response.data[lang || 'en'];
        localStorage.setItem('translations', JSON.stringify(translations));
        document.location.reload();
      });
  };

  render() {
    const { drawerType, navigationStyle, withDatePicker, startDate, endDate, onDateUpdate, styleName } = this.props;
    const drawerStyle =
      drawerType.includes(FIXED_DRAWER) || drawerType.includes(MINI_DRAWER)
        ? 'd-block d-xl-none'
        : drawerType.includes(COLLAPSED_DRAWER)
        ? 'd-block'
        : 'd-none';

    return (
      <div className={`app-main-header ${styleName}`}>
        <Toolbar className={`app-toolbar ${styleName}`} disableGutters={false}>
          <TopMenu />
          {navigationStyle === HORIZONTAL_NAVIGATION ? (
            <div className="d-block d-md-none pointer mr-3" onClick={this.onToggleCollapsedNav}>
              <span className="jr-menu-icon">
                <span className="menu-icon" />
              </span>
            </div>
          ) : (
            <IconButton
              className={`jr-menu-icon mr-3 ${drawerStyle}`}
              aria-label="Menu"
              onClick={this.onToggleCollapsedNav}
            >
              <span className="menu-icon" />
            </IconButton>
          )}

          {this.props.productsLength !== undefined && (
            <h2 className="headline-text" style={{ textTransform: 'capitalize' }}>
              {this.props.heading ||
              this.props.newProduct ||
              this.props.merchants ||
              this.props.category ||
              this.props.productsLength
                ? translate('All Categories') + ' (' + this.props.productsLength + ')'
                : translate('dashboard')}
            </h2>
          )}
          {withDatePicker && (
            <DateRangePickerComp startDate={startDate} endDate={endDate} onDateUpdate={onDateUpdate} />
          )}

          <div className="navigation-left-wrapper navigation-left-resp">
            {this.props.search && (
              <SearchBar
                updateSearchText={this.updateSearchText.bind(this)}
                value={this.state.searchText}
                onSubmit={this.submitSearchBox}
              />
            )}
            <Button classes={{ root: 'navigation-notification' }}>
              <img className="notification-img" src={Notificationimg} alt="notification"></img>
            </Button>

            <UserInfo history={this.props.history} />

            <LanguageMenu getTranslation={this.getTranslation} />
          </div>
        </Toolbar>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, locale, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, locale, navigationStyle, horizontalNavPosition };
};

export default withRouter(connect(mapStateToProps, { toggleCollapsedNav, switchLanguage, getTranslation })(Header));
