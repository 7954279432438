import React, { useState } from 'react';
import {DeleteOutline} from '@material-ui/icons';

import TextInputWithLabel from '../../../../../components/commonElements/TextInputWithLabel';

const CreateAttributeValue = ({ data, onClose, ...props }) => {
  return (
    <div className={'custom_attribute_value-item'}>
      <div className="d-flex">
        <TextInputWithLabel
          name="label"
          value={props.label}
          onChange={props.onTextChange}
        />
        <div className="atrribute-buttons">
          <button 
            onClick={props.cancel(props.index)}
            className="attribute-cancel-button">
            <DeleteOutline />
          </button>
        </div>
        <label className="switcher">
          <input 
            checked={props.isDefault}
            onChange={props.onSwitchChange}
            type="checkbox" name="isDefault" />
          <span className="slider round"></span>
        </label>
      </div>
    </div>
  );
};

export default CreateAttributeValue;
