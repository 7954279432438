import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Product extends Component {
  state = {
    checked: false
  };

  handleInputChange = id => {
    this.setState({ checked: !this.state.checked });
    this.props.handleDeleteItems(id);
  };

  componentDidUpdate() {
    if (!this.props.checkedArray.length && this.state.checked) {
      this.setState({ checked: !this.state.checked });
    }
  }

  render() {
    const isNotMerchant = Number(JSON.parse(localStorage.getItem('verificationData')).role) !== 2;
    const {
      name,
      old_price,
      price,
      discount,
      store_name,
      images,
      id,
      isOwnProducts,
      quickView,
      handleActiveItem,
      checkedItem
    } = this.props;

    return (
      <div
        className={`col-12 col-sm-6 col-md-4 col-lg-2 product-box ${quickView && 'hovered-product-box'}`}
        style={{ paddingLeft: '8px', paddingRight: '7px' }}
      >
        {quickView && (
          <div className="over-product-box">
            <button onClick={() => handleActiveItem(id)}>More Details</button>
          </div>
        )}
        <div className="product-select-button" onClick={() => this.handleInputChange(id)}>
          <input
            type="checkbox"
            checked={checkedItem.length > 0 && 'checked'}
            onChange={() => console.log('just for warning')}
          />
          <span className="checkmark"></span>
        </div>
        <Link
          to={`/app/club/product/wizard/${id}`}
          style={{ textDecoration: 'none' }}
          disabled={isNotMerchant && !isOwnProducts}
          className={isNotMerchant && !isOwnProducts ? 'disabled-link' : ''}
        >
          <div className={`club-product ${checkedItem.length && ' greenBordered'}`}>
            <div className="product-image">
              <img src={images[0]} alt=""></img>
            </div>
            <div className="product-description">
              <div className="product-domain">{store_name}</div>
              <div className="product-name">{name}</div>
              <div className="product-price">
                <span className="product-old-price">${old_price}</span>
                <span className="product-new-price">${price}</span>
                <div className="product-discount">- {Math.round(discount) || 0}%</div>
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}
