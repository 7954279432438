import { connect } from 'react-redux';

import Slider from '../../app/routes/Club/Slider/wizard';

import { FetchFilteredProducts } from '../catalog/catalog.action';

import {
  selectProducts,
  selectProductsPending,
  selectProductsError,
  selectProductsCount
} from '../catalog/catalog.selector';

const mapStateToProps = state => {
  return {
    Pending: selectProductsPending(state),
    ErrorMessage: selectProductsError(state),
    Products: selectProducts(state),
    ProductsCount: selectProductsCount(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchFilteredProducts: filter => dispatch(FetchFilteredProducts(filter))
  };
};

const SliderView = connect(mapStateToProps, mapDispatchToProps)(Slider);

export default SliderView;
