import * as types from './dashboard.actionTypes';

const initState = {
  data: {},
  pending: false,
  error: null
};

const DashboardReducer = (state = initState, action) => {
  switch (action.type) {
    // Fetching Data
    case types.FETCH_DASHBOARD_DATA_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.FETCH_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case types.FETCH_DASHBOARD_DATA_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export default DashboardReducer;
