import * as types from './catalog.actionTypes';

// Fetching Filtered Merchants

export function FetchFilteredProductsPending() {
  return {
    type: types.FETCH_FILTERED_PRODUCTS_PENDING
  };
}
export function FetchFilteredProductsSuccess(products) {
  return {
    type: types.FETCH_FILTERED_PRODUCTS_SUCCESS,
    payload: products
  };
}
export function FetchFilteredProductsError(error) {
  return {
    type: types.FETCH_FILTERED_PRODUCTS_ERROR,
    payload: error
  };
}

// Fetching Selected Products

export function SelectedProductsPending() {
  return {
    type: types.FETCH_SELECTED_PRODUCTS_PENDING
  };
}
export function SelectedProductsSuccess(products) {
  return {
    type: types.FETCH_SELECTED_PRODUCTS_SUCCESS,
    payload: products
  };
}
export function SelectedProductsError(error) {
  return {
    type: types.FETCH_SELECTED_PRODUCTS_ERROR,
    payload: error
  };
}

// Import Products

export function ImportProductsPending() {
  return {
    type: types.IMPORT_PRODUCTS_PENDING
  };
}
export function ImportProductsSuccess(products) {
  return {
    type: types.IMPORT_PRODUCTS_SUCCESS,
    payload: products
  };
}
export function ImportProductsError(error) {
  return {
    type: types.IMPORT_PRODUCTS_ERROR,
    payload: error
  };
}

// Import Retrieve Countries

export function ImportRetrieveCountriesPending() {
  return {
    type: types.IMPORT_RETRIEVE_COUNTRIES_PENDING
  };
}
export function ImportRetrieveCountriesSuccess(countries) {
  return {
    type: types.IMPORT_RETRIEVE_COUNTRIES_SUCCESS,
    payload: countries
  };
}
export function ImportRetrieveCountriesError(error) {
  return {
    type: types.IMPORT_RETRIEVE_COUNTRIES_ERROR,
    payload: error
  };
}
