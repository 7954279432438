import * as types from './order.actionTypes';

// Fetching Orders

export function FetchOrdersPending() {
  return {
    type: types.FETCH_ORDERS_PENDING
  };
}
export function FetchOrdersSuccess(orders) {
  return {
    type: types.FETCH_ORDERS_SUCCESS,
    payload: orders
  };
}
export function FetchOrdersError(error) {
  return {
    type: types.FETCH_ORDERS_ERROR,
    payload: error
  };
}

// Set Orders Page
export function SetOrdersPage(page) {
  return {
    type: types.SET_ORDERS_TABLE_PAGE,
    payload: page
  };
}

// Fetch Countries
export function FetchCountries(countries) {
  return {
    type: types.FETCH_COUNTRIES,
    payload: countries
  };
}
