import * as types from './signup.actionTypes';

// Add New Merchant
export function AddMerchantPending() {
  return {
    type: types.ADD_MERCHANT_PENDING
  };
}
export function AddMerchantSuccess(merchant) {
  return {
    type: types.ADD_MERCHANT_SUCCESS,
    payload: merchant
  };
}
export function AddMerchantError(error) {
  return {
    type: types.ADD_MERCHANT_ERROR,
    payload: error
  };
}
