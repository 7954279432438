import { connect } from 'react-redux';

import Order from '../../app/routes/Orders/index';

import { FetchOrders, SetPage, FetchCountriesAction } from './order.action';

import {
  selectOrders,
  selectOrdersError,
  selectOrdersPending,
  selectDataLength,
  selectOrdersPage,
  selectCountries
} from './order.selector';

const mapStateToProps = state => {
  return {
    Pending: selectOrdersPending(state),
    ErrorMessage: selectOrdersError(state),
    Orders: selectOrders(state),
    dataLength: selectDataLength(state),
    page: selectOrdersPage(state),
    Countries: selectCountries(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchOrders: (filter, page, rowsPerPage) => dispatch(FetchOrders(filter, page, rowsPerPage)),
    SetPage: page => dispatch(SetPage(page)),
    FetchCountriesAction: () => dispatch(FetchCountriesAction())
  };
};

const OrderView = connect(mapStateToProps, mapDispatchToProps)(Order);

export default OrderView;
