export default {
  palette: {
    primary: {
      light: '#a380ff',
      main: '#009ee3',
      dark: '#7647f3',
      contrastText: '#fff'
    },
    secondary: {
      light: '#69e8c9',
      main: '#37dfb6',
      dark: '#1ed8aa',
      contrastText: '#fff'
    }
  },
  status: {
    danger: 'orange'
  },
  typography: {
    button: {
      fontWeight: 400,
      textAlign: 'capitalize'
    }
  },
  shape: {
    borderRadius: 4
  }
};
