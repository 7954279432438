import React, { Component } from 'react';
import ImportProduct from './ImportProduct';
import { CircularProgress } from '@material-ui/core';
import SelectedItem from './SelectedItem';

class ImporterModal extends Component {
  state = {
    checkedArray: [],
    selectedItem: false,
    merchant: this.props.merchants && this.props.merchants.length ? this.props.merchants[0].store_id : '',
    category: this.props.categories && this.props.categories.length ? this.props.merchants[0].category_id : '',
    searchText: ''
  };

  // Select Product
  handleCheck = item => {
    const { checkedArray } = this.state;
    const match = checkedArray.find(i => i === item);

    if (match) {
      const index = checkedArray.indexOf(match);
      checkedArray.splice(index, 1);
    } else {
      checkedArray.push(item);
    }

    this.setState({
      checkedArray
    });
  };

  // Open Product
  handleActiveItem = item => {
    const { Products } = this.props;
    const Filtered = Products && Products.filter(i => i.id === item);
    this.setState({
      selectedItem: Filtered[0]
    });
  };

  // Close Modal
  handleCloseModal = () => {
    this.setState({ checkedArray: [], selectedItem: false });

    this.props.ToggleImporterModal();
  };

  // Filter Data
  handleChangeSelect = async e => {
    await this.setState({
      [e.target.name]: e.target.value
    });
    await this.props.FetchSelectedProducts({
      filters: {
        category: this.state.category,
        merchant: this.state.merchant,
        searchText: this.state.searchText
      }
    });
  };

  // Import Products
  importProducts = async () => {
    await this.props.ImportProducts(this.state.checkedArray);
    (await this.props.imported) && this.handleCloseModal();
  };

  // Clear Selected Items List
  clearSelected = () => {
    this.setState({ checkedArray: [], selectedItem: false });
  };

  // Render Button Text
  checkedOrNot = id => {
    const { checkedArray } = this.state;

    const result = checkedArray && checkedArray.length && checkedArray.filter(c => c === id);

    return result.length ? 'Remove From Selection' : 'Add to Selection';
  };

  removeSelected = () => {
    this.setState({ selectedItem: false });
  };

  render() {
    const { modal, Products, pending, merchants, categories } = this.props;
    const { checkedArray, selectedItem, searchText } = this.state;
    return (
      <>
        {modal && (
          <div className="import-modal">
            <div className="import-container">
              <div className="import-header">
                <div className="header-left">
                  <h3>Internity Club {Products && Products.length > 0 && Products.length}</h3>
                  {checkedArray.length ? (
                    <>
                      <button className="header-button clear" onClick={() => this.clearSelected()}>
                        Clear Selected
                      </button>
                      <button className="header-button import" onClick={() => this.importProducts()}>
                        Import {checkedArray.length} Products
                      </button>
                    </>
                  ) : (
                    ''
                  )}
                </div>
                <div className="header-right">
                  <div className="header-select">
                    <select
                      onChange={e => this.handleChangeSelect(e)}
                      className="browser-default custom-select modal-resp"
                      name="merchant"
                      style={{ width: '181px', height: '36px', borderRadius: '18px', background: '#ffffff' }}
                    >
                      {merchants &&
                        merchants.length > 0 &&
                        merchants.map(m => {
                          return (
                            <option
                              defaultValue={
                                merchants && merchants.length > 0 && merchants[0].store_id == m.store_id && m.store_id
                              }
                              key={m.store_id}
                              value={m.store_id}
                            >
                              {m.store_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="header-select">
                    <select
                      onChange={e => this.handleChangeSelect(e)}
                      className="browser-default custom-select modal-resp"
                      name="category"
                      style={{ width: '181px', height: '36px', borderRadius: '18px', background: '#ffffff' }}
                    >
                      <option value={''}>Categories</option>
                      {categories &&
                        categories.length > 0 &&
                        categories.map(m => {
                          return (
                            <option
                              defaultValue={
                                categories &&
                                categories.length > 0 &&
                                categories[0].category_id == m.category_id &&
                                m.category_id
                              }
                              key={m.category_id}
                              value={m.category_id}
                            >
                              {m.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  <div className="searchbar ">
                    <input
                      className="modal-resp"
                      type="text"
                      placeholder={'Search...'}
                      name="searchText"
                      value={searchText}
                      onChange={e => this.handleChangeSelect(e)}
                    />
                    <div className="over-input">
                      <i className="material-icons">search</i>
                    </div>
                  </div>
                  <div className="close-modal" onClick={this.handleCloseModal}>
                    X
                  </div>
                </div>
              </div>
              <div className="import-body">
                {pending ? (
                  <div className="progress-container" style={{ height: '100%' }}>
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    {Products &&
                      Products.length > 0 &&
                      Products.map((product, i) => {
                        return (
                          <ImportProduct
                            key={i}
                            {...product}
                            checkedItem={checkedArray.filter(c => c === product.id)}
                            handleDeleteItems={this.handleCheck}
                            checkedArray={checkedArray}
                            quickView={true}
                            handleActiveItem={this.handleActiveItem}
                          />
                        );
                      })}

                    {selectedItem && (
                      <SelectedItem
                        selectedItem={selectedItem}
                        handleCheck={this.handleCheck}
                        checkedOrNot={this.checkedOrNot}
                        removeSelected={this.removeSelected}
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default ImporterModal;
