import React from 'react';
import { create } from 'jss';
import rtl from 'jss-rtl';
// import JssProvider from 'react-jss/lib/JssProvider';
import { StylesProvider, jssPreset } from '@material-ui/styles';
// import {createGenerateClassName, jssPreset} from '@material-ui/core/styles';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

function RTL(props) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
}

export default RTL;
