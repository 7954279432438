import React, { Component } from 'react';
import CardBox from 'components/CardBox/index';
import translate from '../../../../appUtility/Translate';
import TextInputWithLabel from '../../../../components/commonElements/TextInputWithLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { CloseIconSvg, UploadIconSvg } from '../../../../assets/svg/SvgIcons';
import CircularProgress from '@material-ui/core/CircularProgress';

export default class Options extends Component {
  state = {
    variations: []
  };

  changeImage = (e, index) => {
    this.props.getBase64(e.target.files[0], r => {
      this.props.uploadVariationImage(r, index);
    });
  };

  render() {
    const {
      deleteVariationImage,
      handleChange,
      variations,
      handlePlus,
      errors,
      data,
      handleDeleteVariation
    } = this.props;
    const currencies = [['USD'], ['UAH'], ['ILS']];
    return (
      <div>
        <div className="club-add-product-content club-add-product-content--row">
          {variations.length > 0 &&
            variations.map((item, index) => {
              return (
                <CardBox
                  heading={translate('product_option')}
                  styleName="col-12 club-add-product-options__wrapper"
                  cardStyle="p-0"
                  key={index}
                >
                  <div>
                    <i
                      className="fas fa-trash-alt"
                      onClick={e => handleDeleteVariation(e, index)}
                      style={{ fontSize: '16px', color: 'rgba(0,0,0,0.54)' }}
                    ></i>
                  </div>
                  <div className="club-add-product-options__content">
                    <TextInputWithLabel
                      name="size"
                      readOnly={true}
                      disabled={true}
                      value={data.currency ? data.currency : 'USD'}
                      onChange={e => handleChange(e, index)}
                      label={translate('currency')}
                    />

                    <TextInputWithLabel
                      name="size"
                      value={item.size ? item.size : ''}
                      onChange={e => handleChange(e, index)}
                      label={translate('size')}
                    />
                    {item.size === false && <p className={'input-error'}>{translate('value_must_not_be_empty')}</p>}

                    <TextInputWithLabel
                      name="color"
                      value={item.color ? item.color : ''}
                      onChange={e => handleChange(e, index)}
                      label={translate('color')}
                    />
                    {item.color === false && <p className={'input-error'}>{translate('value_must_not_be_empty')}</p>}

                    <TextInputWithLabel
                      name="regularPrice"
                      value={item.attributes.regularPrice ? item.attributes.regularPrice : ''}
                      onChange={e => handleChange(e, index)}
                      label={translate('Regular Price')}
                    />
                    {item.attributes.regularPrice === false && (
                      <p className={'input-error'}>{translate('value_must_not_be_empty')}</p>
                    )}
                    {item.attributes.regularPrice === null && (
                      <p className={'input-error'}>{translate('only_numbers')}</p>
                    )}

                    <TextInputWithLabel
                      name="salePrice"
                      value={item.attributes.salePrice ? item.attributes.salePrice : ''}
                      onChange={e => handleChange(e, index)}
                      label={translate('Sale Price')}
                    />
                    {item.attributes.salePrice === false && (
                      <p className={'input-error'}>{translate('value_must_not_be_empty')}</p>
                    )}
                    {item.attributes.salePrice === null && <p className={'input-error'}>{translate('only_numbers')}</p>}

                    {/*Upload / Render Image*/}

                    {item.state.loading ? (
                      <div style={{ marginTop: '20px' }}>
                        <CircularProgress />{' '}
                      </div>
                    ) : item.attributes.image ? (
                      <div className="options-uploaded-imagebox">
                        <div className="options-remove-thumbnail" onClick={() => deleteVariationImage(index)}>
                          <CloseIconSvg />
                        </div>
                        <img
                          style={{ float: 'right' }}
                          alt={item.attributes.image}
                          src={item.attributes.image}
                          className="options-uploaded-thumbnail"
                        />
                      </div>
                    ) : (
                      <>
                        {/*Upload Images Input*/}
                        <div className="jr-card-label jr-card-label--small" style={{ marginTop: '20px' }}>
                          {translate('variation_image')}
                          <label
                            htmlFor="imageUploadInput"
                            style={{ width: '213px', float: 'right' }}
                            className="image-upload_button"
                          >
                            <UploadIconSvg />
                            <span> {translate('upload_image')} </span>
                          </label>
                          <input
                            type="file"
                            style={{ width: '213px', float: 'right' }}
                            onChange={e => this.changeImage(e, index)}
                            id="imageUploadInput"
                            name="image"
                            accept=".jpg, .gif, .png , .jpeg "
                          />
                        </div>
                      </>
                    )}
                  </div>
                </CardBox>
              );
            })}

          <CardBox
            // heading={translate('product_option')}
            styleName="col-12 club-add-product-options__wrapper club-add-product-options__wrapper--small"
            cardStyle="p-0"
          >
            <div className="club-add-product-options__content" onClick={handlePlus}>
              <AddBoxIcon className="club-add-product-options__add" />
            </div>
          </CardBox>
        </div>
      </div>
    );
  }
}
