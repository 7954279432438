import * as types from './translation.actionTypes';

// Fetching Translations
export function FetchTranslationsPending() {
  return {
    type: types.FETCH_TRANSLATIONS_PENDING
  };
}
export function FetchTranslationsSuccess(translations) {
  return {
    type: types.FETCH_TRANSLATIONS_SUCCESS,
    payload: translations
  };
}
export function FetchTranslationsError(error) {
  return {
    type: types.FETCH_TRANSLATIONS_ERROR,
    payload: error
  };
}

// Set Translations Page
export function SetTranslationsPage(page) {
  return {
    type: types.SET_TRANSLATIONS_TABLE_PAGE,
    payload: page
  };
}
