import * as types from './merchant.actionTypes';

const initState = {
  Merchants: {},
  pending: false,
  error: null,
  page: 0
};

const MerchantReducer = (state = initState, action) => {
  switch (action.type) {
    // Fetching Merchants
    case types.FETCH_MERCHANTS_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.FETCH_MERCHANTS_SUCCESS:
      return {
        ...state,
        pending: false,
        Merchants: action.payload.store
      };
    case types.FETCH_MERCHANTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    // Set Page
    case types.SET_MERCHANTS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload
      };

    default:
      return state;
  }
};

export default MerchantReducer;
