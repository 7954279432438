// Sign Up
export const selectAuthUser = state => {
  return state.AuthReducer.authUser;
};
export const selectAuthPending = state => {
  return state.AuthReducer.pending;
};
export const selectAuthAlertMessage = state => {
  return state.AuthReducer.alertMessage;
};
export const selectAuthAdminName = state => {
  return state.AuthReducer.admin_name;
};
export const selectAuthRole = state => {
  return Number(state.AuthReducer.role);
};

export const selectAuthError = state => {
  return state.AuthReducer.error;
};
export const selectAuthMessage = state => {
  return state.AuthReducer.showMessage;
};
export const selectNewPassRequest = state => {
  return state.AuthReducer.newPasswordSuccess;
};
export const selectPasswordRequestRoute = state => {
  return state.AuthReducer.ValidatepasswordResetRoute;
};
