import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import validator from 'validator';
import BG1 from '../Backgrounds/BG1';
import MuiPhoneNumber from 'material-ui-phone-number';
import { connect } from 'react-redux';
import { SignUpNewMerchant } from '../../store/auth/auth.actions';
import { selectAuthAdminName, selectAuthUser, selectAuthError } from '../../store/auth/auth.selector';
import Secured from '../../assets/images/sign-up/secured-1.png';
import SingleCheckout from '../../assets/images/sign-up/single_checkout.png';
import Extra from '../../assets/images/sign-up/extra.png';
import Manage from '../../assets/images/sign-up/manage.png';

class SignUp extends Component {
  state = {
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    firstname: '',
    lastname: '',
    company: '',
    phonenumber: '',
    website: '',
    errors: [],
    characterErrors: []
  };

  placeholderStyle = {
    // placeholder styles for UI material, used in TextField component' attribute 'InputLabelProps
    fontSize: '13px',
    color: '#c4c4c4'
  };

  validatePassword = password => {
    //validating password with following validations:
    let containsUppercase, containsLowercase, containsNumber, isCorrectLength; //set validation parameters for password
    // let format = /[!@#$%^&*(),.?":{}|<>]/; //regex validation for special symbols

    [...password].forEach(character => {
      if (validator.isLowercase(character)) containsLowercase = true; // check if password contains lowercase character
      if (validator.isUppercase(character) && !validator.isNumeric(character)) containsUppercase = true; //check if password contains uppercase character
      if (validator.isNumeric(character)) containsNumber = true; //check if password contains numbers
    });

    //if (format.test(password)) containsSymbols = true //check if password contains special symbols
    if (validator.isLength(password, { min: 8 })) isCorrectLength = true; //check if password is correct length

    if (containsLowercase && containsUppercase && containsNumber && isCorrectLength) {
      // if password pass all validation, return it
      return true;
    }
  };

  validateInputs = async () => {
    // validating signup form inputs
    let { email, firstname, lastname, company, phonenumber, website, password, passwordConfirm } = this.state;
    // if input value doesn't meet validation requirements, we update state with invalid input name.

    if (!validator.isEmail(email) || validator.isEmpty(email)) {
      // if email validation fails, set email error
      await this.setState(prevState => ({ errors: [...prevState.errors, 'email'] }));
    }
    if (!validator.isURL(website)) {
      await this.setState(prevState => ({ errors: [...prevState.errors, 'website'] }));
    }
    if (
      validator.isEmpty(passwordConfirm) ||
      !validator.equals(password, passwordConfirm) ||
      !this.validatePassword(password)
    ) {
      await this.setState(prevState => ({ errors: [...prevState.errors, 'passwordConfirm'] }));
    }
    if (validator.isEmpty(phonenumber) || !validator.isLength(phonenumber, { min: 6 })) {
      await this.setState(prevState => ({ errors: [...prevState.errors, 'phonenumber'] }));
    }
    if (validator.isEmpty(firstname) || !validator.isLength(firstname, { max: 100 })) {
      await this.setState(prevState => ({ errors: [...prevState.errors, 'firstname'] }));
    }
    if (!validator.isAlpha(firstname)) {
      await this.setState(prevState => ({ characterErrors: [...prevState.characterErrors, 'firstname'] }));
    }
    if (validator.isEmpty(lastname) || !validator.isLength(lastname, { max: 100 })) {
      await this.setState(prevState => ({ errors: [...prevState.errors, 'lastname'] }));
    }

    if (!validator.isAlpha(lastname)) {
      await this.setState(prevState => ({ characterErrors: [...prevState.characterErrors, 'lastname'] }));
    }

    if (validator.isEmpty(company)) {
      await this.setState(prevState => ({ errors: [...prevState.errors, 'company'] }));
    }

    //if password validation fails, set error for password
    !this.validatePassword(password) &&
      (await this.setState(prevState => ({ errors: [...prevState.errors, 'password'] })));

    validator.isEmpty(website) && (await this.setState(prevState => ({ errors: [...prevState.errors, 'website'] })));
  };

  componentDidUpdate() {
    if (this.props.authUser !== null) {
      this.props.history.push('/app/club/catalog');
    }
  }

  handleSubmitForm = async e => {
    e.preventDefault();
    this.setState({ errors: [] });
    this.setState({ characterErrors: [] });
    await this.validateInputs();
    const merchant = {
      first_name: this.state.firstname,
      last_name: this.state.lastname,
      company: this.state.company,
      email: this.state.email,
      password: this.state.password,
      website: this.state.website,
      phonenumber: this.state.phonenumber
    };
    if (this.state.errors.length === 0 && this.state.characterErrors.length == 0) {
      this.props.SignUpNewMerchant(merchant);
    }
  };

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  render() {
    const { firstname, lastname, company, website, password, email, errors, characterErrors } = this.state;
    const { loader, AuthError } = this.props;
    return (
      <>
        <div className="sign-up_layout bg-1">
          <div className="app-signup-main-content animated slideInLeftTiny animation-duration-3">
            <Button className="back-to-login" variant="contained">
              <Link to="/signin">
                {' '}
                <span>&lt;</span> Back to Login{' '}
              </Link>
            </Button>

            <div>
              <h1>Merchants Sign-up</h1>

              {/*<p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the . took a galley of type and scrambled.
            </p>*/}

              <div className="app-signup-form">
                <form onSubmit={this.handleSubmitForm}>
                  {/*Firstname / Lastname*/}

                  <div className="sign-up__input--wrapper">
                    {(errors.includes('firstname') || errors.includes('lastname')) && (
                      <span className="first-last-names__error">
                        First and last names are required and must be maximum 100 letters
                      </span>
                    )}

                    {(characterErrors.includes('firstname') || characterErrors.includes('lastname')) && (
                      <span className="first-last-names__error" style={{ display: 'block' }}>
                        Field is empty or contains invalid characters
                      </span>
                    )}

                    <div className="first-last-names__wrapper">
                      <TextField
                        classes={{ root: errors.includes('firstname') && 'input-error' }}
                        className="outlined-dense sign-up__firstname"
                        label="First Name"
                        InputLabelProps={{
                          style: this.placeholderStyle,
                          classes: { root: 'label1' }
                        }}
                        fullWidth
                        onChange={this.handleInputChange}
                        defaultValue={firstname}
                        name={'firstname'}
                        margin="dense"
                        variant="outlined"
                        type="text"
                      />

                      <TextField
                        classes={{ root: errors.includes('lastname') && 'input-error' }}
                        className="outlined-dense sign-up__lastname"
                        label="Last Name"
                        InputLabelProps={{
                          style: this.placeholderStyle
                        }}
                        fullWidth
                        onChange={this.handleInputChange}
                        name={'lastname'}
                        defaultValue={lastname}
                        margin="dense"
                        variant="outlined"
                        type="text"
                      />
                    </div>
                  </div>

                  {/*Company */}

                  <div className="sign-up__input--wrapper">
                    {errors.includes('company') && (
                      <span className="sign-up__error">
                        Company name is required and must contain only letters and numbers
                      </span>
                    )}
                    <TextField
                      classes={{ root: `sign-up__company--input ${errors.includes('company') && 'input-error'}` }}
                      className="outlined-dense"
                      label={'Company Name'}
                      InputLabelProps={{
                        style: this.placeholderStyle
                      }}
                      fullWidth
                      onChange={this.handleInputChange}
                      name={'company'}
                      defaultValue={company}
                      margin="dense"
                      variant="outlined"
                      type="text"
                    />
                  </div>

                  {/*Email*/}
                  <div className="sign-up__input--wrapper">
                    {errors.includes('email') && (
                      <span className="sign-up__error">Email Address is required and must be valid.</span>
                    )}
                    {AuthError && <span className="sign-up__error">{AuthError}</span>}
                    <TextField
                      classes={{ root: errors.includes('email') && 'input-error' }}
                      className="outlined-dense"
                      label="Email Address"
                      InputLabelProps={{
                        style: this.placeholderStyle
                      }}
                      fullWidth
                      onChange={this.handleInputChange}
                      name={'email'}
                      defaultValue={email}
                      margin="dense"
                      variant="outlined"
                      type="text"
                      inputProps={{ style: { boderColor: 'red' } }}
                    />
                  </div>

                  {/*Password*/}

                  <div className="sign-up__input--wrapper">
                    {errors.includes('password') && (
                      <span className="sign-up__error">
                        Password must be at least 8 characters, containing at least one lower case and upper case
                        letters, number and special symbols
                      </span>
                    )}
                    <TextField
                      classes={{ root: errors.includes('password') && 'input-error' }}
                      className="password-input outlined-dense sign-up__password"
                      label="Password"
                      InputLabelProps={{
                        style: this.placeholderStyle
                      }}
                      variant="outlined"
                      type="password"
                      fullWidth
                      onChange={this.handleInputChange}
                      name={'password'}
                      defaultValue={password}
                      margin="dense"
                    />
                  </div>

                  {/*Password Confirmation*/}

                  <div className="sign-up__input--wrapper">
                    {errors.includes('passwordConfirm') && (
                      <span className="sign-up__error">Passwords must match each other</span>
                    )}

                    <TextField
                      classes={{ root: errors.includes('passwordConfirm') && 'input-error' }}
                      className="password-input outlined-dense sign-up__password"
                      label="Confirm Password"
                      InputLabelProps={{
                        style: this.placeholderStyle
                      }}
                      variant="outlined"
                      type="password"
                      fullWidth
                      onChange={this.handleInputChange}
                      name={'passwordConfirm'}
                      defaultValue={password}
                      margin="dense"
                    />
                  </div>

                  {/*Phone Number */}

                  <div className="sign-up__input--wrapper">
                    <MuiPhoneNumber
                      label="Phone Number"
                      InputLabelProps={{
                        style: this.placeholderStyle
                      }}
                      defaultCountry={'us'}
                      disableAreaCodes
                      variant="outlined"
                      inputClass={
                        errors.includes('phonenumber')
                          ? 'input-error outlined-dense sign-up__phone--number'
                          : 'outlined-dense sign-up__phone--number'
                      }
                      onChange={value => this.setState({ phonenumber: value })}
                    />
                    {errors.includes('phonenumber') && (
                      <span className="sign-up__error">* Phone Number is Required</span>
                    )}
                    {errors.includes('phonenumber') && (
                      <span className="sign-up__error">* Please Enter Valid Number</span>
                    )}

                    {/* {errors.includes('phonenumber') && <span className='sign-up__error'>Phone number must be valid</span>} */}
                  </div>

                  {/*Website Address */}

                  <div className="sign-up__input--wrapper">
                    <TextField
                      classes={{ root: errors.includes('website') && 'input-error' }}
                      className="outlined-dense"
                      label="Website Address"
                      InputLabelProps={{
                        style: this.placeholderStyle
                      }}
                      fullWidth
                      onChange={this.handleInputChange}
                      name={'website'}
                      defaultValue={website}
                      margin="dense"
                      variant="outlined"
                      type="text"
                    />
                    {errors.includes('website') && <span className="sign-up__error">Website must be valid</span>}
                  </div>

                  <button className="signup-button" type="submit" variant="contained" color="primary">
                    Join Now!
                  </button>

                  <p className="sign-up__terms">
                    By signing up you agree to Internity&nbsp;
                    <span onClick={() => window.open('https://www.clalit.co.il/he/Pages/default.aspx', '_blank')}>
                      Terms &amp; Conditions.  
                    </span>
                  </p>
                </form>
              </div>

              {loader && (
                <div className="loader-view">
                  <CircularProgress />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    AdminName: selectAuthAdminName(state),
    authUser: selectAuthUser(state),
    AuthError: selectAuthError(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    SignUpNewMerchant: merchant => dispatch(SignUpNewMerchant(merchant))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
