import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

class CustomModal extends Component {
  render() {
    return (
      <Modal isOpen={this.props.show}>
        <ModalHeader>
          {this.props.title}
          <button type="button" className="close" aria-label="Close" onClick={() => this.props.onClose()}>
            <span aria-hidden="true">×</span>
          </button>
        </ModalHeader>
        <ModalBody>{this.props.children}</ModalBody>
      </Modal>
    );
  }
}

export default CustomModal;
