import AppAPI from '../appUtility/Api';

class UserService {
  static fetchUsers = searchText => {
    return AppAPI.post('/admin/getcoreusers', { searchText: searchText }).then(res => {
      return res.data;
    });
  };
}

export default UserService;
