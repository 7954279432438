import * as types from './order.actionTypes';

const initState = {
  Orders: {},
  pending: false,
  error: null,
  dataLength: 0,
  page: 0,
  Countries: {}
};

const OrdersReducer = (state = initState, action) => {
  switch (action.type) {
    // Fetching Orders
    case types.FETCH_ORDERS_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.FETCH_ORDERS_SUCCESS:
      return {
        ...state,
        pending: false,
        Orders: action.payload.orders,
        dataLength: action.payload.length
      };
    case types.FETCH_ORDERS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    // Countries
    case types.FETCH_COUNTRIES: {
      return {
        ...state,
        pending: false,
        Countries: action.payload.data
      };
    }

    // Set Page
    case types.SET_ORDERS_TABLE_PAGE:
      return {
        ...state,
        page: action.payload
      };

    default:
      return state;
  }
};

export default OrdersReducer;
