export const FETCH_CUSTOMERS_PENDING = 'FETCH_CUSTOMERS_PENDING';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR';

export const UPLOAD_CUSTOMERS_FILE_PENDING = 'UPLOAD_CUSTOMERS_FILE_PENDING';
export const UPLOAD_CUSTOMERS_FILE_SUCCESS = 'UPLOAD_CUSTOMERS_FILE_SUCCESS';
export const UPLOAD_CUSTOMERS_FILE_ERROR = 'UPLOAD_CUSTOMERS_FILE_ERROR';

export const BLOCK_CUSTOMER_PENDING = 'BLOCK_CUSTOMER_PENDING';
export const BLOCK_CUSTOMER_SUCCESS = 'BLOCK_CUSTOMER_SUCCESS';
export const BLOCK_CUSTOMER_ERROR = 'BLOCK_CUSTOMER_ERROR';

export const DELETE_CUSTOMERS_PENDING = 'DELETE_CUSTOMERS_PENDING';
export const DELETE_CUSTOMERS_SUCCESS = 'DELETE_CUSTOMERS_SUCCESS';
export const DELETE_CUSTOMERS_ERROR = 'DELETE_CUSTOMERS_ERROR';

export const SET_CUSTOMERS_TABLE_PAGE = 'SET_CUSTOMERS_TABLE_PAGE';
