import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { getTranslation } from './appUtility/Translate';
import configureStore, { history } from './store';
import './firebase/firebase';
import App from './containers/App';
export const store = configureStore();

const MainApp = () => {
  useEffect(() => {
    getTranslation();
  }, []);
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route path="/" component={App} />
        </Switch> 
      </Router>
    </Provider>
  );
};

export default MainApp;
