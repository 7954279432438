import {
  FetchMerchantsPending,
  FetchMerchantsSuccess,
  FetchMerchantsError,
  SetMerchantsPage
} from './merchant.actionCreator';
import MerchantService from '../../services/Merchant';

// Fetch Categpries
export const FetchMerchants = searchText => {
  return async dispatch => {
    try {
      dispatch(FetchMerchantsPending());

      const Merchant = await MerchantService.fetchMerchants(searchText);

      dispatch(FetchMerchantsSuccess(Merchant));

      if (Merchant.data.error) {
        dispatch(FetchMerchantsError(Merchant.data.error));
      }
    } catch (err) {
      dispatch(FetchMerchantsError(err));
    }
  };
};

// Set Page
export const SetPage = page => {
  return async dispatch => {
    try {
      dispatch(SetMerchantsPage(page));
    } catch (err) {
      console.error(err);
    }
  };
};
