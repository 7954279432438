import React from 'react';
import CardBox from 'components/CardBox/index';
import translate from '../../../../appUtility/Translate';
import SelectWithLabel from '../../../../components/commonElements/SelectWithLabel';
import TextInputWithLabel from '../../../../components/commonElements/TextInputWithLabel';

export default function Price({ data, handleChange, currencies, errors, changePrice, changeDiscount }) {
  // const discount = data.old_price && data.price && Math.round(100 - (100 * data.price) / data.old_price) + '%';
  // const club_price = data.price - ((data.price / data.discount) / 100);
  return (
    <div>
      <div className="club-add-product-content">
        <CardBox heading={translate('product_price')} styleName="col-12">
          <div className="jr-card-form">
            <SelectWithLabel
              data={currencies}
              label={translate('currency')}
              name="currency"
              value={data.currency}
              selectedValue={data.currency}
              onChange={handleChange}
            />
            <TextInputWithLabel
              name="old_price"
              value={data.old_price}
              onChange={changePrice}
              maxLength={6}
              label={translate('regular_price')}
            />
            {errors.old_price && errors.old_price.status && <p className={'input-error'}>{errors.old_price.message}</p>}

            <TextInputWithLabel
              name="price"
              value={data.price}
              onChange={changePrice}
              maxLength={6}
              label={translate('club_price')}
            />
            {errors.price && errors.price.status && <p className={'input-error'}>{errors.price.message}</p>}
            <div className="discount_container">
              <TextInputWithLabel
                name="discount"
                value={data.discount}
                onChange={changeDiscount}
                label={translate('discount')}
              />
              <span className="discount_percent">%</span>
            </div>
          </div>
        </CardBox>
      </div>
    </div>
  );
}
