import AppAPI from '../appUtility/Api';

class CategoryService {
  static fetchCategories = () => {
    return AppAPI.post('/app/club/admincategory').then(res => {
      return res.data;
    });
  };
}

export default CategoryService;
