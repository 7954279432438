import React from 'react';

// Upload
export const UploadIconSvg = () => {
  return (
    <>
      <svg viewBox="0 0 60 60" width="23.5" height="17.9" fill="#fff">
        <path
          d="M50.975,20.694c-0.527-9-7.946-16.194-16.891-16.194c-5.43,0-10.688,2.663-13.946,7.008
			c-0.074-0.039-0.153-0.065-0.228-0.102c-0.198-0.096-0.399-0.188-0.605-0.269c-0.115-0.045-0.23-0.086-0.346-0.127
			c-0.202-0.071-0.406-0.133-0.615-0.19c-0.116-0.031-0.231-0.063-0.349-0.09c-0.224-0.051-0.452-0.09-0.683-0.124
			c-0.102-0.015-0.202-0.035-0.305-0.047C16.677,10.523,16.341,10.5,16,10.5c-4.962,0-9,4.037-9,9c0,0.129,0.007,0.255,0.016,0.381
			C2.857,22.148,0,26.899,0,31.654C0,38.737,5.762,44.5,12.845,44.5H18c0.552,0,1-0.447,1-1s-0.448-1-1-1h-5.155
			C6.865,42.5,2,37.635,2,31.654c0-4.154,2.705-8.466,6.432-10.253L9,21.13V20.5c0-0.123,0.008-0.249,0.015-0.375l0.009-0.175
			l-0.012-0.188C9.007,19.675,9,19.588,9,19.5c0-3.859,3.14-7,7-7c0.309,0,0.614,0.027,0.917,0.067
			c0.078,0.01,0.155,0.023,0.232,0.036c0.268,0.044,0.532,0.102,0.792,0.177c0.034,0.01,0.069,0.016,0.102,0.026
			c0.286,0.087,0.565,0.198,0.838,0.322c0.069,0.031,0.137,0.065,0.205,0.099c0.242,0.119,0.479,0.251,0.707,0.399
			C21.72,14.875,23,17.039,23,19.5c0,0.553,0.448,1,1,1s1-0.447,1-1c0-2.754-1.246-5.219-3.2-6.871
			C24.666,8.879,29.388,6.5,34.084,6.5c7.744,0,14.178,6.135,14.848,13.887c-1.022-0.072-2.553-0.109-4.083,0.125
			c-0.546,0.083-0.921,0.593-0.838,1.139c0.075,0.495,0.501,0.85,0.987,0.85c0.05,0,0.101-0.004,0.152-0.012
			c2.224-0.336,4.543-0.021,4.684-0.002C54.49,23.372,58,27.661,58,32.472C58,38.001,53.501,42.5,47.972,42.5H44
			c-0.552,0-1,0.447-1,1s0.448,1,1,1h3.972C54.604,44.5,60,39.104,60,32.472C60,26.983,56.173,22.06,50.975,20.694z"
        />
        <path
          d="M31.708,30.794c-0.092-0.093-0.203-0.166-0.326-0.217c-0.244-0.101-0.52-0.101-0.764,0
			c-0.123,0.051-0.233,0.124-0.326,0.217l-7.999,7.999c-0.391,0.391-0.391,1.023,0,1.414C22.488,40.402,22.744,40.5,23,40.5
			s0.512-0.098,0.707-0.293L30,33.914V54.5c0,0.553,0.448,1,1,1s1-0.447,1-1V33.914l6.293,6.293C38.488,40.402,38.744,40.5,39,40.5
			s0.512-0.098,0.707-0.293c0.391-0.391,0.391-1.023,0-1.414L31.708,30.794z"
        />
      </svg>
    </>
  );
};

// Delete - Bin
export const DeleteIconSvg = () => {
  return (
    <svg width="20px" height="18px" viewBox="0 0 408.483 408.483" fill="#60596c">
      <path
        d="M87.748,388.784c0.461,11.01,9.521,19.699,20.539,19.699h191.911c11.018,0,20.078-8.689,20.539-19.699l13.705-289.316
			H74.043L87.748,388.784z M247.655,171.329c0-4.61,3.738-8.349,8.35-8.349h13.355c4.609,0,8.35,3.738,8.35,8.349v165.293
			c0,4.611-3.738,8.349-8.35,8.349h-13.355c-4.61,0-8.35-3.736-8.35-8.349V171.329z M189.216,171.329
			c0-4.61,3.738-8.349,8.349-8.349h13.355c4.609,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.737,8.349-8.349,8.349h-13.355
			c-4.61,0-8.349-3.736-8.349-8.349V171.329L189.216,171.329z M130.775,171.329c0-4.61,3.738-8.349,8.349-8.349h13.356
			c4.61,0,8.349,3.738,8.349,8.349v165.293c0,4.611-3.738,8.349-8.349,8.349h-13.356c-4.61,0-8.349-3.736-8.349-8.349V171.329z"
      />
      <path
        d="M343.567,21.043h-88.535V4.305c0-2.377-1.927-4.305-4.305-4.305h-92.971c-2.377,0-4.304,1.928-4.304,4.305v16.737H64.916
			c-7.125,0-12.9,5.776-12.9,12.901V74.47h304.451V33.944C356.467,26.819,350.692,21.043,343.567,21.043z"
      />
    </svg>
  );
};

// Move - Arrows
export const MoveIconSvg = () => {
  return (
    <svg width="20px" height="18px" fill="#60596c" viewBox="0 0 511.626 511.626">
      <path
        d="M506.199,242.968l-73.09-73.089c-3.614-3.617-7.898-5.424-12.848-5.424c-4.948,0-9.229,1.807-12.847,5.424
			c-3.613,3.619-5.424,7.902-5.424,12.85v36.547H292.355V109.641h36.549c4.948,0,9.232-1.809,12.847-5.424
			c3.614-3.617,5.421-7.896,5.421-12.847c0-4.952-1.807-9.235-5.421-12.851L268.66,5.429c-3.613-3.616-7.895-5.424-12.847-5.424
			c-4.952,0-9.232,1.809-12.85,5.424l-73.088,73.09c-3.618,3.619-5.424,7.902-5.424,12.851c0,4.946,1.807,9.229,5.424,12.847
			c3.619,3.615,7.898,5.424,12.85,5.424h36.545v109.636H109.636v-36.547c0-4.952-1.809-9.234-5.426-12.85
			c-3.619-3.617-7.902-5.424-12.85-5.424c-4.947,0-9.23,1.807-12.847,5.424L5.424,242.968C1.809,246.585,0,250.866,0,255.815
			s1.809,9.233,5.424,12.847l73.089,73.087c3.617,3.613,7.897,5.431,12.847,5.431c4.952,0,9.234-1.817,12.85-5.431
			c3.617-3.61,5.426-7.898,5.426-12.847v-36.549H219.27v109.636h-36.542c-4.952,0-9.235,1.811-12.851,5.424
			c-3.617,3.617-5.424,7.898-5.424,12.847s1.807,9.233,5.424,12.854l73.089,73.084c3.621,3.614,7.902,5.424,12.851,5.424
			c4.948,0,9.236-1.81,12.847-5.424l73.087-73.084c3.621-3.62,5.428-7.905,5.428-12.854s-1.807-9.229-5.428-12.847
			c-3.614-3.613-7.898-5.424-12.847-5.424h-36.542V292.356h109.633v36.553c0,4.948,1.807,9.232,5.42,12.847
			c3.621,3.613,7.905,5.428,12.854,5.428c4.944,0,9.226-1.814,12.847-5.428l73.087-73.091c3.617-3.617,5.424-7.901,5.424-12.85
			S509.82,246.585,506.199,242.968z"
      />
    </svg>
  );
};

// Youtube Logo
export const YoutubeIconSvg = () => {
  return (
    <svg width="20px" height="18px" fill="#60596c" viewBox="0 0 512 512">
      <path
        d="M490.24,113.92c-13.888-24.704-28.96-29.248-59.648-30.976C399.936,80.864,322.848,80,256.064,80
			c-66.912,0-144.032,0.864-174.656,2.912c-30.624,1.76-45.728,6.272-59.744,31.008C7.36,138.592,0,181.088,0,255.904
			C0,255.968,0,256,0,256c0,0.064,0,0.096,0,0.096v0.064c0,74.496,7.36,117.312,21.664,141.728
			c14.016,24.704,29.088,29.184,59.712,31.264C112.032,430.944,189.152,432,256.064,432c66.784,0,143.872-1.056,174.56-2.816
			c30.688-2.08,45.76-6.56,59.648-31.264C504.704,373.504,512,330.688,512,256.192c0,0,0-0.096,0-0.16c0,0,0-0.064,0-0.096
			C512,181.088,504.704,138.592,490.24,113.92z M192,352V160l160,96L192,352z"
      />
    </svg>
  );
};

// Crop
export const CropIconSvg = () => {
  return (
    <svg width="20px" height="18px" fill="#60596c" viewBox="0 0 512 512">
      <path
        d="M408,357h51V153c0-28.05-22.95-51-51-51H204v51h204V357z M153,408V0h-51v102H0v51h102v255c0,28.05,22.95,51,51,51h255v102
			h51V459h102v-51H153z"
      />
    </svg>
  );
};

// Close
export const CloseIconSvg = () => {
  return (
    <svg width="15px" height="15px" viewBox="0 0 95.939 95.939">
      <path
        d="M62.819,47.97l32.533-32.534c0.781-0.781,0.781-2.047,0-2.828L83.333,0.586C82.958,0.211,82.448,0,81.919,0
            c-0.53,0-1.039,0.211-1.414,0.586L47.97,33.121L15.435,0.586c-0.75-0.75-2.078-0.75-2.828,0L0.587,12.608
            c-0.781,0.781-0.781,2.047,0,2.828L33.121,47.97L0.587,80.504c-0.781,0.781-0.781,2.047,0,2.828l12.02,12.021
            c0.375,0.375,0.884,0.586,1.414,0.586c0.53,0,1.039-0.211,1.414-0.586L47.97,62.818l32.535,32.535
            c0.375,0.375,0.884,0.586,1.414,0.586c0.529,0,1.039-0.211,1.414-0.586l12.02-12.021c0.781-0.781,0.781-2.048,0-2.828L62.819,47.97
            z"
      />
    </svg>
  );
};

// Plus
export const PlusIconSvg = props => {
  return (
    <svg width="12.6" height="12.6" viewBox="0 0 357 357" fill={props.color}>
      <path d="M357,204H204v153h-51V204H0v-51h153V0h51v153h153V204z" />
    </svg>
  );
};

// Burger Menu
export const BurgerMenuIconSvg = props => {
  return (
    <svg width="15" height="13.5" viewBox="0 0 459 459" fill={props.color}>
      <path d="M0,382.5h459v-51H0V382.5z M0,255h459v-51H0V255z M0,76.5v51h459v-51H0z" />
    </svg>
  );
};

// Dots Menu
export const DotsMenuIconSvg = props => {
  return (
    <svg viewBox="0 0 512 512" width="15" height="12" fill={props.color}>
      <circle cx="256" cy="256" r="64" />
      <circle cx="256" cy="448" r="64" />
      <circle cx="256" cy="64" r="64" />
    </svg>
  );
};

// Sort
export const SortIconSvg = () => {
  return (
    <svg viewBox="0 0 60 60" width="20px" height="20px">
      <path
        d="M23.5,60c-0.552,0-1-0.448-1-1V21c0-0.552,0.448-1,1-1s1,0.448,1,1v38
		C24.5,59.552,24.052,60,23.5,60z"
      />
      <path
        d="M23.5,60c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l12-12
		c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-12,12C24.012,59.902,23.756,60,23.5,60z"
      />
      <path
        d="M23.5,60c-0.256,0-0.512-0.098-0.707-0.293l-12-12c-0.391-0.391-0.391-1.023,0-1.414
		s1.023-0.391,1.414,0l12,12c0.391,0.391,0.391,1.023,0,1.414C24.012,59.902,23.756,60,23.5,60z"
      />
      <path
        d="M23.5,60c-0.256,0-0.512-0.098-0.707-0.293l-12-12c-0.391-0.391-0.391-1.023,0-1.414
		s1.023-0.391,1.414,0l12,12c0.391,0.391,0.391,1.023,0,1.414C24.012,59.902,23.756,60,23.5,60z"
      />
      <path
        d="M36.5,40c-0.552,0-1-0.448-1-1V1c0-0.552,0.448-1,1-1s1,0.448,1,1v38C37.5,39.552,37.052,40,36.5,40
		z"
      />
      <path
        d="M24.5,14c-0.256,0-0.512-0.098-0.707-0.293c-0.391-0.391-0.391-1.023,0-1.414l12-12
		c0.391-0.391,1.023-0.391,1.414,0s0.391,1.023,0,1.414l-12,12C25.012,13.902,24.756,14,24.5,14z"
      />
      <path
        d="M48.5,14c-0.256,0-0.512-0.098-0.707-0.293l-12-12c-0.391-0.391-0.391-1.023,0-1.414
		s1.023-0.391,1.414,0l12,12c0.391,0.391,0.391,1.023,0,1.414C49.012,13.902,48.756,14,48.5,14z"
      />
      <path
        d="M48.5,14c-0.256,0-0.512-0.098-0.707-0.293l-12-12c-0.391-0.391-0.391-1.023,0-1.414
		s1.023-0.391,1.414,0l12,12c0.391,0.391,0.391,1.023,0,1.414C49.012,13.902,48.756,14,48.5,14z"
      />
    </svg>
  );
};

// Reload
export const ReloadIconSvg = () => {
  return (
    <svg viewBox="0 0 467.871 467.871" width="20" height="20" fill="#272528">
      <path
        d="M392.098,344.131c-6.597-5.014-16.007-3.729-21.019,2.868c-0.962,1.266-1.948,2.516-2.957,3.751
		c-15.046,18.411-35.315,33.36-60.321,44.474c-0.124,0.055-0.247,0.111-0.369,0.17c-39.456,18.831-85.618,21.405-129.896,7.274
		c-38.875-12.657-70.505-37.162-94.017-72.837c-17.462-27.997-26.613-58.428-27.264-90.524c-0.016-0.781-0.015-1.564-0.021-2.346
		h18.402c6.919,0,10.384-8.365,5.491-13.257L46.7,190.277c-3.033-3.033-7.95-3.033-10.983,0L2.29,223.704
		c-4.892,4.892-1.427,13.257,5.491,13.257H26.21c0.24,38.722,10.983,75.351,31.963,108.92c0.062,0.099,0.125,0.196,0.188,0.294
		c27.356,41.581,64.327,70.186,109.971,85.046c21.87,6.979,44.152,10.447,66.102,10.447c29.833-0.001,59.045-6.407,85.737-19.113
		c31.267-13.929,56.432-33.243,74.793-57.405C399.977,358.554,398.693,349.144,392.098,344.131z"
      />
      <path
        d="M460.09,233.876h-18.428c-0.001-4.112-0.123-8.271-0.364-12.362c-1.913-32.411-11.568-64.326-27.921-92.295
		c-15.945-27.271-38.292-50.932-64.626-68.426c-26.774-17.787-57.774-29.226-89.649-33.079
		c-35.426-4.283-71.452,0.578-104.185,14.052c-32.1,13.213-60.653,34.522-82.572,61.623c-5.21,6.44-4.211,15.886,2.229,21.096
		c6.442,5.209,15.886,4.211,21.096-2.23c1.12-1.385,2.264-2.75,3.424-4.1c18.274-21.253,41.418-38.016,67.242-48.646
		c27.995-11.523,58.83-15.678,89.166-12.011c27.25,3.294,53.754,13.074,76.648,28.284c22.546,14.979,41.679,35.234,55.329,58.58
		c13.98,23.91,22.235,51.2,23.871,78.92c0.104,1.763,0.182,3.54,0.235,5.32c0.052,1.76,0.077,3.522,0.078,5.275h-18.426
		c-6.919,0-10.384,8.365-5.491,13.257l33.427,33.427c3.033,3.033,7.95,3.033,10.983,0l33.427-33.427
		C470.473,242.241,467.008,233.876,460.09,233.876z"
      />
    </svg>
  );
};

// Arrow
export const ArrowIncoSvg = () => {
  return (
    <svg x="0px" y="0px" viewBox="0 0 240.823 240.823" width="10" height="10">
      <path
        id="Chevron_Right"
        d="M57.633,129.007L165.93,237.268c4.752,4.74,12.451,4.74,17.215,0c4.752-4.74,4.752-12.439,0-17.179
		l-99.707-99.671l99.695-99.671c4.752-4.74,4.752-12.439,0-17.191c-4.752-4.74-12.463-4.74-17.215,0L57.621,111.816
		C52.942,116.507,52.942,124.327,57.633,129.007z"
      />
    </svg>
  );
};
