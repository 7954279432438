import * as types from './signup.actionTypes';

const initState = {
  Merchant: {},
  pending: false,
  error: null,
  authUser: localStorage.getItem('token')
};

const merchantReducer = (state = initState, action) => {
  switch (action.type) {
    // Add New Merchant
    case types.ADD_MERCHANT_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.ADD_MERCHANT_SUCCESS:
      if (action.payload.token) {
        localStorage.setItem('token', JSON.stringify(action.payload.token));
      }
      if (action.payload.admin_name) {
        localStorage.setItem('admin_name', JSON.stringify(action.payload.admin_name));
      }

      return {
        ...state,
        pending: false,
        authUser: action.payload.token
      };
    case types.ADD_MERCHANT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    default:
      return state;
  }
};

export default merchantReducer;
