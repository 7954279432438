import React, { useState, useEffect } from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
  Surface,
  Symbols
} from 'recharts';

const AcountActivation = props => {
  return (
    <div className="card activity-chart shadow text-center">
      <div className="card-header"></div>
      <div className="px-3 pb-3 pt-0">
        <ResponsiveContainer width="100%" height={300}>
          <AreaChart margin={{ top: 0, right: 0, left: 0, bottom: 0 }}> </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default AcountActivation;
