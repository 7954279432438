import React from 'react';
import Header from 'components/Header';
import DataTable from './OrderTable';
import Catalog from './Catalog';

class userTable extends React.Component {
  state = {
    order: 'asc',
    orderBy: 'calories',
    page: 0,
    rowsPerPage: 10,
    source: '',
    status: '',
    store: '',
    searchText: '',
    filter: {}
  };

  componentDidMount() {
    this.props.FetchOrders(null, this.state.page, this.state.rowsPerPage);
  }

  handleChangePage = (event, page) => {
    this.props.SetPage(page);
    this.props.FetchOrders(this.state.filter, page, this.state.rowsPerPage);
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
    this.props.FetchOrdersthis.props.FetchOrdersthis.props.FetchOrders(
      this.state.filter,
      this.state.page,
      event.target.value
    );
  };

  handleFilterData = filter => {
    this.setState({ filter });
    this.props.FetchOrders(filter, this.state.page, this.state.rowsPerPage);
  };

  getSearchBarValue = e => {
    this.setState({
      searchText: e
    });
  };

  submitSearchBox = () => {
    this.props.SetPage(0);
    const filter = {
      source: this.state.source,
      status: this.state.status,
      store: this.state.store,
      searchText: this.state.searchText || ''
    };
    this.props.FetchOrders(filter, this.state.page, this.state.rowsPerPage);
  };

  filterValues = e => {
    this.setState({
      e: e.value
    });
  };

  render() {
    const { Orders, Pending, dataLength, page } = this.props;
    const { rowsPerPage, order, orderBy, searchText } = this.state;
    return (
      <div className="orders-wrapper">
        <Catalog handleFilterData={this.handleFilterData} searchText={searchText} filterValues={this.filterValues} />
        <div className="orders-container">
          <Header
            merchants="orders"
            getSearchBarValue={this.getSearchBarValue}
            submitSearchBox={this.submitSearchBox}
            search={true}
          />
          <DataTable
            fetchOrders={this.props.FetchOrders}
            data={Orders}
            pending={Pending}
            page={page}
            rowsPerPage={rowsPerPage}
            dataLength={dataLength}
            orderBy={orderBy}
            order={order}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

export default userTable;
