import AppAPI from '../appUtility/Api';

class TranslationService {
  static fetchTranslations = searchText => {
    return AppAPI.post('/app/translate/index', { searchText: searchText }).then(res => {
      return res.data;
    });
  };
}

export default TranslationService;
