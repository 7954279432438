import * as types from './category.actionTypes';

// Fetching Categories
export function FetchCategoriesPending() {
  return {
    type: types.FETCH_CATEGORIES_PENDING
  };
}
export function FetchCategoriesSuccess(categories) {
  return {
    type: types.FETCH_CATEGORIES_SUCCESS,
    payload: categories
  };
}
export function FetchCategoriesError(error) {
  return {
    type: types.FETCH_CATEGORIES_ERROR,
    payload: error
  };
}
