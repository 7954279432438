import React from 'react';
import { connect } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import LanguageSwitcher from '../LanguageSwitcher';

const buttonStyle = {
  border: 'none',
  borderRadius: '50%',
  width: '37px',
  height: '25px',
  background: 'none'
};
class LanguageMenu extends React.Component {
  state = {
    anchorEl: null,
    open: false
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <div>
        <div onClick={this.handleClick} className="d-flex justify-content-center align-items-center">
          <button style={buttonStyle}>
            <i
              style={{ backgroundImage: 'none' }}
              className={`flag flag-24 flag-${localStorage.getItem('lang') || this.props.locale.icon}`}
            />
          </button>
        </div>
        <Menu
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleRequestClose}
          PaperProps={{
            style: {
              minWidth: 120,
              paddingTop: 0,
              paddingBottom: 0,
              marginTop: '50px',
              height: '133px',
              overflow: 'hidden'
            }
          }}
        >
          <LanguageSwitcher
            style={{ position: 'fixed!important' }}
            switchLanguage={e => {
              localStorage.setItem('lang', e.locale);
              this.props.getTranslation(e.locale);
            }}
            handleRequestClose={this.handleRequestClose}
          />
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale };
};
export default connect(mapStateToProps)(LanguageMenu);
