import * as types from './catalog.actionTypes';

const initState = {
  Products: {},
  pending: false,
  error: null,
  productsCount: 0,
  PerPage: 0,
  ImporterModal: false,
  SelectedProducts: {},
  imported: false,
  Countries: []
};

const ProductReducer = (state = initState, action) => {
  switch (action.type) {
    // Fetching Products
    case types.FETCH_FILTERED_PRODUCTS_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.FETCH_FILTERED_PRODUCTS_SUCCESS:
      return {
        ...state,
        pending: false,
        Products: action.payload.products,
        productsCount: action.payload.length,
        PerPage: action.payload.perPage
      };
    case types.FETCH_FILTERED_PRODUCTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    // Fetching Selected Products
    case types.FETCH_SELECTED_PRODUCTS_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.FETCH_SELECTED_PRODUCTS_SUCCESS:
      return {
        ...state,
        pending: false,
        SelectedProducts: action.payload.products
      };
    case types.FETCH_SELECTED_PRODUCTS_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    // Import Products
    case types.IMPORT_PRODUCTS_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.IMPORT_PRODUCTS_SUCCESS:
      return {
        ...state,
        pending: false,
        imported: true
      };
    case types.IMPORT_PRODUCTS_ERROR:
      return {
        ...state,
        pending: false,
        imported: true,
        error: action.payload.error
      };

    // Import Countries
    case types.IMPORT_RETRIEVE_COUNTRIES_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.IMPORT_RETRIEVE_COUNTRIES_SUCCESS:
      return {
        ...state,
        pending: false,
        Countries: action.payload
      };
    case types.IMPORT_RETRIEVE_COUNTRIES_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    case types.TOGGLE_IMPORTER_MODAL:
      return {
        ...state,
        ImporterModal: !state.ImporterModal
      };
    default:
      return state;
  }
};

export default ProductReducer;
