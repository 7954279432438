// Fetching Dashboard Data
import * as types from './dashboard.actionTypes';

export function FetchDashboardDataPending() {
  return {
    type: types.FETCH_DASHBOARD_DATA_PENDING
  };
}
export function FetchDashboardDataSuccess(data) {
  return {
    type: types.FETCH_DASHBOARD_DATA_SUCCESS,
    payload: data
  };
}
export function FetchDashboardDataError(error) {
  return {
    type: types.FETCH_DASHBOARD_DATA_ERROR,
    payload: error
  };
}
export function FetchDashboardMapDataPending() {
  return {
    type: types.FETCH_DASHBOARD_MAP_DATA_PENDING
  };
}
export function FetchDashboardMapDataSuccess(data) {
  return {
    type: types.FETCH_DASHBOARD_MAP_DATA_SUCCESS,
    payload: data
  };
}
export function FetchDashboardMapDataError(error) {
  return {
    type: types.FETCH_DASHBOARD_MAP_DATA_ERROR,
    payload: error
  };
}
