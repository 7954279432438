import AppAPI from '../appUtility/Api';

class MerchantService {
  static fetchMerchants = searchText => {
    return AppAPI.post('/app/club/storedata', { searchText: searchText }).then(res => {
      return res.data;
      console.log(res.data);
    });
  };
}

export default MerchantService;
