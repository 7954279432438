import axios from 'axios';
import AppAPI from '../appUtility/Api';
class DashboardService {
  static fetchDashboardData = data => {
    return axios
      .post(
        'https://clalit-nest.webiz.ge/dashboard/statistics',
        {
          headers: {
            'Access-Control-Allow-Origin': 'https://clalit.webiz.ge',
            'Access-Control-Allow-Headers': '*',
            'Access-Control-Allow-Methods': 'POST'
          }
        },
        data
      )
      .then(res => {
        // // return axios.post('http://10.20.0.18:3001/dashboard/statistics', data).then(res => {
        return res.data;
      });
  };
}

export default DashboardService;
