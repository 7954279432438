export const SIGN_UP_PENDING = 'SIGN_UP_PENDING';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR';

export const SIGN_IN_PENDING = 'SIGN_IN_PENDING';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const LOG_OUT_PENDING = 'LOG_OUT_PENDING';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';
export const LOG_OUT_ERROR = 'LOG_OUT_ERROR';

export const RESET_PASSWORD_PENDING = 'RESET_PASSWORD_PENDING';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

export const CHECK_RESET_PASSWORD_ROUTE_PENDING = 'CHECK_RESET_PASSWORD_ROUTE_PENDING';
export const CHECK_RESET_PASSWORD_ROUTE_SUCCESS = 'CHECK_RESET_PASSWORD_ROUTE_SUCCESS';
export const CHECK_RESET_PASSWORD_ROUTE_ERROR = 'CHECK_RESET_PASSWORD_ROUTE_ERROR';

export const UPDATE_PASSWORD_PENDING = 'UPDATE_PASSWORD_PENDING';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
