import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

const Loader = props => {
  return props.loading ? (
    <div className="progress-container">
      <CircularProgress />
    </div>
  ) : (
    props.children
  );
};

Loader.propTypes = {
  loading: PropTypes.bool
};

export default Loader;
