import React from 'react';
import EnhancedTableHead from '../../../../../components/EnhancedTable/EnhancedTableHead';

import { stableSort, getComparator } from '../../../../../util/EnhancedTableFunctions';

import {
  Checkbox,
  Table,
  TableCell,
  TableHead,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableRow,
  Dialog,
  Collapse,
  Box,
  Grid,
  Typography,
  TableSortLabel,
  Tooltip,
  TableBody,
  Button
} from '@material-ui/core';
import Modal from '../../../../../components/Modal/Modal';
import CreateNewAttribute from './CreateNewAttriute';
import { CloseOutlined, EditOutlined, CheckOutlined, KeyboardArrowUp, KeyboardArrowDown } from '@material-ui/icons';
import Spinner from '@material-ui/core/CircularProgress';

const headCells = [
  { id: 'collapse', align: false, sortable: false, disablePadding: true, label: 'Collapse' },
  { id: 'id', align: false, sortable: true, disablePadding: true, label: 'Attribute Code' },
  { id: 'label', align: true, sortable: true, disablePadding: false, label: 'Attribute Label' },
  { id: 'isRequired', align: true, sortable: true, disablePadding: false, label: 'Required' },
  { id: 'isGlobal', align: true, sortable: true, disablePadding: false, label: 'Global' },
  { id: 'type', align: true, sortable: false, disablePadding: false, label: 'Type' },
  { id: 'edit', align: false, sortable: true, disablePadding: true, label: '' }
];

const subTableColumns = [
  { id: 'id', align: false, sortable: true, disablePadding: true, label: 'Value Code' },
  { id: 'value', align: false, sortable: true, disablePadding: true, label: 'Value' },
  { id: 'isDefault', align: false, sortable: true, disablePadding: true, label: 'Default' }
];

class DataTable extends React.Component {
  state = {
    show: false,
    selectedRowIds: new Set([]),
    openItemIds: new Set([]),
    data: this.props.data,
    removeDialog: {
      title: '',
      description: '',
      mode: null,
      active: false
    },
    sort: {
      sortColumn: 'createdAt',
      sortDirection: 'DESC'
    },
    subTableSort: {
      sortColumn: 'createdAt',
      sortDirection: 'DESC'
    },
    selectedValueRowIds: new Set([]),
    loading: false,
    createData: undefined,
    order: 'asc',
    orderBy: 'id',
    selected: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevState.data) !== JSON.stringify(this.props.data)) {
      this.setState({
        data: this.props.data
      });
    }
  }

  createSortHandler = property => event => {
    const sort = {
      sortColumn: property,
      sortDirection:
        this.state.sort.sortColumn === property
          ? ['ASC', 'DESC'][Number(this.state.sort.sortDirection === 'ASC')]
          : 'DESC'
    };
    this.setState({
      sort,
      loading: true
    });
    this.props
      .getData({
        ...sort
      })
      .finally(() => {
        this.setState({
          loading: false
        });
      });
  };

  createSubRowSortHandler = (property, parent) => event => {
    const sort = {
      sortColumn: property,
      sortDirection:
        this.state.subTableSort.sortColumn === property
          ? ['ASC', 'DESC'][Number(this.state.subTableSort.sortDirection === 'ASC')]
          : 'DESC'
    };
    let { data } = this.state;
    switch (property) {
      case 'id': {
        parent.values = (parent.values || []).sort((a, b) =>
          sort.sortDirection === 'ASC' ? b.id - a.id : a.id - b.id
        );
        break;
      }
      case 'value': {
        parent.values = (parent.values || []).sort((a, b) =>
          sort.sortDirection === 'ASC'
            ? b.value.toString().localeCompare(a.value.toString())
            : a.value.toString().localeCompare(b.value.toString())
        );
        break;
      }
      case 'isDefault': {
        parent.values = (parent.values || []).sort((a, b) =>
          sort.sortDirection === 'ASC' ? (a === b ? 0 : a ? -1 : 1) : b === a ? 0 : b ? -1 : 1
        );
        break;
      }
      default: {
        parent.values = (parent.values || []).sort((a, b) => {
          const [da, db] = [new Date(a.createdAt).getTime(), new Date(b.createdAt).getTime()];
          return sort.sortDirection === 'ASC' ? db - da : da - db;
        });
      }
    }

    this.setState({
      subTableSort: sort,
      data: data.map(dataItem => (dataItem.id === parent.id ? parent : dataItem))
    });
  };

  handleClick = () => {
    this.setState({ show: true });
  };
  onClose = () => {
    this.setState({ show: false, createData: undefined });
  };

  edit = item => {
    this.setState({
      createData: item,
      show: true
    });
  };

  getData = () => {
    return this.props.getData({
      ...this.state.sort
    });
  };

  handleRequestSort = (event, property) => {
    // Sorting Functions
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data =
      order === 'desc'
        ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };

  handleSelectAllClick = event => {
    // Sorting Functions
    console.log('Teeeest 1', event);

    if (event.target.checked) {
      console.log('Test');
      this.setState({
        selected: this.state.data.map(n => n.id)
      });
      // this.setState({
      //   selectedRowIds: this.state.data.map(n => n.id)
      // })
      return;
    }
    this.setState({ selected: [] });

    console.log('Teeeest');
  };

  isSelected = ID => (this.state.selected.indexOf(ID) !== -1);

  render() {
    const { data, show } = this.state;

    return (
      <div>
        <Modal title="New Attribute" show={show} onClose={this.onClose}>
          <CreateNewAttribute getData={this.getData} data={this.state.createData} onClose={this.onClose} />
        </Modal>
        <Dialog fullScreen={false} className={'mui-custom_dialog'} open={this.state.removeDialog.active}>
          {this.state.removeDialog.title && <DialogTitle color={'white'}>{this.state.removeDialog.title}</DialogTitle>}
          {this.state.removeDialog.description && (
            <DialogContent>
              <DialogContentText>{this.state.removeDialog.description}</DialogContentText>
            </DialogContent>
          )}
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                this.setState({
                  removeDialog: {
                    mode: null,
                    active: false,
                    title: '',
                    description: ''
                  }
                });
              }}
              color="primary"
            >
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  loading: true,
                  removeDialog: {
                    active: false,
                    mode: null,
                    title: '',
                    description: ''
                  }
                });
                switch (this.state.removeDialog.mode) {
                  case 'attribute': {
                    this.props
                      .bulkRemove(Array.from(this.state.selectedRowIds))
                      .then(() => {
                        return this.getData();
                      })
                      .finally(() => {
                        this.setState({
                          selectedRowIds: new Set([]),
                          selectedValueRowIds: new Set([]),
                          loading: false
                        });
                      });
                    break;
                  }
                  default: {
                    setImmediate(() => {
                      this.setState({
                        loading: false
                      });
                    });
                    break;
                  }
                }
              }}
              color="primary"
              autoFocus
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <button className="add-new-product-button" onClick={this.handleClick}>
            Create New Attribute
          </button>
          {this.state.selectedRowIds.size > 0 && (
            <Button
              variant={'contained'}
              color={'primary'}
              onClick={() => {
                this.setState({
                  removeDialog: {
                    active: true,
                    title: `Are you sure you want to delete ${this.state.selectedRowIds.size} attributes?`,
                    mode: 'attribute'
                  }
                });
              }}
            >
              <b>Delete {this.state.selectedRowIds.size} Attributes</b>
            </Button>
          )}
        </Grid>
        {this.state.loading ? (
          <Box p={3} display={'flex'} justifyContent={'center'} flexDirection={'row'}>
            <Spinner className={'mui-custom_table-spinner'} />
          </Box>
        ) : (
          <Table>
            {/* <TableHead>
              <TableRow>
                {columnData.map(column => {
                  return (
                    <TableCell
                      key={column.id}
                      align={column.numeric}
                      padding={column.disablePadding ? 'none' : 'default'}
                    >
                      {column.sortable ? (
                        <Tooltip
                          title="Sort"
                          placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={this.state.sort.sortColumn === column.id}
                            direction={this.state.sort.sortDirection.toLowerCase()}
                            onClick={this.createSortHandler(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </Tooltip>
                      ) : (
                        column.label
                      )}
                    </TableCell>
                  );
                }, this)}
              </TableRow>
            </TableHead> */}

            <EnhancedTableHead
              numSelected={this.state.selected.length}
              order={this.state.order}
              orderBy={this.state.orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              headCells={headCells}
            />
            <TableBody>
              {(stableSort(data, getComparator(this.state.order, this.state.orderBy)) || []).map((item, index) => {
                const isItemSelected = this.isSelected(item.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <React.Fragment key={item.id}>
                    <TableRow 
                      hover={true} 
                      key={item.id} 
                      selected={isItemSelected || this.state.selectedRowIds.has(item.id)} 
                      aria-checked={isItemSelected}
                    >
                      <TableCell>
                        <Checkbox
                          color={'primary'}
                          checked={isItemSelected }
                          inputProps={{"aria-labelledby": labelId}}
                          onChange={event => {
                            const selected = new Set(Array.from(this.state.selected));
                            if (event.target.checked) {
                              selected.add(item.id);
                            } else {
                              selected.delete(item.id);
                            }
                            this.setState({
                              selected
                            });
                          }}
                          defaultChecked={isItemSelected}
                        />
                      </TableCell>
                      <TableCell>
                        <Button
                          hidden={!(item.values || []).length}
                          onClick={() => {
                            const openItemIds = new Set(Array.from(this.state.openItemIds));
                            if (openItemIds.has(item.id)) {
                              openItemIds.delete(item.id);
                            } else {
                              openItemIds.clear();
                              openItemIds.add(item.id);
                            }
                            this.setState({
                              openItemIds
                            });
                          }}
                        >
                          {this.state.openItemIds.has(item.id) ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                        </Button>
                      </TableCell>
                      <TableCell>{item.id}</TableCell>
                      <TableCell>{item.label}</TableCell>
                      <TableCell>{item.isRequired ? <CheckOutlined /> : <CloseOutlined />}</TableCell>
                      <TableCell>{item.isGlobal ? <CheckOutlined /> : <CloseOutlined />}</TableCell>
                      <TableCell>{(item.type && item.type.name) || ''}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() => {
                            this.edit(item);
                          }}
                          size={'small'}
                          color={'default'}
                          variant={'text'}
                        >
                          <EditOutlined />
                        </Button>
                      </TableCell>
                    </TableRow>
                    <TableRow key={`value_${item.id}`}>
                      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
                        <Collapse in={this.state.openItemIds.has(item.id)} timeout="auto" unmountOnExit>
                          <Box mb={5} mt={2}>
                            <Typography variant="h6" gutterBottom={true} component="div">
                              Attribute Values
                            </Typography>
                            <Table style={{ width: '100%' }}>
                              <TableHead>
                                <TableRow>
                                  {subTableColumns.map(subColumn => (
                                    <TableCell
                                      key={subColumn.id}
                                      align={subColumn.numeric}
                                      padding={subColumn.disablePadding ? 'none' : 'default'}
                                    >
                                      {subColumn.sortable ? (
                                        <Tooltip
                                          title="Sort"
                                          placement={subColumn.numeric ? 'bottom-end' : 'bottom-start'}
                                          enterDelay={300}
                                        >
                                          <TableSortLabel
                                            active={this.state.subTableSort.sortColumn === subColumn.id}
                                            direction={this.state.subTableSort.sortDirection.toLowerCase()}
                                            onClick={this.createSubRowSortHandler(subColumn.id, item)}
                                          >
                                            {subColumn.label}
                                          </TableSortLabel>
                                        </Tooltip>
                                      ) : (
                                        subColumn.label
                                      )}
                                    </TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {(item.values || []).map(value => (
                                  <TableRow hover={true} key={value.id}>
                                    <TableCell>{value.id}</TableCell>
                                    <TableCell>{value.value}</TableCell>
                                    <TableCell>{value.isDefault ? <CheckOutlined /> : <CloseOutlined />}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </TableBody>
          </Table>
        )}
      </div>
    );
  }
}

export default DataTable;
