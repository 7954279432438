import React, { Component } from 'react';
import { DeleteIconSvg } from '../../assets/svg/SvgIcons';
import translate from '../../appUtility/Translate';

class DeleteHeaderModal extends Component {
  render() {
    return (
      <div className="app-delete-modal_header">
        <div className="delete-modal_items-list">
          <span onClick={this.props.clearList}>x</span>{' '}
          <h3>
            {this.props.count} {translate('items_selected')}
          </h3>
        </div>
        <div className="app-delete-modal_right">
          {this.props.isOwnProducts ? (
            <div className="app-delete-modal_button">
              <button onClick={this.props.confirmDelete}>
                <i
                  className="fas fa-trash-alt"
                  style={{ fontSize: '16px', color: 'rgba(0,0,0,0.54)', paddingRight: '5px' }}
                ></i>{' '}
                {translate('delete')}
              </button>
            </div>
          ) : (
            <div className="app-delete-modal_button" style={{ backgroundColor: '#fff' }}>
              <button onClick={this.props.handleImport} style={{ color: '#37dfb6' }}>
                {`${translate('import')} ${this.props.count} ${translate('products')}`}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default DeleteHeaderModal;
