import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import translate from '../../../../appUtility/Translate';

const Tabs = props => {
  const switchTab = e => {
    props.setTab(e.target.id);
  };
  const handleClick = e => {
    e.preventDefault();
    const param = props.match.params.id;
    props.edit(param);
  };

  return (
    <div className="tab-wrapper">
      <div className="tab-header">{translate('order_details')}</div>
      <div className="tab-container" onClick={switchTab}>
        <div id="general" className={'tab-item' + (props.activeTab === 'general' ? ' tab-item--active' : '')}>
          {translate('general')}
        </div>
        <div
          id="shipping"
          className={'tab-item' + (props.activeTab === 'shipping' ? ' tab-item--active' : '')}
          onClick={switchTab}
        >
          {translate('shipping_data')}
        </div>
        <div
          id="billing"
          className={'tab-item' + (props.activeTab === 'billing' ? ' tab-item--active' : '')}
          onClick={switchTab}
        >
          {translate('billing_data')}
        </div>
        <div
          id="products"
          className={'tab-item' + (props.activeTab === 'products' ? ' tab-item--active' : '')}
          onClick={switchTab}
        >
          {translate('products')}
        </div>
        <div className="tab-buttons">
          <button onClick={handleClick}>Save</button>
          <NavLink className="prepend-icon" to="/app/orders/listing">
            <button>Cancel</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Tabs);
