import AppAPI from '../appUtility/Api';

class AuthService {
  static SignUp = merchant => {
    return AppAPI.post('/merchant/register', merchant).then(res => {
      return res.data;
    });
  };

  static SignIn = merchant => {
    return AppAPI.post('/admin/login', merchant).then(res => {
      return res.data;
    });
  };

  static SignOut = merchant => {
    return AppAPI.post('/admin/signout', merchant).then(res => {
      return res.data;
    });
  };

  static PasswordResetRequest = email => {
    return AppAPI.post('/app/user/passwordreset', email).then(res => {
      return res.data;
    });
  };

  static PasswordResetRouteRequest = url => {
    return AppAPI.post('/app/user/passwordrouteconfirm', url).then(res => {
      return res.data;
    });
  };

  static UpdatePassword = data => {
    return AppAPI.post('/app/user/passwordresetconfirm', data).then(res => {
      return res.data;
    });
  };
}

export default AuthService;
