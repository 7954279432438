// Fetching Merchants
export const selectTranslations = state => {
  return state.TranslationReducer.Translations;
};
// Pending
export const selectTranslationsPending = state => {
  return state.TranslationReducer.pending;
};
// Error
export const selectTranslationsError = state => {
  return state.TranslationReducer.error;
};

// Page
export const selectTranslationsPage = state => {
  return state.TranslationReducer.page;
};
