import { connect } from 'react-redux';

import Order from '../../app/routes/Orders/Wizard/index';

import { FetchCountriesAction } from './order.action';

import { selectCountries } from './order.selector';

const mapStateToProps = state => {
  return {
    Countries: selectCountries(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchCountriesAction: () => dispatch(FetchCountriesAction())
  };
};

const OrderSingleView = connect(mapStateToProps, mapDispatchToProps)(Order);

export default OrderSingleView;
