import React from 'react';
import CardBox from 'components/CardBox/index';
import { DateTimePicker } from 'material-ui-pickers';
import translate from '../../../../appUtility/Translate';

export default function General({ data, handleChange, handleDateChange }) {
  return (
    <div className="club-add-merchant-content">
      <CardBox
        styleName="col-12"
        cardStyle="p-0"
        style={{ borderRadius: '4px', border: '1px solid rgb(222,220,236)' }}
        headerOutside
      >
        <div>
          <form className="" style={{ maxWidth: '500px' }}>
            <div>
              <span>{translate('date')}</span>

              <DateTimePicker
                fullWidth
                value={data.time || new Date()}
                showTabs={false}
                onChange={handleDateChange}
                leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                disabled
              />
            </div>

            <div>
              <span>{translate('status')}</span>

              <select
                id="status"
                className="browser-default custom-select"
                onChange={handleChange}
                value={data.status || ''}
                style={{ cursor: 'pointer' }}
              >
                <option value="1">{translate('pending_payment')}</option>
                <option value="2">{translate('processing')}</option>
                <option value="10">{translate('Awaiting_Processing')}</option>
                <option value="3">{translate('completed')}</option>
                <option value="6">{translate('cancelled')}</option>
                <option value="8">{translate('refunded')}</option>
                <option value="17">{translate('failed')}</option>
              </select>
            </div>
          </form>
        </div>
      </CardBox>
    </div>
  );
}
