import React, { useState, useEffect } from 'react';
import Header from 'components/Header';
import DataTable from './userTable';
import Catalog from './Catalog';
import DeleteHeaderModal from '../../../components/Header/deleteModal';
import axios from '../../../appUtility/Api';
import SweetAlert from 'react-bootstrap-sweetalert';

const userTable = props => {
  const [selected, setSelected] = useState([]);
  const [alert, setAlert] = useState(null);
  const [searchText, setsearchText] = useState(null);

  const handleChangeSelectedRows = id => {
    setSelected(id);
  };

  const deleteThisUser = () => {
    let getAlert = () => {
      return (
        <SweetAlert
          warning
          show
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure that you want to delete?"
          onConfirm={() => confirmDelete()}
          onCancel={hideAlert}
        ></SweetAlert>
      );
    };

    setAlert(getAlert());
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const confirmDelete = () => {
    setAlert(null);
    axios({
      method: 'post',
      url: axios.defaults.baseURL + '/admin/deleteselectedusers',
      data: JSON.stringify({ id: selected }),
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    }).then(res => {
      if (res.data.success) {
        window.location.reload();
      }
    });
  };

  const clearList = () => {
    setSelected([]);
  };

  const getSearchBarValue = e => {
    setsearchText(e);
  };

  const submitSearchBox = async () => {
    props.FetchUsers(searchText);
    props.SetPage(0);
  };

  useEffect(() => {
    props.FetchUsers();
  }, []);

  return (
    <>
      {alert}

      <div className="users-wrapper">
        <Catalog />
        <div className="users-container">
          {selected.length > 0 ? (
            <DeleteHeaderModal count={selected.length} clearList={clearList} confirmDelete={deleteThisUser} />
          ) : (
            <Header
              getSearchBarValue={getSearchBarValue}
              submitSearchBox={submitSearchBox}
              search={true}
              merchants="users"
            />
          )}

          <DataTable
            handleChangeSelectedRows={handleChangeSelectedRows}
            selected={selected}
            data={props.Users}
            pending={props.Pending}
            page={props.page}
            setPage={props.SetPage}
          />
        </div>
      </div>
    </>
  );
};

export default userTable;
