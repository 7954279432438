import React from 'react';
import { CropIconSvg, DeleteIconSvg, MoveIconSvg, YoutubeIconSvg } from '../../../../../assets/svg/SvgIcons';

const UploadView = props => {
  const { image, removeImageItem, handleCrop } = props;
  return (
    <div className="uploaded-image-box">
      <img src={image} className="uploaded-image" alt="preview" />
      <div className="uploaded-image-hoverbox">
        <div className="hoverbox-item hoverbox-crop" onClick={handleCrop}>
          <CropIconSvg />
        </div>
        {/*<div className="hoverbox-item hoverbox-youtube">*/}
        {/*  <YoutubeIconSvg />*/}
        {/*</div>*/}
        {/*<div className="hoverbox-item hoverbox-move">*/}
        {/*  <MoveIconSvg />*/}
        {/*</div>*/}
        {/*Remove Image*/}
        <div className="hoverbox-item hoverbox-delete" onClick={removeImageItem}>
          <i className="fas fa-trash-alt" style={{ fontSize: '16px', color: 'rgba(0,0,0,0.54)' }}></i>
        </div>
      </div>
    </div>
  );
};

export default UploadView;
