import {
  FetchDashboardDataPending,
  FetchDashboardDataSuccess,
  FetchDashboardDataError,
  FetchDashboardMapDataPending,
  FetchDashboardMapDataSuccess,
  FetchDashboardMapDataError
} from './dashboard.actionCreator';
import DashboardService from '../../services/Dashboard';

// Fetch Dashboard Data

export const FetchDashboardData = data => {
  return async dispatch => {
    try {
      dispatch(FetchDashboardDataPending());

      const Data = await DashboardService.fetchDashboardData(data);

      dispatch(FetchDashboardDataSuccess(Data));
    } catch (err) {
      dispatch(FetchDashboardDataError(err));
    }
  };
};

export const FetchDashboardMapData = data => {
  return async dispatch => {
    try {
      dispatch(FetchDashboardMapDataPending());

      const Data = await DashboardService.fetchDashboardMapData(data);

      dispatch(FetchDashboardMapDataSuccess(Data));
    } catch (err) {
      dispatch(FetchDashboardMapDataError(err));
    }
  };
};
