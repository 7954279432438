import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import IntlMessages from 'util/IntlMessages';
import Lock from '../../src/assets/images/loginlocklarge.png';
import CloseXbtn from '../components/commonElements/CloseXbtn';
import validator from 'validator';
import EnviteIcon from '../components/commonElements/PaylixIcon';
import WhiteLogo from '../assets/images/logo/clalit.png';
import InternityLogo from "../assets/icons/internity-logo-final-20@3x.png";

import BG1 from '../components/Backgrounds/BG1';
import {
  selectAuthAlertMessage,
  selectAuthMessage,
  selectAuthPending,
  selectAuthUser,
  selectNewPassRequest
} from '../store/auth/auth.selector';
import { hideMessage, ResetPasswordRequest, showAuthLoader } from '../store/auth/auth.actions';

class ResetPass extends React.Component {
  state = {
    email: ''
  };

  componentDidUpdate() {
    if (this.props.showMessage || this.state.emailError) {
      // hide email validation error after 3 seconds
      setTimeout(() => {
        this.props.hideMessage();
        this.setState({ emailError: false });
      }, 3000);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
    if (this.props.emailSentSuccess) {
      this.props.history.push('/passwordresetsent');
    }
  }

  placeholderStyle = {
    // placeholder styles for UI material, used in TextField component' attribute 'InputLabelProps
    fontSize: '13px',
    color: '#c4c4c4',
    height: '46px'
  };

  onSubmit = e => {
    e.preventDefault();
    const data = {
      email: this.state.email
    };
    !validator.isEmail(this.state.email) && this.setState({ emailError: true });
    this.props.ResetPasswordRequest(data);
  };

  render() {
    const { email, emailError } = this.state;
    const { loader } = this.props;

    return (
      <>
        <BG1 />

        <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
          <div className="app-login-main-content">
            <div className="app-forget-password">
              <CloseXbtn to="/signin" />

              <div className="lock-image__wrapper">
                <img src={Lock} alt="lock" className="forgot-password__lock" />
              </div>

              <h1>Reset Your Password</h1>

              {emailError ? (
                <p className="email-error__message">Please enter valid email</p>
              ) : (
                <p>Enter your email address below and we'll send you a link to reset your password.</p>
              )}

              <TextField
                className="outlined-dense"
                label="Your Email"
                InputLabelProps={{
                  style: this.placeholderStyle
                }}
                fullWidth
                onChange={event => this.setState({ email: event.target.value })}
                defaultValue={email}
                margin="dense"
                variant="outlined"
              />

              <div className="mb-3 d-flex align-items-center justify-content-between">
                {loader ? (
                  <div className="loader-view">
                    <CircularProgress />
                  </div>
                ) : (
                  <Button className="login-button" variant="contained" color="primary" onClick={this.onSubmit}>
                    <span className="reset-password__text">
                      <IntlMessages id="appModule.resetPassword" />
                    </span>
                  </Button>
                )}
              </div>
              <img className="envite-logo" src={InternityLogo} style={{ width: '100px' }} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    authUser: selectAuthUser(state),
    loader: selectAuthPending(state),
    showMessage: selectAuthMessage(state),
    alertMessage: selectAuthAlertMessage(state),
    emailSentSuccess: selectNewPassRequest(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    hideMessage: () => dispatch(hideMessage()),
    showAuthLoader: () => dispatch(showAuthLoader()),
    ResetPasswordRequest: email => dispatch(ResetPasswordRequest(email))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPass);
