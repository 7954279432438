import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
// import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import translate from 'appUtility/Translate';
import axios from '../../appUtility/Api';
import InternityLogoSml from '../../assets/icons/internity-logo-small-full.png';
import { connect } from 'react-redux';

class SidenavContent extends Component {
  // state = {
  //   role: null,
  //   company: null,
  //   merchant: '',
  //   clalit: ''
  // };

  componentDidMount() {
    this.initMenuActions();
  }

  initMenuActions = () => {
    const { history } = this.props;
    const that = this;
    const pathname = `${history.location.pathname}`; // get current path

    const menuLi = document.getElementsByClassName('menu');
    for (let i = 0; i < menuLi.length; i++) {
      if (pathname.includes('dashboard')) {
        menuLi[i].classList.remove('open');
      }
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, 'li');
          if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
            menuLi[j].classList.remove('open');
          }
        }
        this.classList.toggle('open');
      };
    }

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) { }
  };

  componentDidUpdate(prevProps) {
    this.initMenuActions();
  }

  componentWillReceiveProps(nextProps) {
    const { history } = nextProps;
    const pathname = `${history.location.pathname}`; // get current path

    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, 'ul'); // select closest ul
      if (activeNav.classList.contains('sub-menu')) {
        this.closest(activeNav, 'li').classList.add('open');
      } else {
        this.closest(activeLi, 'li').classList.add('open');
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (
        fn
      ) {
        if (typeof document.body[fn] == 'function') {
          matchesFn = fn;
          return true;
        }
        return false;
      });

      let parent;

      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }

    return null;
  }

  render() {
    const { role, company } = this.props;
    const merchant = role === 2 || role === 3 ? 2 : null;
    const clalit = company && company.id === 2;

    return (
      <>
        <NavLink to={role !== 2 ? '/app/dashboard' : '/app/club/catalog'} className="navigation-logo-wrapper">
          {window.location.hostname == 'homerun.webiz.ge' ? <h1>H</h1> : <img className="navigation-logo" src={InternityLogoSml} alt="Clalit"></img>}

        </NavLink>
        {role && (
          <CustomScrollbars className="scrollbar">
            <ul className="nav-menu">
              {/*Club*/}
              <li className="menu">
                <Button className="void" classes={{ label: 'sub-menu-btn-label' }}>
                  <span className="navigation-icon heart-icon" id="club"></span>
                  <span className="nav-text">{translate('Club')}</span>
                </Button>
                <ul className="sub-menu">
                  <li>
                    <NavLink className="prepend-icon" to="/app/club/catalog">
                      <i className="material-icons">keyboard_arrow_right</i>
                      <span className="nav-text">{translate('Catalog')}</span>
                    </NavLink>
                  </li>
                  {!merchant && (
                    <>
                      <li>
                        <NavLink className="prepend-icon" to="/app/club/category/listing">
                          <i className="material-icons">keyboard_arrow_right</i>
                          <span className="nav-text">{translate('Categories')}</span>
                        </NavLink>
                      </li>
                      <li>
                        {window.location.hostname == 'homerun.webiz.ge' ? null : <NavLink className="prepend-icon" to="/app/club/slider/listing">
                          <i className="material-icons">keyboard_arrow_right</i>
                          <span className="nav-text">{translate('Club Slider')}</span>
                        </NavLink>}

                      </li>
                    </>
                  )}
                </ul>
              </li>
              {/*Users*/}
              {!merchant && !clalit && (
                <li className="menu arrow">
                  {window.location.hostname == 'homerun.webiz.ge' ? null : <NavLink
                    className="void"
                    classes={{ label: 'sub-menu-btn-label' }}
                    className="prepend-icon"
                    to="/app/users/listing"
                    className={'noIcon'}
                  >
                    <span className="navigation-icon club-icon" id="club"></span>
                    <span className="nav-text">{translate('Users')}</span>
                  </NavLink>}

                  {/*<ul className="sub-menu">*/}
                  {/*  <li>*/}
                  {/*    <NavLink className="prepend-icon" to="/app/users/listing">*/}
                  {/*      <i className="material-icons">keyboard_arrow_right</i>*/}
                  {/*      <span className="nav-text">{translate('Users Listing')}</span>*/}
                  {/*    </NavLink>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </li>
              )}
              {/*Merchants*/}

              {!merchant && !clalit && (
                <li className="menu arrow">
                  <NavLink
                    className="void"
                    classes={{ label: 'sub-menu-btn-label' }}
                    to="/app/merchants/listing"
                    className={'noIcon'}
                  >
                    <span className="navigation-icon bank-icon" id="club"></span>
                    <span className="nav-text">{translate('Merchants')}</span>
                  </NavLink>
                  {/*<ul className="sub-menu">*/}
                  {/*  <li>*/}
                  {/*    <NavLink className="prepend-icon" to="/app/merchants/listing">*/}
                  {/*      <i className="material-icons">keyboard_arrow_right</i>*/}
                  {/*      <span className="nav-text">{translate('Merchants Listing')}</span>*/}
                  {/*    </NavLink>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </li>
              )}
              {/*Customers*/}
              {!merchant && (
                <li className="menu arrow">
                  <NavLink
                    to="/app/customers/listing"
                    className="void"
                    classes={{ label: 'sub-menu-btn-label' }}
                    className={'noIcon'}
                  >
                    <span className="navigation-icon club-icon" id="club"></span>
                    <span className="nav-text">{translate('Customers')}</span>
                  </NavLink>
                  {/*<ul className="sub-menu">*/}
                  {/*  <li>*/}
                  {/*    <NavLink className="prepend-icon" to="/app/customers/listing">*/}
                  {/*      <i className="material-icons">keyboard_arrow_right</i>*/}
                  {/*      <span className="nav-text">{translate('Customers Listing')}</span>*/}
                  {/*    </NavLink>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </li>
              )}
              {/*Orders*/}
              {!merchant && !clalit && (
                <li className="menu arrow">
                  <NavLink
                    to="/app/orders/listing"
                    className="void"
                    classes={{ label: 'sub-menu-btn-label' }}
                    className={'noIcon'}
                  >
                    <span className="navigation-icon cart-icon" id="club"></span>
                    <span className="nav-text">{translate('Orders')}</span>
                  </NavLink>
                  {/*<ul className="sub-menu">*/}
                  {/*  <li>*/}
                  {/*    <NavLink className="prepend-icon" to="/app/orders/listing">*/}
                  {/*      <i className="material-icons">keyboard_arrow_right</i>*/}
                  {/*      <span className="nav-text">{translate('Orders Listing')}</span>*/}
                  {/*    </NavLink>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                </li>
              )}
              {/*Logs*/}
              <li className="menu arrow">
                {/* <NavLink
                  to="/app/logs/listing"
                  className="void"
                  classes={{ label: 'sub-menu-btn-label' }}
                  className={'noIcon'}
                >
                  <span className="navigation-icon app-icon" id="club"></span>
                  <span className="nav-text">{translate('Logs')}</span>
                </NavLink> */}
                {/*<ul className="sub-menu">*/}
                {/*  <li>*/}
                {/*    <NavLink className="prepend-icon" to="/app/logs/listing">*/}
                {/*      <i className="material-icons">keyboard_arrow_right</i>*/}
                {/*      <span className="nav-text">{translate('Logs Listing')}</span>*/}
                {/*    </NavLink>*/}
                {/*  </li>*/}
                {/*</ul>*/}
              </li>

              {/*Translations*/}
              {/*{!merchant && !clalit && (*/}
              {/*  <li className="menu arrow">*/}
              {/*    <NavLink*/}
              {/*      to="/app/translations/listing"*/}
              {/*      classes={{ label: 'sub-menu-btn-label' }}*/}
              {/*      className={'noIcon'}*/}
              {/*    >*/}
              {/*      <span className="navigation-icon club-icon" id="club"></span>*/}
              {/*      <span className="nav-text">{translate('Translations')}</span>*/}
              {/*    </NavLink>*/}
              {/*    /!*<ul className="sub-menu">*!/*/}
              {/*    /!*  <li>*!/*/}
              {/*    /!*    <NavLink className="prepend-icon" to="/app/translations/listing">*!/*/}
              {/*    /!*      <i className="material-icons">keyboard_arrow_right</i>*!/*/}
              {/*    /!*      <span className="nav-text">{translate('Translations Listing')}</span>*!/*/}
              {/*    /!*    </NavLink>*!/*/}
              {/*    /!*  </li>*!/*/}
              {/*    /!*</ul>*!/*/}
              {/*  </li>*/}
              {/*)}*/}
              {window.location.hostname != 'homerun.webiz.ge' &&
                <li className="menu">
                  <Button className="void" classes={{ label: 'sub-menu-btn-label' }}>
                    <span className="navigation-icon club-icon" id="club"></span>
                    <span className="nav-text">{translate('Settings')}</span>
                  </Button>
                  <ul className="sub-menu">
                    <li>
                      <NavLink className="prepend-icon" to="/app/translations/listing">
                        <i className="material-icons">keyboard_arrow_right</i>
                        <span className="nav-text">{translate('translations')}</span>
                      </NavLink>
                    </li>
                  </ul>
                </li>
              }
              {/*<li className="menu no-arrow" >*/}
              {/*<NavLink to="/app/users" >*/}
              {/*<span className="navigation-icon club-icon" ></span>*/}
              {/*<span className="nav-text" >Users</span>*/}
              {/*</NavLink>*/}
              {/*</li>*/}
              {/*<li className="menu" >*/}
              {/*<Button className="void" classes={{ label: 'sub-menu-btn-label' }}>*/}
              {/*<span className="navigation-icon club-icon" id="club"></span>*/}
              {/*<span className="nav-text"><IntlMessages id="sidebar.pickers" /></span>*/}
              {/*</Button>*/}

              {/*<ul className="sub-menu">*/}
              {/*<li>*/}
              {/*<NavLink className="prepend-icon" to="/app/pickers/date-time">*/}
              {/*<i className="material-icons">keyboard_arrow_right</i>*/}
              {/*<span className="nav-text"><IntlMessages*/}
              {/*id="sidebar.pickers.dateTimePickers" /></span>*/}
              {/*</NavLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*<NavLink className="prepend-icon" to="/app/pickers/1">*/}
              {/*<i className="material-icons">keyboard_arrow_right</i>*/}
              {/*<span className="nav-text"><IntlMessages id="sidebar.pickers.colorPickers" /></span>*/}
              {/*</NavLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*<NavLink className="prepend-icon" to="/app/pickers/2">*/}
              {/*<i className="material-icons">keyboard_arrow_right</i>*/}
              {/*<span className="nav-text"><IntlMessages id="sidebar.pickers.colorPickers" /></span>*/}
              {/*</NavLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*<NavLink className="prepend-icon" to="/app/pickers/3">*/}
              {/*<i className="material-icons">keyboard_arrow_right</i>*/}
              {/*<span className="nav-text"><IntlMessages id="sidebar.pickers.colorPickers" /></span>*/}
              {/*</NavLink>*/}
              {/*</li>*/}
              {/*<li>*/}
              {/*<NavLink className="prepend-icon" to="/app/pickers/4">*/}
              {/*<i className="material-icons">keyboard_arrow_right</i>*/}
              {/*<span className="nav-text"><IntlMessages id="sidebar.pickers.colorPickers" /></span>*/}
              {/*</NavLink>*/}
              {/*</li>*/}
              {/*</ul>*/}

              {/*</li>*/}
              {/* <li className="menu no-arrow" >
            <NavLink to="/app/orders" >
              <span className="navigation-icon heart-icon" ></span>
              <span className="nav-text" >More Info</span>
            </NavLink>
          </li>
          <li className="menu no-arrow" >
            <NavLink to="/app/chat" >
              <span className="navigation-icon bank-icon" ></span>
              <span className="nav-text" >More Info</span>
            </NavLink>
          </li>
          <li className="menu no-arrow" >
            <NavLink to="/app/announcements" >
              <span className="navigation-icon cart-icon" ></span>
              <span className="nav-text" >More Info</span>
            </NavLink>
          </li> */}
              {/* <li className="menu no-arrow">
            <NavLink to="/app/goals">
              <i className="zmdi zmdi-gps-dot zmdi-hc-fw"/>
              <span className="nav-text"><IntlMessages id="pages.goals"/> </span>
            </NavLink>
          </li> */}
            </ul>
          </CustomScrollbars>
        )}
      </>
    );
  }
}
const mapStateToProps = ({ auth, merchantReducer }) => {
  const { role } = auth;
  const { Merchant } = merchantReducer;
  return { role, Merchant };
};
export default withRouter(connect(mapStateToProps)(SidenavContent));
