import { connect } from 'react-redux';
import { FetchTranslations, SetPage } from './translation.actions';
import {
  selectTranslations,
  selectTranslationsPending,
  selectTranslationsError,
  selectTranslationsPage
} from './translation.selector';
import Translation from '../../app/routes/Translations/index';

const mapStateToProps = state => {
  return {
    Pending: selectTranslationsPending(state),
    Translations: selectTranslations(state),
    ErrorMessage: selectTranslationsError(state),
    page: selectTranslationsPage(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchTranslations: searchText => dispatch(FetchTranslations(searchText)),
    SetPage: page => dispatch(SetPage(page))
  };
};

const TranslationView = connect(mapStateToProps, mapDispatchToProps)(Translation);

export default TranslationView;
