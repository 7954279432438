import React, { useEffect, useState } from 'react';
import Header from 'components/Header';
import DataTable from './merchantTable';
import Catalog from './Catalog';
import Loader from '../../../appUtility/Loader';

const userTable = props => {
  useEffect(() => {
    props.FetchMerchants();
  }, []);

  const [searchText, setsearchText] = useState(null);

  const getSearchBarValue = e => {
    setsearchText(e);
  };

  const submitSearchBox = () => {
    props.FetchMerchants({ searchText });
    props.SetPage(0);
  };
  return (
    <div className="merchant-wrapper">
      <Catalog />
      <div className="merchant-container">
        <Header
          merchants="merchants"
          getSearchBarValue={getSearchBarValue}
          submitSearchBox={submitSearchBox}
          search={true}
        />

        <DataTable
          fechMerchants={props.FetchMerchants}
          data={props.Merchants}
          pending={props.Pending}
          page={props.page}
          setPage={props.SetPage}
        />
      </div>
    </div>
  );
};

export default userTable;
