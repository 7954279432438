import {
  FetchFilteredProductsPending,
  FetchFilteredProductsSuccess,
  FetchFilteredProductsError,
  SelectedProductsPending,
  SelectedProductsSuccess,
  SelectedProductsError,
  ImportProductsPending,
  ImportProductsSuccess,
  ImportProductsError,
  ImportRetrieveCountriesPending,
  ImportRetrieveCountriesSuccess,
  ImportRetrieveCountriesError
} from './catalog.actionCreator';
import ProductsService from '../../services/Catalog';
import * as types from './catalog.actionTypes';

// Fetch Categories

export const FetchFilteredProducts = filter => {
  return async dispatch => {
    try {
      dispatch(FetchFilteredProductsPending());

      const Products = await ProductsService.fetchFilteredProducts(filter);

      dispatch(FetchFilteredProductsSuccess(Products));

      if (Products.data.error) {
        dispatch(FetchFilteredProductsError(Products.data.error));
      }
    } catch (err) {
      dispatch(FetchFilteredProductsError(err));
    }
  };
};

// Fetch Selected Products

export const FetchSelectedProducts = filter => {
  return async dispatch => {
    try {
      dispatch(SelectedProductsPending());

      const Products = await ProductsService.fetchSelectedProducts(filter);

      dispatch(SelectedProductsSuccess(Products));

      if (Products.data.error) {
        dispatch(SelectedProductsError(Products.data.error));
      }
    } catch (err) {
      dispatch(SelectedProductsError(err));
    }
  };
};

// Import Products

export const ImportProducts = data => {
  return async dispatch => {
    try {
      dispatch(ImportProductsPending());

      const Products = await ProductsService.importProducts({ products: data });

      if (Products.success) {
        const OutProducts = await ProductsService.fetchFilteredProducts();
        dispatch(FetchFilteredProductsSuccess(OutProducts));
      }

      (await Products.success) && dispatch(ImportProductsSuccess(Products));
    } catch (err) {
      dispatch(ImportProductsError(err));
    }
  };
};

// Togle Import Product Modal
export function ToggleImporterModal() {
  return {
    type: types.TOGGLE_IMPORTER_MODAL
  };
}

// Fetch Retrieved Countries

export const FetchRetrievedCountries = () => {
  return async dispatch => {
    try {
      dispatch(ImportRetrieveCountriesPending());

      const Countries = await ProductsService.retrievedCountries();

      // dispatch(ImportRetrieveCountriesSuccess(Countries));
    } catch (err) {
      dispatch(ImportRetrieveCountriesError(err));
    }
  };
};
