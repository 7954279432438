import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import Loader from '../../../appUtility/Loader';
import { withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import axios from '../../../appUtility/Api';
import { connect } from 'react-redux';
import { showAuthLoader, hideAuthLoader } from 'actions/Auth';
import { CircularProgress } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

import { getComparator, stableSort } from '../../../util/EnhancedTableFunctions';

import EnhancedTableHead from './../../../components/EnhancedTable/EnhancedTableHead';


const columnData = [
  { id: 'id', align: false, disablePadding: true, sortable: true, label: 'Ordrer ID' },
  { id: 'user_id', align: false, disablePadding: true, sortable: true, label: 'User ID' },
  { id: 'store', align: true, disablePadding: false, sortable: true, label: 'Store' },
  { id: 'status', align: true, disablePadding: false, sortable: true, label: 'Status' },
  { id: 'currency', align: true, disablePadding: false, sortable: true, label: 'Currency' },
  { id: 'shippment_price', align: true, disablePadding: false, sortable: true, label: 'Shipment Price' },
  { id: 'city', align: true, disablePadding: false, sortable: true, label: 'City' },
  { id: 'street', align: true, disablePadding: false, sortable: true, label: 'Street' },
  { id: 'time', align: true, disablePadding: false, sortable: true, label: 'Created At' }
];

class DataTable extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
      dataLength: 0,
      page: 0,
      rowsPerPage: 10
    };
    this._isMounted = false;
  }

  handleRequestSort = (event, property) => {
    
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';

    this.setState({ order: isAsc ? 'desc' : 'asc' });
    this.setState({ orderBy: property })

    let data = [...this.state.data];

    let comparator = getComparator(this.state.order, this.state.orderBy);

    const stabilizedThis = data.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    this.setState({ data: stabilizedThis.map((el) => el[0]) });
  };

  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };

  handleKeyDown = (event, id) => {
    event.preventDefault();
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };

  handleClick = (event, id) => {
    if (event.target.nodeName === 'TD') {
      this.props.history.push('/app/orders/' + id);
    }
    event.preventDefault();
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }

    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
    this.getOrdersData({}, page);
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
    this.getOrdersData({}, this.state.page, event.target.value);
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  deleteCustomer = id => {
    const data = [...this.state.data];
    let obj = data[id];
    let index = data.indexOf(obj);
    data.splice(index, 1);
    this.setState({ data });
    axios({
      method: 'post',
      url: axios.defaults.baseURL + '/admin/customer/deletecustomer',
      data: JSON.stringify({ id }),
      headers: { Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')) }
    });
  };

  componentWillReceiveProps(nextProps) {
    nextProps.data && this.setState({ data: nextProps.data });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const {
      rowsPerPage,
      page,
      order,
      orderBy,
      pending,
      dataLength,
      handleChangePage,
      handleChangeRowsPerPage
    } = this.props;
    const { data } = this.state;
    return (
      <>
        <Loader loading={pending}>
          <div className="orders-card mt-4">
            <Paper>
              <div className="flex-auto">
                <div className="table-responsive-material">
                  <Table className="">
                    <EnhancedTableHead
                      numSelected={this.state.selected.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={this.handleSelectAllClick}
                      onRequestSort={this.handleRequestSort}
                      rowCount={data.length}
                      headCells={columnData}
                    />
                    <TableBody>
                      {data.length &&
                        stableSort(data, getComparator(order, orderBy)).map((n, index) => {
                          const isItemSelected = this.isSelected(n.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <TableRow
                              hover
                              onClick={event => this.handleClick(event, n.id)}
                              onKeyDown={event => this.handleKeyDown(event, n.id)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={n.id}
                              selected={isItemSelected}
                            >
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isItemSelected}
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </TableCell>
                              <TableCell padding="none">{n.id}</TableCell>
                              <TableCell align="right">{n.user_id}</TableCell>
                              <TableCell align="right">{n.store}</TableCell>
                              <TableCell align="right">{n.status}</TableCell>
                              <TableCell align="right">{n.currency}</TableCell>
                              <TableCell align="right">{n.shipment_price}</TableCell>
                              <TableCell align="right"> {n.city} </TableCell>
                              <TableCell align="right"> {n.street} </TableCell>
                              <TableCell align="right"> {n.time} </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          count={dataLength}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onChangePage={handleChangePage}
                          onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              </div>
            </Paper>
          </div>
        </Loader>
      </>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, role, store } = auth;
  return { loader, role, store };
};

export default withRouter(
  connect(mapStateToProps, {
    showAuthLoader,
    hideAuthLoader
  })(DataTable)
);
