import React from 'react';
import CardBox from 'components/CardBox/index';
import translate from '../../../../appUtility/Translate';
import Avatar from '@material-ui/core/Avatar';

let counter = 0;

function createData(name, image, quantity, price, total) {
  counter += 1;
  return { id: counter, name, image, quantity, price, total };
}

export default function Products({ data, shipping, shippingPrice }) {
  let totalPrice = 0;

  const formatedData = [];
  let formatedShipping = {};
  let sum = 0;

  data.forEach(el => {
    let total = el.price * el.quantity;
    sum += total;
    sum += shippingPrice;
    formatedData.push(createData(el.name, el.image, el.quantity, el.price, total));
    formatedShipping = {
      shippingQuantity: shipping.quantity,
      shippingType: shipping.shippingType,
      shippingPrice: shippingPrice,
      shippingTime: `${shipping.time.min}d-${shipping.time.max}d`
    };
  });
  data = formatedData;
  totalPrice = sum;
  shipping = formatedShipping;

  return (
    <div className="club-add-merchant-content">
      <CardBox
        styleName="col-12"
        cardStyle="p-0"
        style={{ borderRadius: '4px', border: '1px solid rgb(222,220,236)' }}
        headerOutside
      >
        <div>
          <h1>{translate('products')}</h1>

          {data.length > 0 ? (
            <div className="table-responsive-material">
              <table className="default-table table table-sm table-hover">
                <thead>
                  <tr>
                    <th>{translate('products')}</th>
                    <th style={{ paddingLeft: '18px' }}>{translate('quantity')}</th>
                    <th style={{ paddingLeft: '25px' }}>{translate('price')}</th>
                    <th style={{ paddingLeft: '21px' }}>{translate('price_total')}</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map(data => {
                    console.log('dataatatata', data);
                    return (
                      <>
                        <tr style={{ background: '#fff' }} tabIndex={-1} key={data.id}>
                          <td>
                            <div className="user-profile d-flex flex-row align-items-center">
                              <Avatar alt={data.name} src={data.image} className="ml-3 user-avatar" />
                              <div className="user-detail">
                                <h5 className="user-name">{data.name}</h5>
                                <p className="user-description">{data.description}</p>
                              </div>
                            </div>
                          </td>
                          <td style={{ paddingLeft: '39px' }}>{data.quantity}</td>
                          <td style={{ paddingLeft: '34px' }}>{data.price}</td>
                          <td style={{ paddingLeft: '29px' }}>{data.total}</td>
                        </tr>
                        <tr>
                          <th style={{ fontWeight: '400', fontSize: '16px' }}></th>
                          <th style={{ fontWeight: '400', fontSize: '16px' }}>{translate('shipping_type')}</th>
                          <th style={{ fontWeight: '400', fontSize: '16px' }}>{translate('shipping_price')}</th>
                          <th style={{ fontWeight: '400', fontSize: '16px' }}>{translate('shipping_time')}</th>
                        </tr>

                        <tr style={{ background: '#fff' }} tabIndex={-1} key={'2' + data.id}>
                          <td style={{ paddingLeft: '16px' }}></td>
                          <td style={{ paddingLeft: '26px' }}>{shipping.shippingType}</td>
                          <td style={{ paddingLeft: '39px' }}>{shipping.shippingPrice}</td>
                          <td style={{ paddingLeft: '23px' }}>{shipping.shippingTime}</td>
                        </tr>
                      </>
                    );
                  })}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'right' }}>
                      {translate('price_total')} {totalPrice}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          ) : (
            <div>{translate('products_not_found')}</div>
          )}
        </div>
      </CardBox>
    </div>
  );
}
