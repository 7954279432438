import {
  SignUpPending,
  SignUpSuccess,
  SignUpError,
  SignInPending,
  SignInSuccess,
  SignInError,
  LogOutPending,
  LogOutSuccess,
  LogOutError,
  ResetPasswordPending,
  ResetPasswordSuccess,
  ResetPasswordError,
  ResetPasswordRoutePending,
  ResetPasswordRouteSuccess,
  ResetPasswordRouteError,
  UpdatePasswordPending,
  UpdatePasswordSuccess,
  UpdatePasswordError
} from './auth.actionCreator';
import AuthService from '../../services/Auth';
import * as types from './auth.actionTypes';
import AppAPI, { setTokenToAxios, AppNodeAPI } from '../../appUtility/Api';

// Sign Up
export const SignUpNewMerchant = merchant => {
  return async dispatch => {
    try {
      dispatch(SignUpPending());

      const Merchant = await AuthService.SignUp(merchant);

      if (Merchant.token) {
        setTokenToAxios(Merchant.token);
        localStorage.setItem('token', JSON.stringify(Merchant.token));
      }
      if (Merchant.admin_name) {
        localStorage.setItem('admin_name', JSON.stringify(Merchant.admin_name));
      }

      if (Merchant.error) {
        dispatch(SignUpError(Merchant.error));
      } else {
        dispatch(SignUpSuccess(Merchant));
      }
    } catch (err) {
      dispatch(SignUpError(err));
    }
  };
};

// Sign In
export const SignInMerchant = merchant => {
  return async dispatch => {
    try {
      dispatch(SignInPending());
      const Merchant = await AuthService.SignIn(merchant);

      if (Merchant.token) {
        localStorage.setItem('token', JSON.stringify(Merchant.token));
        AppAPI.defaults.headers.common['Authorization'] = 'Bearer ' + Merchant.token;
        AppNodeAPI.defaults.headers.common['Authorization'] = 'Bearer ' + Merchant.token;
      }
      if (Merchant.admin_name) {
        localStorage.setItem('admin_name', JSON.stringify(Merchant.admin_name));
      }

      if (Merchant.store) {
        localStorage.setItem('store_id', JSON.stringify(Merchant.store));
      }

      if (Merchant.error) {
        dispatch(SignInError(Merchant.error));
      } else {
        dispatch(SignInSuccess(Merchant));
      }
    } catch (err) {
      dispatch(SignInError(err));
    }
  };
};

// Log Out
export const LogOutMerchant = merchant => {
  return async dispatch => {
    try {
      dispatch(LogOutPending());

      const Merchant = await AuthService.SignOut(merchant);

      localStorage.removeItem('token');
      localStorage.removeItem('admin_name');
      localStorage.removeItem('store_id');
      dispatch(LogOutSuccess(Merchant));
    } catch (err) {
      localStorage.removeItem('token');
      localStorage.removeItem('admin_name');
      localStorage.removeItem('store_id');
      dispatch(LogOutError(err));
    }
  };
};

// Reset Password
export const ResetPasswordRequest = email => {
  return async dispatch => {
    try {
      dispatch(ResetPasswordPending());

      const Merchant = await AuthService.PasswordResetRequest(email);

      if (Merchant.success) {
        dispatch(ResetPasswordSuccess(Merchant));
      }
    } catch (err) {
      dispatch(ResetPasswordError(err));
    }
  };
};

// Check Password Reset Route
export const CheckPasswordResetRoute = url => {
  return async dispatch => {
    try {
      dispatch(ResetPasswordRoutePending());

      const Merchant = await AuthService.PasswordResetRouteRequest(url);

      if (Merchant.success) {
        dispatch(ResetPasswordRouteSuccess());
      }

      if (Merchant.error) {
        dispatch(ResetPasswordRouteError(Merchant));
      }
    } catch (err) {
      dispatch(ResetPasswordRouteError(err));
    }
  };
};

// Update Password
export const UpdatePassword = data => {
  return async dispatch => {
    try {
      dispatch(UpdatePasswordPending());

      const Merchant = await AuthService.UpdatePassword(data);

      if (Merchant.success) {
        dispatch(UpdatePasswordSuccess());
      }
    } catch (err) {
      dispatch(UpdatePasswordError(err));
    }
  };
};

export const hideMessage = () => {
  return {
    type: types.HIDE_MESSAGE
  };
};

export const showAuthLoader = () => {
  return {
    type: types.ON_SHOW_LOADER
  };
};
