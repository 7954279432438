import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_FACEBOOK_USER_SUCCESS,
  SIGNIN_GITHUB_USER_SUCCESS,
  SIGNIN_GOOGLE_USER_SUCCESS,
  SIGNIN_TWITTER_USER_SUCCESS,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  VERIFY_ROLE
} from 'constants/ActionTypes';

import * as types from '../store/signUp/signup.actionTypes';

const INIT_STATE = {
  admin_name: '',
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('token'),
  role: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // Add New Merchant
    case types.ADD_MERCHANT_PENDING:
      return {
        ...state,
        pending: true
      };
    case types.ADD_MERCHANT_SUCCESS:
      if (action.payload.token) {
        localStorage.setItem('token', JSON.stringify(action.payload.token));
      }
      if (action.payload.admin_name) {
        localStorage.setItem('admin_name', JSON.stringify(action.payload.admin_name));
      }

      return {
        ...state,
        pending: false,
        authUser: action.payload.token,
        initURL: '/app/dashboard'
      };
    case types.ADD_MERCHANT_ERROR:
      return {
        ...state,
        pending: false,
        error: action.payload.error
      };

    case VERIFY_ROLE: {
      return {
        ...state,
        role: action.role
      };
    }

    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
        initURL: '/app/dashboard'
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      localStorage.removeItem('store_id');
      return {
        ...state,
        authUser: null,
        initURL: '/signin',
        loader: false,
        showMessage: false
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      };
    }

    case SIGNIN_GOOGLE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case SIGNIN_FACEBOOK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case SIGNIN_TWITTER_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case SIGNIN_GITHUB_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    default:
      return state;
  }
};
