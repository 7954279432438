import axios from './Api';

let langs = JSON.parse(localStorage.getItem('translations'));

const translate = key => {
  if (langs[key] === '' || typeof langs[key] === undefined || typeof langs[key] === 'undefined') {

    if(window.location.hostname == 'homerun.webiz.ge'){
      return key.replace('Clalit','Homerun').replace('clalit','homerun');
    }
    return key;
  }
  if(window.location.hostname == 'homerun.webiz.ge'){
    return langs[key].replace('Clalit','Homerun').replace('clalit','homerun');
  }
  
  return langs[key];
};

const getTranslation = lang => {
  fetch(axios.defaults.baseURL + '/app/translate/index', {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    body: JSON.stringify({ lang: lang || localStorage.getItem('lang') || 'en' })
  })
    .then(res => {
      return res.json();
    })
    .then(response => {
      const translations = response.data[lang || localStorage.getItem('lang') || 'en'];

      localStorage.setItem('translations', JSON.stringify(translations));
      langs = translations;
    });
};

export { getTranslation };
export default translate;
