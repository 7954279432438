import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import translate from '../../../appUtility/Translate';

export default function Catalog({ file, onChangeFile, onUploadFile, resetFile }) {
  return (
    <div className="users-resp" style={{ width: '200px', height: '100%', backgroundColor: '#f0f0f6' }}>
      <div className="manage-catalog">{translate('customers')}</div>
      <div className="import-customers">
        <p className="import-customers__heading">{translate('import_new_users')}</p>
        <div className="import-customers__upload-btn-wrapper">
          <button className="add-new-product-button">{translate('choose_file')}</button>
          <input
            type="file"
            name="file"
            onChange={onChangeFile}
            onClick={event => (event.target.value = null)}
            className="import-customers__file-input"
            accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
          />
        </div>
        {file && (
          <button className="add-new-product-button import-customers__upload-btn" onClick={onUploadFile}>
            {translate('upload') +
              ' ' +
              (file.name.length > 20
                ? file.name.substr(0, 20) + '...' + file.name.substring(file.name.lastIndexOf('.'))
                : file.name)}
          </button>
        )}

        {/*<input type="file" name="file" onChange={onChangeHandler} className="import-customers__file-input" />*/}
        {/*<button className="add-new-product-button">{translate('upload')}</button>*/}
      </div>
    </div>
  );
}
