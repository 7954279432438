// Fetching Dashboard Data
export const selectDashboardData = state => {
  return state.DashboardReducer.data;
};
// Pending
export const selectDashboardPending = state => {
  return state.DashboardReducer.pending;
};
// Error
export const selectDashboardError = state => {
  return state.DashboardReducer.error;
};
// Fetching Dashboard Map Data
export const selectDashboardMapData = state => {
  return state.DashboardReducer.data;
};
// Pending
export const selectDashboardMapPending = state => {
  return state.DashboardReducer.pending;
};
// Error
export const selectDashboardMapError = state => {
  return state.DashboardReducer.error;
};
