// Fetching Users
export const selectUsers = state => {
  return state.UsersReducer.Users;
};

// Data Length
export const selectDataLength = state => {
  return state.UsersReducer.dataLength;
};

// Pending
export const selectUsersPending = state => {
  return state.UsersReducer.pending;
};
// Error
export const selectUsersError = state => {
  return state.UsersReducer.error;
};

// Page
export const selectUsersTablePage = state => {
  return state.UsersReducer.page;
};
