import React, { useState, useEffect } from 'react';
import Charts from './Charts';
import ContainerHeader from '../../../components/ContainerHeader';
import Header from '../../../components/Header';
import 'react-daterange-picker/src/css/react-calendar.scss';
import moment from 'moment';
import Loader from '../../../appUtility/Loader';

const SamplePage = props => {
  const [selectedWebsiteId, setSelectedWebsiteId] = useState(props.selectedWebsiteId);
  const [dateFrom, setDateFrom] = useState(
    moment()
      .subtract(1, 'months')
      .format('YYYY-MM-DD')
  );
  const [dateTo, setDateTo] = useState(moment().format('YYYY-MM-DD'));
  const [mapData, setMapData] = useState(null);
  const [average, setAverage] = useState(null);

  let user = JSON.parse(localStorage.getItem('store_id'));
  useEffect(() => {
    const fetchData = async () => {
      await props.FetchDashboardData({ dateFrom: dateFrom, dateTo: dateTo, storeId: user });
    };
    fetchData();
    setSelectedWebsiteId(props.selectedWebsiteId);
  }, [props.selectedWebsiteId]);

  useEffect(() => {
    filterMapData('visits');
  }, [props.Data]);

  const handleDateUpdate = (from, to) => {
    setDateFrom(from);
    setDateTo(to);
    props.FetchDashboardData({ dateFrom: from, dateTo: to, storeId: user });
  };

  const filterMapData = category => {
    const data = props.Data.map;
    if (data && data[category] && data[category].length > 0) {
      const map =
        data &&
        data[category].map(item => ({
          z: 1,
          keyword: item.country,
          lat: 34 + item.cnt / 2,
          lon: -85 + item.cnt / 2,
          ...item
        }));
      setMapData(map);
      const total =
        data &&
        data[category].reduce((item, nextItem, index) =>
          index === 1 ? Number(item.cnt) + Number(nextItem.cnt) : item + Number(nextItem.cnt)
        );
      const avr =
        data &&
        data[category]
          .sort((a, b) => b.cnt > a.cnt)
          .slice(1, 4)
          .map(item => ({ ...item, percent: ((100 * item.cnt) / total).toFixed() }));

      setAverage(avr);
    } else {
      setMapData([]);
      setAverage([]);
    }
  };

  const data = props.Data;
  const pending = props.Pending;
  return (
    <>
      <Header
        withDatePicker
        styleName="header--transparent"
        onDateUpdate={handleDateUpdate}
        startDate={dateFrom}
        endDate={dateTo}
      />
      {/*{data ? ( <div className="noData">there is no data available yet</div> ):*/}
      <Loader loading={pending}>
        <div className="app-wrapper dashboard-wrapper">
          <div className="animated slideInUpTiny animation-duration-3">
            <Charts
              selectedWebsiteId={selectedWebsiteId}
              key={selectedWebsiteId}
              siteAddHandler={() => {}}
              data={{ ...data, mapData: { chart: mapData, average } }}
              pending={pending}
              onChangeMapCategory={filterMapData}
            />
          </div>
        </div>
      </Loader>
    </>
  );
};

export default SamplePage;
