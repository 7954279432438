import React from 'react';
import PropTypes from 'prop-types';
import keycode from 'keycode';
import { Link, NavLink, withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import SweetAlert from 'react-bootstrap-sweetalert';
import axios from '../../../appUtility/Api';
import { connect } from 'react-redux';
import { hideAuthLoader, showAuthLoader } from 'actions/Auth';
import { CircularProgress } from '@material-ui/core';
import Loader from '../../../appUtility/Loader';

let counter = 0;

function createData(user_id, store_id, store_name, store_domain, edit, remove) {
  counter += 1;
  return { id: counter, user_id, store_id, store_name, store_domain, edit, remove };
}

const columnData = [
  { id: 'store_id', sort: true, align: false, disablePadding: true, label: 'Store ID' },
  { id: 'store_name', sort: true, align: true, disablePadding: false, label: 'Store Name' },
  { id: 'store_domain', sort: true, align: true, disablePadding: false, label: 'Store Address' },
  { id: 'edit', align: true, sort: false, disablePadding: false, label: 'Edit' },
  { id: 'remove', align: true, sort: false, disablePadding: false, label: 'Remove' }
];

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
  };

  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          {/*<TableCell padding="checkbox">*/}
          {/*  <Checkbox*/}
          {/*    color="primary"*/}
          {/*    indeterminate={numSelected > 0 && numSelected < rowCount}*/}
          {/*    checked={numSelected === rowCount}*/}
          {/*    onChange={onSelectAllClick}*/}
          {/*  />*/}
          {/*</TableCell>*/}
          {columnData.map(column => {
            return (
              <TableCell key={column.id} align={column.numeric} padding={column.disablePadding ? 'none' : 'default'}>
                <Tooltip title="Sort" placement={column.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
                  {column.sort === true ? (
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    <div>{column.label}</div>
                  )}
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

let DataTableToolbar = props => {
  const { numSelected } = props;

  return (
    <Toolbar className="table-header">
      <div className="title">
        {numSelected > 0 ? (
          <Typography variant="subheading">{numSelected} selected</Typography>
        ) : (
          <Typography variant="title">Nutrition</Typography>
        )}
      </div>
      <div className="spacer" />
      <div className="actions">
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

class DataTable extends React.Component {
  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }
    let data = [...this.state.data];
    // order === 'desc'
    //   ? data.sort((a, b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase() ? 1 : -1))
    //   : (data = data.sort((a, b) => (a[orderBy].toUpperCase() > b[orderBy].toUpperCase() ? -1 : 1)));

    this.setState({ data, order, orderBy });
    this.props.fechMerchants({ sort: { orderBy, order } });
    console.log('dataaaas', this.props.fechMerchants({ sort: { orderBy, order } }));
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.state.data.map(n => n.id) });
      return;
    }
    this.setState({ selected: [] });
  };
  handleKeyDown = (event, id) => {
    event.preventDefault();
    if (keycode(event) === 'space') {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    // event.preventDefault();
    // const { selected } = this.state;
    // const selectedIndex = selected.indexOf(id);
    // let newSelected = [];
    //
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selected, id);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selected.slice(1));
    // } else if (selectedIndex === selected.length - 1) {
    //   newSelected = newSelected.concat(selected.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    // }
    //
    // this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {

    this.props.setPage(page);
  };
  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };
  isSelected = id => this.state.selected.indexOf(id) !== -1;

  constructor(props, context) {
    super(props, context);

    this.state = {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      data: [],
      page: 0,
      rowsPerPage: 10,
      alert: null,
      pending: false,
    };
  }

  getStore = async () => {
    await this.setState({
      pending: true
    });
    fetch(axios.defaults.baseURL + '/app/club/storedata', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ token: JSON.parse(localStorage.getItem('token')) })
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        let formatedData = [];
        data.store.forEach(el => {
          formatedData.push(createData(el.user_id, el.store_id, el.store_name, el.store_domain));
        });
        this.props.hideAuthLoader();
        this.setState({ data: formatedData, pending: false });
      });
  };

  deleteThisGoal = id => {
    let getAlert = () => {
      return (
        <SweetAlert
          warning
          show
          showCancel
          confirmBtnText="Yes, delete it!"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title="Are you sure that you want to delete?"
          onConfirm={() => this.removeStore(id)}
          onCancel={this.hideAlert}
        ></SweetAlert>
      );
    };

    this.setState({
      alert: getAlert()
    });
  };

  hideAlert = () => {
    this.setState({
      alert: null
    });
  };

  removeStore = id => {
    this.props.showAuthLoader();
    this.setState({
      alert: null
    });
    fetch(axios.defaults.baseURL + '/admin/deletecoreuser', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify({ id })
    })
      .then(res => {
        return res.json();
      })
      .then(data => {
        if (data.success) {
          this.getStore();
        }
      });
  };

  componentDidMount() {
    // this.getStore();
    this.props.showAuthLoader();
  }

  componentWillReceiveProps(nextProps) {
    nextProps.data && this.setState({ data: nextProps.data });
  }

  render() {
    const { data, order, orderBy, selected, rowsPerPage, pending } = this.state;
    const { page } = this.props;
    return (
      <>
        {this.state.alert}
        {this.props.pending ?
        <div className="progress-container merchant-loader">
          <CircularProgress />
        </div>:
          <div className="merchant-card mt-4" style={{ height: '100%' }}>
            <div className="">
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                {data.length > 0 &&
                  data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(n => {
                    const isSelected = this.isSelected(n.id);
                    return (
                      <div
                        className={`col-12 col-sm-6 col-md-4 col-lg-2 product-box merchant-box`}
                        style={{ paddingLeft: '8px', paddingRight: '7px' }}
                      >
                        <div className="product-select-button">
                          {/*<input*/}
                          {/*  type="checkbox"*/}
                          {/*  checked={checked && 'checked'}*/}
                          {/*  onChange={() => console.log('just for warning')}*/}
                          {/*/>*/}
                          {/*<span className="checkmark"></span>*/}
                        </div>

                        <div
                          className="prepend-icon"
                          onClick={e => {
                            e.stopPropagation();
                            this.props.history.push(`/app/merchants/wizard/${n.user_id}`);
                          }}
                          style={{ cursor: 'pointer' }}
                        >
                          <div className={`club-product`}>
                            <div className="product-price merchant-header">
                              <span className="product-new-price">{n.store_id}</span>
                              <IconButton
                                aria-label="Delete"
                                onClick={e => {
                                  e.stopPropagation();

                                  this.deleteThisGoal(n.user_id);
                                }}
                                // style={{ zIndex: 10000 }}
                              >
                                <i
                                  className="fas fa-trash-alt"
                                  style={{ fontSize: '16px', color: 'rgba(0,0,0,0.54)' }}
                                />
                              </IconButton>
                            </div>

                            <div className="product-image merchant-image">
                              {n.store_image ? (
                                <img src={n.store_image} alt="" />
                              ) : (
                                <i className="fas fa-image merchant-placeholder-image" />
                              )}
                            </div>
                            <div className="product-description">
                              <div className="product-domain">{n.store_name}</div>
                              <div className="product-name">{n.store_domain}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
}
      </>

    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, role, store } = auth;
  return { loader, role, store };
};

export default withRouter(
  connect(mapStateToProps, {
    showAuthLoader,
    hideAuthLoader
  })(DataTable)
);
