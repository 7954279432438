import { connect } from 'react-redux';
import { FetchCategories } from '../category/category.actions';
import { selectCategories, selectCategoriesPending, selecCategoriesError } from '../category/category.selector';
import Product from '../../app/routes/Club/Product/index';

const mapStateToProps = state => {
  return {
    Pending: selectCategoriesPending(state),
    Categories: selectCategories(state),
    ErrorMessage: selecCategoriesError(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    FetchCategories: () => dispatch(FetchCategories())
  };
};

const ProductView = connect(mapStateToProps, mapDispatchToProps)(Product);

export default ProductView;
