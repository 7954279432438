import React from 'react';
import translate from '../../../../../appUtility/Translate';
import { Link } from 'react-router-dom';
const LastOrders = ({ data, styleName }) => {
  const items = data && data.length && data.slice(0, 4);
  return (
    <div className={`dashboard-card dashboard-card--big ${styleName}`}>
      <div className="dashboard-card--big_header">
        <h1 className="dashboard-card--big_heading">{translate('last_orders')}</h1>
        <Link to="/app/orders/listing" className="dashboard-card--big_show-all">
          {translate('show_all')}
        </Link>
      </div>
      <div className="dashboard-card_last-orders_header">
        <p>{translate('items').toUpperCase()}</p>
        <p>{translate('order_sum').toUpperCase()}</p>
        <p>{translate('customer').toUpperCase()}</p>
        <p>{translate('data_and_time').toUpperCase()}</p>
      </div>
      {items &&
        items.length &&
        items.map(item => {
          return (
            <div className="dashboard-card_last-orders_item" key={item.name}>
              <img src={item.image} className="dashboard-card_last-orders_item_image" />
              <p className="dashboard-card_last-orders_item_desc">{item.name}</p>
              <p className="dashboard-card_last-orders_item_other">${item.order_sum}</p>
              <p className="dashboard-card_last-orders_item_other color">{item.customer}</p>
              <p className="dashboard-card_last-orders_item_other">{item.date}</p>
            </div>
          );
        })}
    </div>
  );
};

export default LastOrders;
