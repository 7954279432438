import * as types from './attributes.actionTypes';
import { AppNodeAPI as AxiosNode } from '../../appUtility/Api';

export const setAttributesData = data => {
  return {
    type: types.GET_ATTRIBUTES_DATA,
    payload: data
  };
};

export const fetchAttributesDataFaild = () => {
  return {
    type: types.GET_ATTRIBUTES_DATA_FAILD
  };
};

export const setAttributesDataType = data => {
  return {
    type: types.GET_ATTRIBUTES_TYPES,
    payload: data
  };
};

export const fetchAttributesDataTypeFaild = () => {
  return {
    type: types.GET_ATTRIBUTES_TYPES_FAILD
  };
};

export const getAttributesData = params => {
  return dispatch => {
    return AxiosNode.get('/attribute', { params })
      .then(response => {
        dispatch(setAttributesData(response.data));
      })
      .catch(error => {
        dispatch(fetchAttributesDataFaild());
      });
  };
};

export const getAttributeTypesData = params => {
  return dispatch => {
    return AxiosNode.get('/attribute_type')
      .then(response => {
        dispatch(setAttributesDataType(response.data));
      })
      .catch(error => {
        dispatch(fetchAttributesDataTypeFaild());
      });
  };
};
