import React from 'react';
import DataTable from './Translations';

class Translations extends React.Component {
  constructor(props, context) {
    super(props, context);
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <DataTable
        FetchTranslations={this.props.FetchTranslations}
        submitSearchBox={this.submitSearchBox}
        page={this.props.page}
        SetPage={this.props.SetPage}
      />
    );
  }
}

export default Translations;
