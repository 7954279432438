import React from 'react';
import Header from 'components/Header';
import axios from '../../../../appUtility/Api';
import Tabs from './Tabs';
import General from './General';
import Shipping from './Shipping';
import Billing from './Billing';
import Products from './Products';

class OrderDetails extends React.Component {
  state = {
    tabValue: 'general',
    data: {
      firstName: '',
      lastName: '',
      shippingCountry: '',
      shippingCity: '',
      shippingPrice: '',
      shippingState: '',
      zip: '',
      shippingCompany: '',
      status: '',
      time: ''
    },
    shipping: {
      shippingId: '',
      quantity: '',
      shippingType: '',
      time: {
        min: '',
        max: ''
      }
    },
    orderedItems: []
  };

  handleChange = event => {
    let data = { ...this.state.data };
    let attr = event.target.id;
    let value = event.target.value;
    data[attr] = value;
    this.setState({ data });
  };
  handleDateChange = date => {
    this.setState({ selectedDate: date });
  };

  getorderDetails = id => {
    let data = { id };
    fetch(axios.defaults.baseURL + '/app/club/detailedorder', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(data)
    })
      .then(res => {
        return res.json();
      })
      .then(res => {
        console.log('reeeees', res);
        let orderDetails = res.orderDetails;
        let shipping = res.shipping;
        orderDetails.time = new Date(orderDetails.time);
        const orderedItems = res.orders || [];
        this.setState({ data: orderDetails, orderedItems: orderedItems, shipping });
      })
      .catch(err => console.log('shipping3', err));
  };
  editOrder = (data, id) => {
    return fetch(axios.defaults.baseURL + '/app/club/editorder?id=' + id, {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + JSON.parse(localStorage.getItem('token')),
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: JSON.stringify(data)
    });
  };
  onSubmit = event => {
    const param = this.props.match.params.id;
    let data = { ...this.state.data };
    data.time = data.time
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ');
    this.editOrder(data, param).then(() => {
      this.props.history.push('/app/orders/listing');
    });
  };

  setTab = id => {
    this.setState({ tabValue: id });
  };

  componentDidMount() {
    const param = this.props.match.params.id;
    if (param) {
      this.getorderDetails(param);
    }
    this.props.FetchCountriesAction();
  }

  render() {
    let general = { time: this.state.data.time, status: this.state.data.status };
    let shipping = { ...this.state.data };
    const { Countries } = this.props;

    return (
      <div className="merchant-wizard">
        <Tabs setTab={this.setTab} edit={this.onSubmit} activeTab={this.state.tabValue} />
        <div className="attributes-wrapper">
          <Header heading={this.state.tabValue} />
          {this.state.tabValue === 'general' && (
            <General data={general} handleChange={this.handleChange} handleDateChange={this.handleDateChange} />
          )}
          {this.state.tabValue === 'shipping' && (
            <Shipping data={shipping} Countries={Countries} handleChange={this.handleChange} />
          )}
          {this.state.tabValue === 'billing' && (
            <Billing Countries={Countries} handleChange={this.handleChange} data={shipping} />
          )}
          {this.state.tabValue === 'products' && (
            <Products
              data={this.state.orderedItems}
              shipping={this.state.shipping}
              shippingPrice={this.state.data.shippingPrice}
            />
          )}
        </div>
      </div>
    );
  }
}

export default OrderDetails;
