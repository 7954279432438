import AppAPI from '../appUtility/Api';

class OrderService {
  static fetchOrdersService = (filters, page, rowsPerPage) => {
    console.log(filters, page, rowsPerPage);
    return AppAPI.post('/app/club/orders', { filters, page: page, rowsPerPage: rowsPerPage }).then(res => {
      return res.data;
    });
  };

  static fetchCountries = () => {
    return AppAPI.post('/admin/countries').then(res => {
      return res.data;
    });
  };
}

export default OrderService;
